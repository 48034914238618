import React from 'react'
import { Modal } from 'react-bootstrap'

import ReferralSourceService from '../../services/ReferralSourcesService'

import NewCompany from './NewCompany'

const NewCompanyModal = ({ onSaveSuccess, onSave, onClosed, oldModel }) => {
  const handleSave = (model, isUpdate) => {
    if (onSave) {
      return onSave(model, isUpdate)
    }

    if (isUpdate) {
      return ReferralSourceService.update(model)
    } else {
      return ReferralSourceService.create(model)
    }
  }

  return (
    <Modal show={true} size="xl" onHide={() => {}}>
      <Modal.Body>
        <NewCompany onSave={handleSave} onSaveSuccess={onSaveSuccess} onClose={onClosed} oldModel={oldModel} />
      </Modal.Body>
    </Modal>
  )
}

export default NewCompanyModal

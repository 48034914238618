import React from 'react'
import DatePicker from 'react-datepicker'

import { Field } from 'formik'
import Moment from 'moment'

const DatePickerWrapper = ({ field, form, ...props }) => {
  const fieldProps = {
    ...field,
    ...props,
  }

  if (!fieldProps.className) {
    fieldProps.className = ''
  }

  if (!fieldProps.className.indexOf('date-picker') !== -1) {
    fieldProps.className += ' date-picker'
  }

  if (!fieldProps.className.indexOf('form-control') !== -1) {
    fieldProps.className += ' form-control'
  }

  const dataFormat = fieldProps.dataFormat

  const handleOnChange = value => {
    const valueToUse = dataFormat ? Moment(value).format(dataFormat) : value

    form.setFieldValue(fieldProps.name, valueToUse)
  }

  const valueAsDate = fieldProps.value ? Moment(fieldProps.value).toDate() : null

  return (
    <div className="input-group">
      <DatePicker
        {...fieldProps}
        selected={valueAsDate}
        value={null}
        onChange={handleOnChange}
        aria-describedby="calendarIcon"
        id={fieldProps.id}
      />
      <div className="input-group-append">
        <span id="calendarIcon" className="fas fa-calendar input-group-text"></span>
      </div>
    </div>
  )
}

export default props => <Field component={DatePickerWrapper} {...props} />

import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import FAIcon from '../components/FAIcon'

import './Library.scss'

export default withRouter(({ history, user }) => {
  return (
    <div className="library-page-container mt-4">
      <Link className="shadow library-card bg-white" to="/library/team-members">
        <FAIcon name="user-friends" type="fas" className="library-card-icon d-inline-block align-middle text-primary" />
        <h5 className="mb-0 pl-3 d-inline-block align-middle">Team</h5>
      </Link>

      <Link className="shadow library-card bg-white" to="/library/activities-and-expenses">
        <FAIcon name="user-friends" type="fas" className="library-card-icon d-inline-block align-middle text-primary" />
        <h5 className="mb-0 pl-3 d-inline-block align-middle">Activities and Expenses</h5>
      </Link>
    </div>
  )
})

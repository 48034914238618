import React from 'react'
import { Button, Navbar } from 'react-bootstrap'

import './NavigationBar.scss'

const NavigationBar = props => {
  return (
    <Navbar className="navbar navbar-expand-md bg-white intake-title-bar" fixed="top">
      <div className="container">
        <div className="w-100">
          <ul className="navbar-nav mr-auto">
            {props.leftBtnText && (
              <Button variant="link" id="leftBtn" onClick={props.handleClickLeft}>
                {props.leftBtnText}
              </Button>
            )}
          </ul>
        </div>
        <div className="mx-auto">
          <li className="navbar-brand font-weight-bold"> {props.title} </li>
        </div>
        <div className="navbar-collapse collapse w-100">
          <ul className="navbar-nav ml-auto">
            {props.rightBtnText && (
              <Button id="rightBtn" className="action-button" variant="link" onClick={props.handleClickRight}>
                {props.rightBtnText}
              </Button>
            )}
          </ul>
        </div>
      </div>
    </Navbar>
  )
}

export default NavigationBar

import React, { useState } from 'react'
import { Button, FormGroup, FormLabel } from 'react-bootstrap'

import createFormHelpers from '../../FormHelpers'
import { wrapFetch } from '../../services/FetchHelper'
import { getToken } from '../../utils/getToken'
import validations from '../../validation'
import StateSelector from '../StateSelector'

const fileControlAccepts = [
  '.pdf, application/pdf',
  '.doc, application/msword',
  '.docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.jpg, .jpeg, .jpe, image/jpeg',
  '.bmp, image/bmp',
  '.gif, image/gif',
  '.tiff, .tif, image/tiff',
]

const schema = validations.object({
  name: validations.name().required(),
  addressLine1: validations.addressLine1().nullable(),
  addressCity: validations.addressCity().nullable(),
  addressState: validations.addressState().nullable(),
  addressZip: validations.addressZip(),
  phone: validations.phone(),
  fax: validations.fax(),
  email: validations.email(),
  billingName: validations.name().required(),
  billingAddressLine1: validations.addressLine1().nullable(),
  billingAddressCity: validations.addressCity().nullable(),
  billingAddressState: validations.addressState().nullable(),
  billingAddressZip: validations.addressZip(),
  billingPhone: validations.phone(),
  billingFax: validations.fax(),
  billingEmail: validations.email(),
  notes: validations.note(),
})

const NewCompany = ({ onSave, onSaveSuccess, onClose, oldModel }) => {
  const { getValue } = createFormHelpers({})

  const [model, setModel] = useState(oldModel || {})
  const [validState, setValidState] = useState(validations.validate(model, schema))
  const [touched, setTouched] = useState([])
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [guidelinesFileText, setGuidelinesFileText] = useState(
    getValue('guidelinesFileMetadata.displayName', null, oldModel) || 'Attach a file',
  )
  const [errorMsg, setErrorMsg] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const handleOnChange = e => {
    const getTargetValue = () => {
      if (e.target.type === 'checkbox') {
        return e.target.checked
      } else if (e.target.type === 'file') {
        return e.target.files[0]
      }

      return e.target.value
    }

    const newModel = {
      ...model,
      [e.target.id]: getTargetValue(),
    }

    if (newModel.billingAddressSame) {
      newModel.billingName = newModel.name
      newModel.billingAddressLine1 = newModel.addressLine1
      newModel.billingAddressCity = newModel.addressCity
      newModel.billingAddressState = newModel.addressState
      newModel.billingAddressZip = newModel.addressZip
      newModel.billingPhone = newModel.phone
      newModel.billingFax = newModel.fax
      newModel.billingEmail = newModel.email
    }

    setModel(newModel)
    setValidState(validations.validate(newModel, schema))
  }

  const registerTouch = name => {
    if (touched.includes(name)) return

    setTouched(touched.concat([name]))
  }

  const handleOnBlur = e => {
    registerTouch(e.target.name)
  }

  const createInputProps = name => ({
    id: name,
    name,
    value: model[name] || '',
    onChange: handleOnChange,
    onBlur: handleOnBlur,
  })

  const handleSubmit = e => {
    e.preventDefault()

    setErrorMsg(null)
    setSubmitAttempted(true)
    if (!validState.success) return

    if (onSave) {
      setIsLoading(true)

      const isUpdate = !!oldModel && Object.keys(oldModel).length !== 0
      const result = onSave(model, isUpdate)

      if (result.onSuccess) result.onSuccess(handleOnSaveSuccess)
      if (result.onBadRequest) result.onBadRequest(handleOnSaveFailure)
      if (result.catch) result.catch(handleOnSaveFailure)
      setIsLoading(false)
    }
  }

  const handleOnSaveSuccess = model => {
    if (onSaveSuccess) onSaveSuccess(model)
  }

  const handleOnSaveFailure = reason => {
    setErrorMsg(`Unexpected error while trying to save:\n${reason}`)
  }

  const handleOnFileChange = e => {
    const file = e.target.files[0]

    if (!file) {
      const newModel = {
        ...model,
        guidelinesFileId: null,
      }
      setModel(newModel)
      setGuidelinesFileText('Attach a file')
      setErrorMsg(null)
    }

    const formData = new FormData()
    formData.set('file', file)

    const newModel = {
      ...model,
      guidelinesFileId: 'Uploading',
    }
    setModel(newModel)
    setGuidelinesFileText(`${file.name} is uploading...`)
    setErrorMsg(null)

    wrapFetch(
      fetch('/api/files', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
    )
      .onSuccess(json => {
        const newModel = {
          ...model,
          guidelinesFileId: json.id,
          guidelinesFileMetadata: {
            id: json.id,
            displayName: json.name,
            type: json.type,
            uploadedBy: json.uploadedBy,
            uploadTimeUTC: json.uploadTimeUTC,
            length: json.length,
          },
        }
        setModel(newModel)
        setGuidelinesFileText(file.name)
      })
      .onBadRequest(err => {
        setErrorMsg(err)
        setGuidelinesFileText('Failed to upload')
      })
      .onStatus(413, () => {
        setErrorMsg('File is too large')
        setGuidelinesFileText('Failed to upload')
      })
      .catch(() => {
        setErrorMsg('Unexpected error while uploading file')
        setGuidelinesFileText('Failed to upload')
      })
  }

  const ErrorMessage = ({ name }) => {
    if (!submitAttempted && !touched.includes(name)) return null
    if (!validState.errors) return null

    const msg = validState.errors[name]
    if (!msg) return null

    return <div className="text-danger">{msg}</div>
  }

  return (
    <form noValidate className="form-horizontal" onSubmit={handleSubmit}>
      {!!isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <h2>{!!oldModel && Object.keys(oldModel).length !== 0 ? 'Referral Source' : 'New Referral Source'}</h2>
      <div className="row">
        <div className="col-lg-6">
          <h3>Physical Address</h3>
          <FormGroup>
            <FormLabel htmlFor="name">Company/Individual Name</FormLabel>
            <input
              type="text"
              className="form-control"
              maxLength={validations.maxLengths.name}
              {...createInputProps('name')}
            ></input>
            <ErrorMessage name="name" />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="addressLine1">Address</FormLabel>
            <input
              type="text"
              className="form-control"
              maxLength={validations.maxLengths.address.line1}
              {...createInputProps('addressLine1')}
            ></input>
            <ErrorMessage name="addressLine1" />
          </FormGroup>
          <div className="form-row">
            <div className="col-6">
              <FormGroup>
                <FormLabel htmlFor="addressCity">City</FormLabel>
                <input
                  type="text"
                  className="form-control"
                  maxLength={validations.maxLengths.address.city}
                  {...createInputProps('addressCity')}
                ></input>
              </FormGroup>
            </div>
            <div className="col-2">
              <FormGroup>
                <FormLabel htmlFor="addressState">State</FormLabel>
                <StateSelector {...createInputProps('addressState')} />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup>
                <FormLabel htmlFor="addressZip">Zip Code</FormLabel>
                <input type="text" className="form-control" {...createInputProps('addressZip')}></input>
              </FormGroup>
            </div>
          </div>
          <div>
            <ErrorMessage name="addressCity" />
            <ErrorMessage name="addressState" />
            <ErrorMessage name="addressZip" />
          </div>
          <div className="form-row">
            <div className="col">
              <FormGroup>
                <FormLabel htmlFor="phone">Phone</FormLabel>
                <input type="tel" className="form-control" {...createInputProps('phone')}></input>
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup>
                <FormLabel htmlFor="fax">Fax</FormLabel>
                <input type="tel" className="form-control" {...createInputProps('fax')}></input>
              </FormGroup>
            </div>
          </div>
          <div>
            <ErrorMessage name="phone" />
            <ErrorMessage name="fax" />
          </div>
          <FormGroup>
            <FormLabel htmlFor="email">Email</FormLabel>
            <input
              type="email"
              className="form-control"
              maxLength={validations.maxLengths.email}
              {...createInputProps('email')}
            ></input>
            <ErrorMessage name="email" />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="guidelinesFile">Guidelines</FormLabel>
            <div className="input-group custom-file">
              <input
                className="form-control custom-file-input form-control-file"
                id="guidelinesFile"
                // eslint-disable-next-line react/no-unknown-property
                accepts={fileControlAccepts.join(', ')}
                onChange={handleOnFileChange}
                type="file"
                aria-describedby="paperclipIcon"
              />
              <label htmlFor="guidelinesFile" className="custom-file-label">
                {guidelinesFileText}
                <span id="paperclipIcon" className="fas fa-paperclip input-group-append"></span>
              </label>
            </div>
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="notes">Notes</FormLabel>
            <textarea
              className="form-control"
              maxLength={validations.maxLengths.note}
              {...createInputProps('notes')}
              rows="6"
            ></textarea>
            <ErrorMessage name="notes" />
          </FormGroup>
        </div>
        <div className="col-lg-6">
          <div className="form-row">
            <span className="h3 col">Billing Address</span>

            <div className="form-check form-check-inline float-right">
              <label className="form-check-label" htmlFor="billingAddressSame">
                Same as Physical
              </label>
              <input
                className="form-check-input mr-0 ml-2"
                type="checkbox"
                checked={model.billingAddressSame}
                {...createInputProps('billingAddressSame')}
              />
            </div>
          </div>
          <FormGroup>
            <FormLabel htmlFor="billingName">Company/Individual Name</FormLabel>
            <input
              type="text"
              className="form-control"
              maxLength={validations.maxLengths.name}
              disabled={model.billingAddressSame}
              {...createInputProps('billingName')}
            ></input>
            <ErrorMessage name="billingName" />
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="billingAddressLine1">Address</FormLabel>
            <input
              type="text"
              className="form-control"
              maxLength={validations.maxLengths.address.line1}
              disabled={model.billingAddressSame}
              {...createInputProps('billingAddressLine1')}
            ></input>
            <ErrorMessage name="billingAddressLine1" />
          </FormGroup>
          <div className="form-row">
            <div className="col-6">
              <FormGroup>
                <FormLabel htmlFor="billingAddressCity">City</FormLabel>
                <input
                  type="text"
                  className="form-control"
                  maxLength={validations.maxLengths.address.city}
                  disabled={model.billingAddressSame}
                  {...createInputProps('billingAddressCity')}
                ></input>
              </FormGroup>
            </div>
            <div className="col-2">
              <FormGroup>
                <FormLabel htmlFor="billingAddressState">State</FormLabel>
                <StateSelector disabled={model.billingAddressSame} {...createInputProps('billingAddressState')} />
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup>
                <FormLabel htmlFor="billingAddressZip">Zip Code</FormLabel>
                <input
                  type="text"
                  className="form-control"
                  disabled={model.billingAddressSame}
                  {...createInputProps('billingAddressZip')}
                ></input>
              </FormGroup>
            </div>
          </div>
          <div>
            <ErrorMessage name="billingAddressCity" />
            <ErrorMessage name="billingAddressState" />
            <ErrorMessage name="billingAddressZip" />
          </div>
          <div className="form-row">
            <div className="col">
              <FormGroup>
                <FormLabel htmlFor="billingPhone">Phone</FormLabel>
                <input
                  type="tel"
                  className="form-control"
                  disabled={model.billingAddressSame}
                  {...createInputProps('billingPhone')}
                ></input>
              </FormGroup>
            </div>
            <div className="col">
              <FormGroup>
                <FormLabel htmlFor="billingFax">Fax</FormLabel>
                <input
                  type="tel"
                  className="form-control"
                  disabled={model.billingAddressSame}
                  {...createInputProps('billingFax')}
                ></input>
              </FormGroup>
            </div>
          </div>
          <div>
            <ErrorMessage name="billingPhone" />
            <ErrorMessage name="billingFax" />
          </div>
          <FormGroup>
            <FormLabel htmlFor="billingEmail">Email</FormLabel>
            <input
              type="email"
              className="form-control"
              maxLength={validations.maxLengths.email}
              disabled={model.billingAddressSame}
              {...createInputProps('billingEmail')}
            ></input>
            <ErrorMessage name="billingEmail" />
          </FormGroup>
        </div>
      </div>
      {errorMsg && (
        <div className="alert alert-danger">
          <pre>{errorMsg}</pre>
        </div>
      )}
      <div className="text-right">
        <Button variant="secondary" id="cancelBtn" className="mr-1" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" id="saveBtn" type="submit">
          Save
        </Button>
      </div>
    </form>
  )
}

export default NewCompany

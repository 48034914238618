import React from 'react'

import './DashboardWrapper.scss'

const TimeOfDay = () => {
  const hrs = new Date().getHours()
  if (hrs >= 18) return 'Evening'
  if (hrs >= 12) return 'Afternoon'
  return 'Morning'
}

export default function DashboardWrapper({ user, children }) {
  return (
    <div className="DashboardWrapper-component">
      <div className="that-nice-purpleish-background-at-the-top"></div>
      <h1 className="text-light font-weight-bold mb-2">
        Good {TimeOfDay()}
        {!!user && !!user.firstName ? `, ${user.firstName}` : ''}!
      </h1>
      {children}
    </div>
  )
}

import React from 'react'
import Select, { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import sbc from 'shade-blend-color'

import './Select.scss'

const themeColorPrimary = '#4c5bae'
const themeColorPrimary25 = sbc(0.75, themeColorPrimary)
const themeColorPrimary50 = sbc(0.5, themeColorPrimary)
const themeColorPrimary75 = sbc(0.25, themeColorPrimary)

const themeFactory = theme => ({
  ...theme,
  borderRadius: 8,
  colors: {
    ...theme.colors,
    primary: themeColorPrimary,
    primary25: themeColorPrimary25,
    primary50: themeColorPrimary50,
    primary75: themeColorPrimary75,
  },
})

export default ({ onChange, onBlur, footerText, onFooterClick, allowFreeType, ...props }) => {
  const ctrlComponents = {}
  let itemSelected = false

  if (footerText) {
    ctrlComponents.MenuList = props => (
      <components.MenuList {...props}>
        {props.children}
        <button
          className="btn btn-block btn-primary react-select-footer"
          id={`${props.id}.footer`}
          onClick={onFooterClick}
        >
          {footerText}
        </button>
      </components.MenuList>
    )
  }

  const handleChange = v => {
    itemSelected = true
    onChange({
      target: {
        type: 'react-select',
        name: props.name,
        value: v.value,
      },
    })
  }

  const handleBlur = v => {
    if (!itemSelected) {
      if (v.target.value) {
        onChange({
          target: {
            type: 'react-select',
            name: props.name,
            value: v.target.value,
          },
        })
      }
    } else {
      itemSelected = false
    }

    if (onBlur) {
      onBlur()
    }
  }

  if (allowFreeType) {
    return (
      <CreatableSelect
        className="react-select-component"
        {...props}
        components={ctrlComponents}
        onChange={handleChange}
        onBlur={handleBlur}
        isSearchable
        theme={themeFactory}
        formatCreateLabel={userInput => userInput}
      />
    )
  } else {
    return (
      <Select
        className="react-select-component"
        {...props}
        isSearchable
        components={ctrlComponents}
        onChange={handleChange}
        onBlur={handleBlur}
        theme={themeFactory}
      />
    )
  }
}

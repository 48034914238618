import * as yup from 'yup'

import { SUGGESTED_FIELD_LENGTH } from '../utils/Constants'

const phonePattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
const zipcodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/
const datePattern = /\d{1,2}\/\d{1,2}\/\d{4}/

const maxLengths = {
  note: 2048,
  time: 10,
  distance: 10,
  cost: 10,
  name: SUGGESTED_FIELD_LENGTH,
  shortText: SUGGESTED_FIELD_LENGTH,
  description: SUGGESTED_FIELD_LENGTH,
  email: SUGGESTED_FIELD_LENGTH,
  address: {
    line1: SUGGESTED_FIELD_LENGTH,
    city: SUGGESTED_FIELD_LENGTH,
    state: 2,
    zip: 10,
  },
}

const isRequired = (required, x) => {
  if (required) return x.required()

  return x.nullable()
}

const name = (name = 'Name') => yup.string().label(name).max(maxLengths.name)
const shortText = name => yup.string().label(name).max(maxLengths.shortText)

const number = name => yup.number().label(name)

const phone = (name = 'Phone Number') =>
  yup
    .string()
    .label(name)
    .transform(value => (value === '' ? null : value))
    .matches(phonePattern, `${name} must be in format ###-###-####`)
    .nullable()

const fax = (name = 'Fax Number') => phone(name)

const email = (name = 'Email') =>
  yup
    .string()
    .label(name)
    .max(maxLengths.email)
    .transform(value => (value === '' ? null : value))
    .email(`${name} must be valid.`)
    .nullable()

const note = name => yup.string().label(name).max(maxLengths.note).nullable()

const addressLine1 = (name = 'Address line 1') => yup.string().label(name).max(maxLengths.address.line1)
const addressCity = (name = 'Address city') => yup.string().label(name).max(maxLengths.address.city)
const addressState = (name = 'Address state') => yup.string().label(name).max(maxLengths.address.state)
const addressZip = (name = 'Address zip') =>
  yup
    .string()
    .label(name)
    .transform(value => (value === '' ? null : value))
    .matches(zipcodePattern, `${name} must be formatted as "#####" or "#####-####"`)
    .nullable()

const address = (name = 'Address', required) =>
  yup.object().shape({
    line1: isRequired(required, addressLine1(`${name} line 1`)),
    city: isRequired(required, addressCity(`${name} city`)),
    state: isRequired(required, addressState(`${name} state`)),
    zip: isRequired(required, addressZip(`${name} zip`)),
  })

const date = (name = 'Date') =>
  yup
    .string()
    .label(name)
    .transform(value => (value === '' ? null : value))
    .nullable()

const birthdate = (name = 'Date of Birth') =>
  yup
    .string()
    .label(name)
    .matches(datePattern, 'Date of Birth must be in format ##/##/####')
    .transform(value => (value === '' ? null : value))
    .nullable()
    .test('is-valid-dob', 'Date of Birth is not a valid date', value => {
      if (!value) return true
      return !isNaN(new Date(value).getTime())
    })
    .test('is-valid-dob', 'Date of Birth must be on or after 1/1/1900', value => {
      if (!value) return true
      return new Date('1/1/1900') <= new Date(value)
    })
    .test('is-valid-dob', 'Date of Birth cannot be in the future', value => {
      if (!value) return true
      return new Date() > new Date(value)
    })

const expenseType = (name = 'Type') => yup.string().label(name)

const expense = (name = 'Expense', required) =>
  yup.object().shape({
    type: isRequired(required, expenseType('Type of Expense')),
    date: yup.date().required(),
    amount: yup
      .string()
      .label('Amount')
      .max(maxLengths.cost)
      .when('type', {
        is: value => value !== 'Mileage',
        then: yup.string().required(),
        otherwise: yup.string().nullable(),
      }),
    distance: yup.string().label('Distance').max(maxLengths.distance).when('type', {
      is: 'Mileage',
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
    description: isRequired(false, shortText('Description')),
  })

const activity = (name = 'Activity', required) =>
  yup.object().shape({
    type: isRequired(required, shortText('Type of Activity')),
    date: yup.date().required(),
    time: yup
      .string()
      .label('Time')
      .max(maxLengths.time)
      .transform(value => (value === null ? '' : value))
      .required(),
    description: isRequired(false, shortText('Description')),
  })

const object = shape => yup.object().shape(shape)
const arrayOf = shape => yup.array().of(shape)

const transformValidationErrorToErrorObject = validationErrors => {
  const errors = {}

  validationErrors.inner.forEach(error => {
    errors[error.path] = error.message
  })

  return errors
}

const validate = (model, schema) => {
  try {
    schema.validateSync(model, { abortEarly: false })
    return {
      success: true,
    }
  } catch (err) {
    if (err.name === 'ValidationError') {
      return {
        success: false,
        errors: transformValidationErrorToErrorObject(err),
      }
    }
    throw err
  }
}

export default {
  name,
  shortText,
  number,
  phone,
  fax,
  note,
  email,
  address,
  addressLine1,
  addressCity,
  addressState,
  addressZip,
  date,
  birthdate,
  activity,
  expense,
  object,
  arrayOf,
  maxLengths,
  validate,
}

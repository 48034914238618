import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import format from '../services/format'

import CaseSummary from './CaseSummary'

const ReportDashboardCard = ({ caseReport, settings }) => {
  const { path } = useRouteMatch()

  return (
    <div className="card shadow">
      <div className="card-body">
        <h5 className="card-title">
          {caseReport.case.client.firstName} {caseReport.case.client.lastName}
        </h5>
        <h6 className="card-subtitle text-muted">Case #: {caseReport.caseNumber}</h6>
        <hr />
        <CaseSummary
          caseSummaryModel={caseReport}
          reportDaysTarget={settings.reportDaysTarget}
          reportHoursTarget={settings.reportHoursTarget}
        />

        <div className="text-muted mt-3 mb-2">Submitted on {format.dateTime(caseReport.submittedOnUtc)}</div>

        <Link
          to={`/cases/${caseReport.caseNumber}/reports/${caseReport.reportNumber}?returnTo=${encodeURIComponent(path)}`}
          className="btn btn-secondary btn-block"
        >
          Review
        </Link>
      </div>
    </div>
  )
}

export default ReportDashboardCard

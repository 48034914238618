import React, { useRef, useState } from 'react'
import { useAuth } from 'react-oidc-context'

export const DownloadButton = ({ url, children, className, defaultExtension = '' }) => {
  const ref = useRef(null)
  const [downloadUrl, setDownloadUrl] = useState()
  const [name, setFileName] = useState()
  const auth = useAuth()

  const download = async () => {
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`,
        },
      })

      const filename = response.headers.get('content-disposition')?.split('"')[1] ?? `download.${defaultExtension}`
      const requestUrl = URL.createObjectURL(new Blob([await response.blob()]))
      setDownloadUrl(requestUrl)
      setFileName(filename)
      ref.current?.click()
      URL.revokeObjectURL(requestUrl)
    } catch (error) {}
  }

  return (
    <>
      <a
        href={downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
        ref={ref}
        download={name}
        style={{ display: 'none' }}
      >
        Export
      </a>
      <button className={className} onClick={download}>
        {children}
      </button>
    </>
  )
}

import logger from './logger'

/* USAGE:
SIMPLE:
  return <>
    <input id="inputId" />
    <button onClick={() => setFocusById('inputId')} />
  </>

ARRAY:
  return <>
    {items.map((x, index) => <input key={index} id={`items[${index}].value`} />)}
    <button onClick={() => { addItem({}); setFocusById(`items[${items.length}].value`); }} />
  </>
*/

export default function setFocusById(id) {
  let attempt = 0
  tryToFocus()

  function tryToFocus() {
    attempt++
    const element = window.document.getElementById(id)

    if (element) {
      element.focus()
    } else {
      if (attempt > 100) {
        logger.warn(`Have unsuccessfully attempted for 100 iterations to set focus on ${id}.`)
      }

      setTimeout(tryToFocus)
    }
  }
}

import React from 'react'

import Select from './controls/Select'

const CustomDropDown = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  items,
  className,
  placeholderText,
  noOptionsMessage,
  allowFreeType,
  isDisabled = false,
}) => {
  const options = items.map(x => ({ value: x.id, label: x.name }))
  let selectedValue = ''

  if (value != null) {
    selectedValue = options.find(x => x.value.toString() === value.toString()) || { value: -1, label: value }
  }

  return (
    <Select
      id={id}
      name={name}
      value={selectedValue}
      className={className}
      placeholder={placeholderText}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      isDisabled={isDisabled}
      autoComplete="off"
      noOptionsMessage={() => noOptionsMessage}
      allowFreeType={allowFreeType}
    />
  )
}

export default CustomDropDown

import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { useParams, withRouter } from 'react-router-dom'

import Slide from '@material-ui/core/Slide'
import Moment from 'moment'

import DatePicker from '../components/controls/DatePicker'
import { DownloadButton } from '../components/DownloadButton'
import FAIcon from '../components/FAIcon'
import NavigationBar from '../components/NavigationBar'
import createFormHelpers from '../FormHelpers'
import UserService from '../services/UserService'
import validation from '../validation'

import './ExportProductivityReport.scss'

const ExportProductivityReport = withRouter(({ history }) => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [reportParameters, setReportParameters] = useState({})
  const [user, setUser] = useState({})
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [touched, setTouched] = useState({})

  const { createInputProps, getValue, ErrorMessage, isValidTimeRange } = createFormHelpers({
    touchedObject: touched,
    setTouchedFunction: setTouched,
    submitAttempted,
    validationSchema: getValidationSchema(),
  })

  function getValidationSchema() {
    return validation.object({
      start: validation.name('Start Date').required().nullable(),
      end: validation.name('End Date').required().nullable(),
    })
  }

  useEffect(() => {
    if (id) {
      UserService.get(id)
        .then(userObj => {
          setUser(userObj)
          setIsLoading(false)
        })
        .catch(err => {
          setError(err.message)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
    // eslint-disable-next-line
    }, []);

  const handleClickExit = () => {
    history.push('/library')
  }

  const areFieldsValid = () => {
    const result = validation.validate(reportParameters, getValidationSchema())
    return !!result && result.success && !showTimeRangeError
  }

  const showTimeRangeError =
    !!reportParameters.start &&
    !!reportParameters.end &&
    !isValidTimeRange(reportParameters.start, reportParameters.end)
  const startDate = Moment(reportParameters.start).format()
  const endDate = Moment(reportParameters.end).endOf('day').format()

  return (
    <React.Fragment>
      <Helmet bodyAttributes={{ style: 'background-color : #fff !important' }} />
      <Slide className="user-productivity-report-container" direction="up" in={true} mountOnEnter unmountOnExit>
        <div>
          <NavigationBar
            title={`PRODUCTIVITY REPORT${
              id ? ` - ${getValue('firstName', '', user)} ${getValue('lastName', '', user)}` : ''
            }`}
            leftBtnText={<FAIcon type="fa fa-lg close-icon" name={'times'} />}
            handleClickLeft={handleClickExit}
          />
          {!!error && (
            <Alert variant="danger" className="mb-0 pr-5 pl-5">
              <strong>Error:</strong> <pre className="mb-0">{error}</pre>
            </Alert>
          )}
          {!isLoading ? (
            <div className="container my-5">
              <div style={{ maxWidth: '40rem' }}>
                <h5 className="text-primary">Select the desired date range:</h5>
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label mt-2" htmlFor={'start'}>
                        Start Date
                      </label>
                      <DatePicker
                        {...createInputProps({
                          name: 'start',
                          model: reportParameters,
                          setModelFunction: setReportParameters,
                        })}
                        autoComplete="off"
                      />
                      <ErrorMessage name="start" model={reportParameters} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label mt-2" htmlFor={'end'}>
                        End Date
                      </label>
                      <DatePicker
                        {...createInputProps({
                          name: 'end',
                          model: reportParameters,
                          setModelFunction: setReportParameters,
                        })}
                        autoComplete="off"
                      />
                      <ErrorMessage name="end" model={reportParameters} />
                    </div>
                  </div>
                </div>
                {showTimeRangeError && <div className={'text-danger form-text'}>Invalid time range selected.</div>}
                <DownloadButton
                  onClick={() => setSubmitAttempted(true)}
                  className={'mt-4 btn-primary btn'}
                  defaultExtension="xlsx"
                  url={
                    areFieldsValid()
                      ? `/api/productivity-report/download?filterUserId=${id}&filterStart=${startDate}&filterEnd=${endDate}`
                      : undefined
                  }
                >
                  Download Report
                </DownloadButton>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center mt-3">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </Slide>
    </React.Fragment>
  )
})

export default ExportProductivityReport

import React, { useRef, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import CasesService from '../../../services/CasesService'

import ReportPageTemplate from './ReportPageTemplate'

export default withRouter(({ caseNumber, user, settings, showError, history }) => {
  const [caseReportPromise] = useState(CasesService.getInProgressReport(caseNumber))
  const [filesPromise] = useState(CasesService.getFiles(caseNumber))
  const [showSubmitReportConfirmation, setShowSubmitReportConfirmation] = useState(false)
  const [showReportSubmitting, setReportSubmitting] = useState(false)
  const reportModelRef = useRef()

  const handleSubmitReport = () => {
    setShowSubmitReportConfirmation(true)
  }

  const handleCloseSubmitReportConfirmationModal = () => {
    setShowSubmitReportConfirmation(false)
  }

  const handleSubmitReportConfirmed = () => {
    setShowSubmitReportConfirmation(false)

    setReportSubmitting(true)
    CasesService.submitReport(caseNumber)
      .onSuccess(r => {
        const reportNumber = r.reportNumber
        history.push(`/cases/${caseNumber}/reports/${reportNumber}`)
      })
      .onBadRequest(err => {
        showError(err)
      })
      .catch(() => {
        showError('An unexpected error occurred while trying to submit a report')
      })
      .then(() => {
        setReportSubmitting(false)
      })
  }

  const CannotSubmitModal = ({ reasons }) => {
    return (
      <Modal show={true} onHide={handleCloseSubmitReportConfirmationModal}>
        <Modal.Body>
          <h1 className="h6">Cannot submit for review until the follow issues are resolved:</h1>

          <div className="mb-4">
            {reasons.map((reason, i) => (
              <div key={i} className="text-danger">
                {reason}
              </div>
            ))}
          </div>

          <div className="text-right">
            <button type="button" className="btn btn-secondary mr-1" onClick={handleCloseSubmitReportConfirmationModal}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const SubmitReportConfirmationModal = () => {
    if (reportModelRef.current.invalid) return <CannotSubmitModal reasons={reportModelRef.current.invalidReasons} />

    return (
      <Modal show={true} onHide={handleCloseSubmitReportConfirmationModal}>
        <Modal.Body>
          <h1 className="h6">Ready to Submit for Review?</h1>
          <p>Once the report has been submitted, the action cannot be undone.</p>
          <div className="text-right">
            <button type="button" className="btn btn-secondary mr-1" onClick={handleCloseSubmitReportConfirmationModal}>
              Cancel
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSubmitReportConfirmed}>
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const SubmitForReview = ({ reportModel }) => {
    reportModelRef.current = reportModel

    if (!showReportSubmitting) {
      return (
        <button id="submit-for-review-btn" className="btn btn-primary" onClick={handleSubmitReport}>
          Submit for Review
        </button>
      )
    } else {
      return (
        <>
          <button className="btn btn-primary" disabled>
            <Spinner animation="border" size="sm" className="text-white" />
            &nbsp; Submitting...
          </button>
        </>
      )
    }
  }

  return (
    <>
      <ReportPageTemplate
        caseNumber={caseNumber}
        isInProgress={true}
        user={user}
        caseReportPromise={caseReportPromise}
        filesPromise={filesPromise}
        settings={settings}
        actionsComponent={SubmitForReview}
        printableReportPath={`/cases/${caseNumber}/reports/in-progress/print`}
      />

      {showSubmitReportConfirmation && <SubmitReportConfirmationModal />}
    </>
  )
})

import React from 'react'
import { Button } from 'react-bootstrap'

const CaseCloseButton = ({ handleCaseClose, isClosed }) => {
  return (
    <>
      <Button
        variant="danger"
        type="button"
        className="close-case-button float-right"
        onClick={() => {
          handleCaseClose()
        }}
      >
        {isClosed ? 'Reopen Case' : 'Close Case'}
      </Button>
    </>
  )
}

export default CaseCloseButton

import { getToken } from '../utils/getToken'

export default {
  get,
  getCaseAppointments,
  getAll,
  getFiles,
  create,
  update,
}

function handleSuccess(result) {
  if (!result.ok) throw new Error(result)
  return result.json()
}

function handleFailure(defaultErrorMessage, err) {
  throw new Error(defaultErrorMessage || err)
}

function getFiles(caseNumber, appointmentNumber) {
  return fetch(`/api/cases/${caseNumber}/appointments/${appointmentNumber}/files`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve the list of requested files.', err))
}

function get(caseNumber, appointmentNumber) {
  return fetch(`/api/cases/${caseNumber}/appointments/${appointmentNumber}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve the list of appointments.', err))
}

function getCaseAppointments(caseNumber, filter) {
  let url = `/api/cases/${caseNumber}/appointments`

  if (filter) {
    url += `?$filter="${encodeURIComponent(filter)}"`
  }

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve the list of appointments.', err))
}

function getAll(incompleteOnly = false) {
  return fetch(`/api/appointments?incompleteOnly=${incompleteOnly}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve the list of appointments.', err))
}

function create(caseNumber, model) {
  return fetch(`/api/cases/${caseNumber}/appointments`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(model),
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to create appointment.', err))
}

function update(caseNumber, appointmentNumber, model) {
  return fetch(`/api/cases/${caseNumber}/appointments/${appointmentNumber}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(model),
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to update appointment.', err))
}

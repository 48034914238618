import React from 'react'

import FAIcon from '../components/FAIcon'

import Select from './controls/Select'

const AdjusterSelecter = ({ onChange, name, value, onAddRequested, adjusters, onEditRequested, isAdmin = false }) => {
  const options = adjusters.map(x => ({ value: x.id, label: x.name }))
  const selectedValue = options.find(x => x.value === value)

  return (
    <div>
      <div className="d-inline-block col-10 px-0">
        <Select
          name={name}
          id={name}
          value={selectedValue}
          placeholder="Search for an adjuster"
          options={options}
          onChange={onChange}
          footerText={isAdmin ? '+ Add new adjuster...' : undefined}
          onFooterClick={onAddRequested}
          noOptionsMessage={() => 'No results found'}
        />
      </div>
      {!!selectedValue && isAdmin && (
        <button
          type="button"
          className="fa-button"
          onClick={() => {
            if (onEditRequested && !!selectedValue) {
              onEditRequested(selectedValue.value)
            }
          }}
        >
          <FAIcon type="fas" name="pen" className="d-inline-block text-primary col-2" />
        </button>
      )}
    </div>
  )
}

export default AdjusterSelecter

import React, { useCallback, useState } from 'react'

import clsx from 'clsx'

/** @typedef { import('./SearchBox').SearchBoxProps } SearchBoxProps */

/**
 * Renders a search box.
 * @param {SearchBoxProps} props
 */
export function SearchBox(props) {
  const { onChange, onSubmit, className, placeholder, ...divProps } = props
  const [searchInput, setSearchInput] = useState('')

  const onSearchChanged = useCallback(
    e => {
      e.persist()

      const { value } = e.target

      setSearchInput(value)
      onChange(value)
    },
    [onChange],
  )

  const handleOnSubmit = useCallback(
    e => {
      e.preventDefault()
      onSubmit(searchInput)
    },
    [onSubmit, searchInput],
  )

  return (
    <div {...divProps} className={clsx('card border-light p-2 my-1', className)}>
      <div className="row">
        <form className="col" onSubmit={handleOnSubmit}>
          <div className="form-row align-items-center">
            <div className="col">
              <label className="sr-only" htmlFor="searchText">
                Search
              </label>
              <input
                type="search"
                name="searchText"
                className="form-control"
                id="searchText"
                value={searchInput}
                onChange={onSearchChanged}
                placeholder={placeholder}
              />
            </div>
            <div className="col-auto my-1">
              <button type="submit" className="btn btn-primary">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

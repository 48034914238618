import React, { useEffect, useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Helmet from 'react-helmet'
import { useParams, withRouter } from 'react-router-dom'

import Slide from '@material-ui/core/Slide'
import Moment from 'moment'

import Activity from '../components/Activity'
import CaseNavigationBar from '../components/case/CaseNavigationBar'
import AddButton from '../components/controls/AddButton'
import FilePicker from '../components/controls/FilePicker'
import RemoveButton from '../components/controls/RemoveButton'
import Expense from '../components/Expense'
import FAIcon from '../components/FAIcon'
import createFormHelpers from '../FormHelpers'
import AppointmentService from '../services/AppointmentsService'
import CasesService from '../services/CasesService'
import format from '../services/format'
import logger from '../services/logger'
import setFocusById from '../services/setFocusById'
import validation from '../validation'

import './CompleteAppointment.scss'

const CompleteAppointment = withRouter(({ history }) => {
  const { caseNumber, appointmentNumber } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [showRequiredActivitiesError, setShowRequiredActivitiesError] = useState('')
  const [fileAttachError, setFileAttachError] = useState()
  const formRef = useRef(null)
  const [caseModel, setCaseModel] = useState({})
  const [appointment, setAppointment] = useState({})
  const [caseFiles, setCaseFiles] = useState([])
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [touched, setTouched] = useState({})

  const {
    createInputProps,
    setModelValue,
    setModelValues,
    getValue,
    ErrorMessage,
    addNestedItemToArray,
    removeNestedItemFromArray,
  } = createFormHelpers({
    setModelFunction: setAppointment,
    touchedObject: touched,
    setTouchedFunction: setTouched,
    submitAttempted,
    validationSchema: getValidationSchema(),
  })

  function getValidationSchema() {
    return validation.object({
      subjectiveNote: validation.name('Subjective note').required().nullable().max(1_000_000_000),
      objectiveNote: validation.name('Objective note').required().nullable().max(1_000_000_000),
      assessmentNote: validation.name('Assessment note').required().nullable().max(1_000_000_000),
      planNote: validation.name('Plan note').required().nullable().max(1_000_000_000),
      activities: validation.arrayOf(validation.activity('Activity', true)),
      expenses: validation.arrayOf(validation.expense('Expense', true)),
      tasks: validation
        .arrayOf(
          validation.object({
            title: validation.name('Task title').required(),
          }),
        )
        .nullable(),
    })
  }

  useEffect(() => {
    if (!!caseNumber && !!appointmentNumber) {
      setIsLoading(true)

      const casePromise = CasesService.get(caseNumber).then(caseObj => {
        setCaseModel(caseObj)
        return caseObj
      })

      const appointmentPromise = AppointmentService.get(caseNumber, appointmentNumber).then(appointment => {
        setAppointment(appointment)
        return appointment
      })

      const filesPromise = AppointmentService.getFiles(caseNumber, appointmentNumber).then(files => {
        setCaseFiles(
          files.map(x => {
            x.hasBeenSaved = true
            return x
          }),
        )
        return files
      })

      // wait for everything to be done before we clear the loading indicator.
      Promise.all([casePromise, appointmentPromise, filesPromise])
        .then(result => {
          if (!!result && result.length === 3) {
            const caseObjResult = result[0]
            const appointmentResult = result[1]

            const activities = getValue('activities', [], appointmentResult)
            const userId = getValue('caseManager', '', caseObjResult)

            if (activities.length === 0) {
              appointmentResult.activities = [
                {
                  date: new Date(format.date(result[1].startUtc)),
                  type: 'Appointment',
                  time: '',
                  description: '',
                  userId,
                },
              ]
            }
          }

          setIsLoading(false)
        })
        .catch(err => {
          setError(err.message)
          setIsLoading(false)
        })
    }
    /* TODO: Review this ASAP */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFileAttach = file => {
    setFileAttachError(null)
    const newFiles = caseFiles.concat(file)
    setCaseFiles(newFiles)
    addNestedItemToArray('fileIds', file.id, appointment, setAppointment)
  }

  const handleClickExit = () => {
    history.goBack()
  }

  const handleClickSave = () => {
    setSubmitAttempted(true)
    const caseNumber = getValue('caseNumber', '', appointment)

    if (!!caseNumber && areFieldsValid()) {
      // set the appointment as complete
      const completedAppointment = appointment
      completedAppointment.complete = true

      setIsLoading(true)
      return AppointmentService.update(caseNumber, appointmentNumber, completedAppointment)
        .then(result => {
          // mark files as now saved
          setCaseFiles(caseFiles.map(x => ({ ...x, hasBeenSaved: true })))

          setError(null)
          handleClickExit()
          setIsLoading(false)
        })
        .catch(() => {
          setModelValue('complete', false, appointment, setAppointment)
          setIsLoading(false)
          setError('An unexpected error occurred while trying to save the appointment')
        })
    } else {
      const hasActivities = getValue('activities', [], appointment).length !== 0

      if (!hasActivities) {
        setShowRequiredActivitiesError(true)
      }
    }
  }

  const areFieldsValid = () => {
    const result = validation.validate(appointment, getValidationSchema())
    const hasActivities = getValue('activities', [], appointment).length !== 0

    return !!result && result.success && !!hasActivities
  }

  const handleAddExpense = () => {
    const expenses = getValue('expenses', [], appointment)
    const nextIndex = expenses.length
    const userId = getValue('caseManager', '', caseModel)

    setFocusById(`expenses[${nextIndex}].type`)
    addNestedItemToArray(
      'expenses',
      { date: new Date(format.date(appointment.startUtc)), type: '', amount: 0, distance: 0, description: '', userId },
      appointment,
      setAppointment,
    )
  }

  const handleAddActivity = () => {
    const activities = getValue('activities', [], appointment)
    const nextIndex = activities.length
    const userId = getValue('caseManager', '', caseModel)

    setFocusById(`activities[${nextIndex}].type`)
    addNestedItemToArray(
      'activities',
      { date: new Date(format.date(appointment.startUtc)), type: '', time: '', description: '', userId },
      appointment,
      setAppointment,
    )
    setShowRequiredActivitiesError(false)
  }

  const handleAddTask = () => {
    const tasks = getValue('tasks', [], appointment)
    const nextIndex = tasks.length
    setFocusById(`tasks[${nextIndex}].title`)
    addNestedItemToArray('tasks', { title: '' }, appointment, setAppointment)
  }

  const handleRemoveActivity = index => {
    if (getValue('activities', [], appointment).length < 2 && submitAttempted) {
      setShowRequiredActivitiesError(true)
    }

    removeNestedItemFromArray('activities', index, appointment, setAppointment)
  }

  const triggerFormSubmission = () => {
    formRef.current.dispatchEvent(new Event('submit'))
  }

  const getFormattedDateTimeValue = (startUtc, endUtc) => {
    if (!!startUtc && !!endUtc) {
      const date = Moment.utc(startUtc).local().format('dddd, MMMM DD, YYYY')
      const startTime = Moment.utc(startUtc).local().format('h:mma')
      const endTime = Moment.utc(endUtc).local().format('h:mma')

      return (
        <p className="text-details">
          {date} &#8226; {startTime}-{endTime}
        </p>
      )
    } else {
      return ''
    }
  }

  const expenseOnChangeHandler = (e, namePrefix) => {
    const modelPaths = [{ name: e.target.name, value: e.target.value }]

    if (e.target.name.includes('type') && e.target.value === 'Mileage') {
      modelPaths.push({ name: `${namePrefix}.amount`, value: 0 })
    } else if (e.target.name.includes('type')) {
      modelPaths.push({ name: `${namePrefix}.distance`, value: 0 })
    }

    setModelValues(modelPaths, appointment, setAppointment)
  }

  return (
    <React.Fragment>
      <Helmet bodyAttributes={{ style: 'background-color : #fff !important' }} />
      <Slide className="complete-appointment-container background" direction="up" in={true} mountOnEnter unmountOnExit>
        <div>
          <CaseNavigationBar
            title={`${getValue('client.firstName', '', caseModel).toUpperCase()} ${getValue(
              'client.lastName',
              '',
              caseModel,
            ).toUpperCase()}`}
            subtitle={`CASE NO: ${caseNumber}`}
            rightButtonText={'COMPLETE'}
            handleClickLeft={handleClickExit}
            handleRightClick={triggerFormSubmission}
          />
          {!!error && (
            <Alert variant="danger" className="mb-0 pr-5 pl-5">
              <strong>Error:</strong> <pre className="mb-0">{error}</pre>
            </Alert>
          )}
          {!isLoading ? (
            <div className="container">
              <h4 className="mt-5">{getValue('title', '', appointment)}</h4>
              {getFormattedDateTimeValue(getValue('startUtc', '', appointment), getValue('endUtc', '', appointment))}
              <hr className="mt-5" />
              <form className="mt-5 mb-5" ref={formRef} onSubmit={handleClickSave}>
                <h6 className="mt-5 mb-4">Time</h6>
                {getValue('activities', [], appointment).length > 0 && (
                  <div className="form-row hide-sm-down">
                    <p className="form-label col-md-2 mb-1">Date</p>
                    <p className="form-label col-md-2 mb-1">Type of Activity</p>
                    <p className="form-label col-md-2 mb-1">Time</p>
                    <p className="form-label col-md-6 mb-1">Description</p>
                  </div>
                )}
                <div className="position-relative">
                  {getValue('activities', [], appointment).map((x, index) => {
                    const namePrefix = `activities[${index}]`
                    return (
                      <Activity
                        namePrefix={namePrefix}
                        key={index}
                        index={index}
                        model={appointment}
                        setModelFunction={setAppointment}
                        createInputProps={createInputProps}
                        ErrorMessage={ErrorMessage}
                        handleRemove={() => handleRemoveActivity(index)}
                      />
                    )
                  })}
                  <AddButton
                    id="addActivityButton"
                    className={getValue('activities', [], appointment).length > 0 ? 'inline-add-button' : ''}
                    onClick={handleAddActivity}
                  >
                    Add Activity
                  </AddButton>
                  {!!showRequiredActivitiesError && (
                    <div className={'text-danger form-text'}>At least one activity must be added</div>
                  )}
                </div>
                <hr />
                <h6 className="mt-5">SOAP Note</h6>
                <div className="form-row form-group">
                  <div className="col-md-3 d-inline-block my-2">
                    <h6 className="mb-0">
                      Subjective
                      <br />
                    </h6>
                    <p className="note-label mb-0">(Observations)</p>
                  </div>
                  <div className="col-md-9">
                    <textarea
                      {...createInputProps({
                        name: 'subjectiveNote',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                      className="form-control"
                      rows="4"
                    />
                    <ErrorMessage name="subjectiveNote" model={appointment} />
                  </div>
                </div>
                <div className="form-row form-group">
                  <div className="col-md-3 d-inline-block my-2">
                    <h6 className="mb-0">
                      Objective
                      <br />
                    </h6>
                    <p className="note-label mb-0">(Facts)</p>
                  </div>
                  <div className="col-md-9">
                    <textarea
                      {...createInputProps({
                        name: 'objectiveNote',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                      className="form-control"
                      rows="4"
                    />
                    <ErrorMessage name="objectiveNote" model={appointment} />
                  </div>
                </div>
                <div className="form-row form-group">
                  <div className="col-md-3 d-inline-block my-2">
                    <h6 className="mb-0">
                      Assessment
                      <br />
                    </h6>
                    <p className="note-label mb-0">(Results of Appointment)</p>
                  </div>
                  <div className="col-md-9">
                    <textarea
                      {...createInputProps({
                        name: 'assessmentNote',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                      className="form-control"
                      rows="4"
                    />
                    <ErrorMessage name="assessmentNote" model={appointment} />
                  </div>
                </div>
                <div className="form-row form-group">
                  <div className="col-md-3 d-inline-block my-2">
                    <h6 className="mb-0">
                      Plan
                      <br />
                    </h6>
                    <p className="note-label mb-0">(Next Steps)</p>
                  </div>
                  <div className="col-md-9">
                    <textarea
                      {...createInputProps({ name: 'planNote', model: appointment, setModelFunction: setAppointment })}
                      className="form-control"
                      rows="4"
                    />
                    <ErrorMessage name="planNote" model={appointment} />
                  </div>
                </div>
                <div className="form-row form-group">
                  <div className="col-md-3">
                    <h6 className="mt-2">Tasks</h6>
                  </div>
                  <div className="col-md-9">
                    {getValue('tasks', [], appointment).map((task, index) => (
                      <div key={index}>
                        <div className="form-row mx-0 mt-1">
                          <input
                            {...createInputProps({
                              name: `tasks[${index}].title`,
                              model: appointment,
                              setModelFunction: setAppointment,
                            })}
                            type="text"
                            className="form-control mx-0 col-6"
                          />
                          <RemoveButton
                            id={`removeTask[${index}]`}
                            onClick={() => removeNestedItemFromArray('tasks', index, appointment, setAppointment)}
                          />
                        </div>
                        <ErrorMessage name={`tasks[${index}].title`} model={appointment} />
                      </div>
                    ))}
                    <div className="form-row mt-1">
                      <AddButton id="addTask" onClick={handleAddTask}>
                        Add Task
                      </AddButton>
                    </div>
                  </div>
                </div>
                <div className="form-row form-group">
                  <div className="col-md-3">
                    <h6 className="mt-2">Documents</h6>
                  </div>
                  <div className="col-md-5">
                    <React.Fragment>
                      {getValue('fileIds', [], appointment).map((fileId, index) => {
                        if (caseFiles) {
                          const file = caseFiles.find(x => x.id === fileId)
                          if (file) {
                            return (
                              <div key={index} className="d-flex">
                                <div className="flex-grow-1 text-truncate">
                                  {file.hasBeenSaved ? (
                                    <a
                                      href={`/api/cases/${caseNumber}/files/${fileId}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="btn btn-link"
                                    >
                                      {file.name}
                                    </a>
                                  ) : (
                                    <span className="btn btn-link text-secondary text-decoration-none">
                                      {file.name}
                                    </span>
                                  )}
                                </div>
                                <button
                                  type="button"
                                  id={`removeRecord${fileId}Btn`}
                                  className="btn btn-link"
                                  onClick={() =>
                                    removeNestedItemFromArray('fileIds', index, appointment, setAppointment)
                                  }
                                >
                                  <FAIcon name="minus-circle" />
                                </button>
                              </div>
                            )
                          } else {
                            return null
                          }
                        } else {
                          return null
                        }
                      })}
                      {getValue('injury.recordFiles', [], appointment).length < 10 && ( // max of 10 files
                        <React.Fragment>
                          <FilePicker
                            onAttach={onFileAttach}
                            onError={err => {
                              logger.error(err)
                              setFileAttachError(err)
                            }}
                            id="injuryFilePicker"
                          />
                          {fileAttachError && <div className="text-danger">{fileAttachError}</div>}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  </div>
                </div>
                <hr className="mt-5" />
                <h6 className="mt-5 mb-4">Expenses</h6>
                {getValue('expenses', [], appointment).length > 0 && (
                  <div className="form-row hide-sm-down">
                    <p className="form-label col-md-2 mb-1">Date</p>
                    <p className="form-label col-md-2 mb-1">Type of Expense</p>
                    <p className="form-label col-md-2 mb-1">Amount</p>
                    <p className="form-label col-md-2 mb-1">Distance</p>
                    <p className="form-label col-md-4 mb-1">Description</p>
                  </div>
                )}
                <div className="position-relative">
                  {getValue('expenses', [], appointment).map((x, index) => {
                    const namePrefix = `expenses[${index}]`
                    const expenseIsMileage = getValue(`expenses[${index}].type`, '', appointment) === 'Mileage'
                    return (
                      <Expense
                        namePrefix={namePrefix}
                        key={index}
                        createInputProps={createInputProps}
                        onChangeHandler={expenseOnChangeHandler}
                        model={appointment}
                        setModelFunction={setAppointment}
                        ErrorMessage={ErrorMessage}
                        getValue={getValue}
                        expenseIsMileage={expenseIsMileage}
                        handleRemove={() => removeNestedItemFromArray('expenses', index, appointment, setAppointment)}
                      />
                    )
                  })}
                  <AddButton
                    id="addExpenseButton"
                    className={getValue('expenses', [], appointment).length > 0 ? 'inline-add-button' : ''}
                    onClick={handleAddExpense}
                  >
                    Add Expense
                  </AddButton>
                </div>
              </form>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </Slide>
    </React.Fragment>
  )
})

export default CompleteAppointment

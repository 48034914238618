import React from 'react'
import DatePicker from 'react-datepicker'

import Moment from 'moment'

export default ({ name, id, value, onChange, dataFormat = 'YYYY-MM-DD', ...props }) => {
  const handleOnChange = value => {
    const valueToUse = (() => {
      if (!value) return null

      if (dataFormat) return Moment(value).format(dataFormat)

      return value
    })()

    onChange({
      target: {
        type: 'date-picker',
        name,
        value: valueToUse,
      },
    })
  }

  const valueAsDate = value ? Moment(value).toDate() : null

  return (
    <div className="input-group date-picker">
      <DatePicker
        id={id}
        {...props}
        className="form-control"
        selected={valueAsDate}
        value={null}
        onChange={handleOnChange}
      />
      <div className="input-group-append">
        <span id="calendarIcon" className="fas fa-calendar input-group-text"></span>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import CasesService from '../../../services/CasesService'
import format from '../../../services/format'
import DNPTable from '../../DNPTable'

const Component = ({ caseNumber, showError }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [reports, setReports] = useState()

  useEffect(() => {
    const inProgressReportPromise = CasesService.getInProgressReport(caseNumber)
    const reportsPromise = CasesService.getReportsForCase(caseNumber, [
      'reportNumber',
      'submittedOnUtc',
      'fromDateTimeUtc',
      'toDateTimeUtc',
      'totalBillableTime',
      'status',
    ])

    Promise.all([inProgressReportPromise, reportsPromise])
      .then(results => {
        const reports = [results[0], ...results[1]]
        reports.sort((a, b) => (a.reportNumber < b.reportNumber ? 1 : -1))

        setReports(reports)
      })
      .catch(() => {
        showError('An unexpected error occurred while attempting to get the list of reports')
      })
      .then(() => setIsLoading(false))
  }, [caseNumber, showError])

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-2">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div className="container mt-2">
      <div className="mt-4 mb-4 text-right">
        <Link to={`/cases/${caseNumber}/reports/in-progress`} className="btn btn-primary">
          File Report
        </Link>
      </div>

      <DNPTable
        items={reports}
        columns={[
          {
            title: 'Report Name',
            getText: report => {
              if (!report.submittedOnUtc) {
                return (
                  <Link to={`/cases/${caseNumber}/reports/in-progress`}>Progress Report {report.reportNumber}</Link>
                )
              } else {
                return (
                  <Link to={`/cases/${caseNumber}/reports/${report.reportNumber}`}>
                    Progress Report {report.reportNumber}
                  </Link>
                )
              }
            },
          },
          {
            title: 'Activity Dates',
            getText: report => `${format.date(report.fromDateTimeUtc)} - ${format.date(report.toDateTimeUtc)}`,
          },
          { title: 'Billable hours', getText: report => report.totalBillableTime, className: 'text-center' },
          { title: 'Status', getText: report => report.status },
          {
            title: 'Submitted On',
            getText: report => (report.submittedOnUtc ? format.date(report.submittedOnUtc) : <>&mdash;</>),
          },
        ]}
        noItemsText="No Reports"
      />
    </div>
  )
}

export default Component

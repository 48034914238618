import { getToken } from '../utils/getToken'

export default {
  getAll,
  get,
  create,
  update,
}

function handleSuccess(result) {
  if (!result.ok) throw new Error(result)

  return result.json()
}

function handleFailure(defaultErrorMessage, err) {
  throw new Error(defaultErrorMessage || err)
}

function getAll() {
  return fetch('/api/adjusters', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve adjusters list.', err))
}

function get(id) {
  return fetch(`/api/adjusters/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve adjuster.', err))
}

function create(model) {
  return fetch('/api/adjusters', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(model),
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to create adjuster.', err))
}

function update(model) {
  return fetch('/api/adjusters', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(model),
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to update adjuster.', err))
}

import React from 'react'

const transportationTypes = ['Wheelchair Accessible/Mobility Vehicle', 'Taxi', 'Bus', 'Third-Party Vendor', 'Other']
transportationTypes.sort()

const TransportationSelector = ({ value, onChange, id, ...rest }) => {
  return (
    <select id={id} value={value} onChange={onChange} className="form-control" {...rest}>
      <option value=""></option>
      {transportationTypes.map((type, i) => (
        <option key={i} value={type}>
          {type}
        </option>
      ))}
    </select>
  )
}

export default TransportationSelector

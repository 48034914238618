import React, { useEffect, useState } from 'react'

import setFocusById from '../../../services/setFocusById'
import { SUGGESTED_FIELD_LENGTH } from '../../../utils/Constants'
import validation from '../../../validation'
import AutoCompleteComponent, { getAddressDetails, getStreetAddress } from '../../autocomplete/AutoComplete'
import AddButton from '../../controls/AddButton'
import RemoveButton from '../../controls/RemoveButton'
import StateSelector from '../../StateSelector'

export default {
  name: 'Pharmacies',
  group: 'Providers',
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    pharmacies: validation.arrayOf(
      validation.object({
        name: validation.name('Pharmacy name').required(),
        address: validation.address('Pharmacy address'),
        phone: validation.phone('Pharmacy phone'),
        fax: validation.phone('Pharmacy fax'),
        email: validation.email('Pharmacy email'),
      }),
    ),
  })
}

function Component({ getValue, addItemToArray, ...rest }) {
  const pharmacies = getValue('pharmacies', [])

  return (
    <div>
      <h4 className="pb-3">Pharmacies</h4>
      <div>
        {pharmacies.map((pharmacy, index) => {
          const namePrefix = `pharmacies[${index}]`
          return (
            <PharmaciesSection
              {...rest}
              id={`pharmacies-container-${index}`}
              index={index}
              namePrefix={namePrefix}
              key={`pharmacies-container-${index}`}
              className={`${index !== 0 ? 'mt-6' : 'mt-1'}`}
            />
          )
        })}
        <AddButton
          id="pharmacies.addButton"
          onClick={() => {
            setFocusById(`pharmacies[${pharmacies.length}].name`)
            addItemToArray('pharmacies', {})
          }}
        >
          Add Pharmacy
        </AddButton>
      </div>
    </div>
  )
}

function PharmaciesSection({
  id,
  className,
  index,
  namePrefix,
  createInputProps,
  ErrorMessage,
  removeItemFromArray,
  setModelValues,
  ...rest
}) {
  const [address, setAddress] = useState(null)

  useEffect(() => {
    const streetAddress = getStreetAddress(address)
    if (!streetAddress) {
      return
    }

    setModelValues([
      [`${namePrefix}.address.line1`, streetAddress],
      [`${namePrefix}.address.city`, getAddressDetails(address, 'locality')],
      [`${namePrefix}.address.zip`, getAddressDetails(address, 'postal_code')],
      [`${namePrefix}.address.state`, getAddressDetails(address, 'administrative_area_level_1')],
    ])
  }, [address, namePrefix, setModelValues])
  return (
    <div id={id} className={className}>
      <div className="form-row">
        <div className="col-md-8">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.name`}>
              Pharmacy Name
            </label>
            <input
              type="text"
              {...createInputProps(`${namePrefix}.name`)}
              className="form-control"
              maxLength={SUGGESTED_FIELD_LENGTH}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={`${namePrefix}.name`} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-8">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.line1`}>
              Address
            </label>
            <AutoCompleteComponent address={setAddress} />
            <input
              type="text"
              {...createInputProps(`${namePrefix}.address.line1`)}
              className="form-control"
              maxLength={validation.maxLengths.address.line1}
            />
            <ErrorMessage name={`${namePrefix}.address.line1`} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.city`}>
              City
            </label>
            <input
              type="text"
              {...createInputProps(`${namePrefix}.address.city`)}
              className="form-control"
              maxLength={validation.maxLengths.address.city}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.state`}>
              State
            </label>
            <React.Fragment>
              <StateSelector {...createInputProps(`${namePrefix}.address.state`)} />
            </React.Fragment>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.zip`}>
              Zip Code
            </label>
            <input
              type="text"
              {...createInputProps(`${namePrefix}.address.zip`)}
              className="form-control"
              maxLength={validation.maxLengths.address.zip}
            />
          </div>
        </div>
      </div>
      <div>
        <ErrorMessage name={`${namePrefix}.address.city`} />
        <ErrorMessage name={`${namePrefix}.address.state`} />
        <ErrorMessage name={`${namePrefix}.address.zip`} />
      </div>
      <div className="form-row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.phone`}>
              Phone
            </label>
            <input
              type="tel"
              {...createInputProps(`${namePrefix}.phone`)}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name={`${namePrefix}.phone`} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.fax`}>
              Fax
            </label>
            <input
              type="tel"
              {...createInputProps(`${namePrefix}.fax`)}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name={`${namePrefix}.fax`} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-8">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.email`}>
              Email
            </label>
            <input
              type="email"
              {...createInputProps(`${namePrefix}.email`)}
              className="form-control"
              placeholder="name@email.com"
              maxLength={validation.maxLengths.email}
            />
            <ErrorMessage name={`${namePrefix}.email`} />
          </div>
        </div>
      </div>
      <RemoveButton
        id={`${namePrefix}.removeButton`}
        className="float-right"
        onClick={() => removeItemFromArray('pharmacies', index)}
      >
        Remove
      </RemoveButton>
    </div>
  )
}

import React from 'react'

import { Field } from './FormikWrappers'

export default props => (
  <Field {...props}>
    {({ field, form }) => {
      const { name, value, ...rest } = field
      return (
        <>
          <label className="custom-switch ToggleField-component">
            <input
              type="checkbox"
              {...rest}
              className="custom-control-input"
              checked={value}
              id={props.id}
              onChange={e => {
                form.setFieldValue(name, e.target.checked)
              }}
            />
            <span className="custom-control-label d-inline-block" />
          </label>
        </>
      )
    }}
  </Field>
)

import React, { useEffect, useState } from 'react'

import validation from '../../../validation'
import AutoCompleteComponent, { getAddressDetails, getStreetAddress } from '../../autocomplete/AutoComplete'
import IntakeFormsvalidationSchemas from '../../IntakeForms/IntakeFormsvalidationSchemas'
import StateSelector from '../../StateSelector'

export default {
  name: 'Attorney',
  group: 'Client',
  sectionNumber: 2,
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return IntakeFormsvalidationSchemas.getForStep(1)
}

function Component({ caseModel, createInputProps, getValue, ErrorMessage }) {
  const [fields, setFields] = useState()
  const [address, setAddress] = useState(null)
  const [defendantAddress, setDefendantAddress] = useState(null)

  useEffect(() => {
    const streetAddress = getStreetAddress(address)
    if (!streetAddress) {
      return
    }

    setFields(current => {
      return {
        ...current,
        'plaintiff.address.line1': streetAddress,
        'plaintiff.address.city': getAddressDetails(address, 'locality'),
        'plaintiff.address.zip': getAddressDetails(address, 'postal_code'),
        'plaintiff.address.state': getAddressDetails(address, 'administrative_area_level_1'),
      }
    })
  }, [address])

  useEffect(() => {
    const defendantStreetAddress = getStreetAddress(defendantAddress)
    if (!defendantStreetAddress) {
      return
    }

    setFields(current => {
      return {
        ...current,
        'defendant.address.line1': defendantStreetAddress,
        'defendant.address.city': getAddressDetails(defendantAddress, 'locality'),
        'defendant.address.zip': getAddressDetails(defendantAddress, 'postal_code'),
        'defendant.address.state': getAddressDetails(defendantAddress, 'administrative_area_level_1'),
      }
    })
  }, [defendantAddress])
  return (
    <div>
      <h3 className="pb-3">Attorney Information</h3>
      <h5 className="form-heading mb-4">Plaintiff</h5>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-3" htmlFor="plaintiff.name">
              Company Name
            </label>
            <input
              type="text"
              {...createInputProps('plaintiff.name')}
              className="form-control"
              maxLength={validation.maxLengths.name}
            />
            <ErrorMessage name="plaintiff.name" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="plaintiff.address.line1">
              Address
            </label>
            <AutoCompleteComponent address={setAddress} />
            <input
              type="text"
              {...createInputProps('plaintiff.address.line1')}
              className="form-control"
              maxLength={validation.maxLengths.address.line1}
              value={fields?.['plaintiff.address.line1'] ?? createInputProps('plaintiff.address.line1').value}
            />
            <ErrorMessage name="plaintiff.address.line1" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="plaintiff.address.city">
              City
            </label>
            <input
              type="text"
              {...createInputProps('plaintiff.address.city')}
              className="form-control"
              maxLength={validation.maxLengths.address.city}
              value={fields?.['plaintiff.address.city'] ?? createInputProps('plaintiff.address.city').value}
            />
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="plaintiff.address.state">
              State
            </label>
            <React.Fragment>
              <StateSelector
                {...createInputProps('plaintiff.address.state')}
                value={fields?.['plaintiff.address.state'] ?? createInputProps('plaintiff.address.state').value}
              />
            </React.Fragment>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="plaintiff.address.zip">
              Zip Code
            </label>
            <input
              type="text"
              {...createInputProps('plaintiff.address.zip')}
              className="form-control"
              maxLength={validation.maxLengths.address.zip}
              value={fields?.['plaintiff.address.zip'] ?? createInputProps('plaintiff.address.zip').value}
            />
          </div>
        </div>
      </div>
      <div>
        <ErrorMessage name="plaintiff.address.city" />
        <ErrorMessage name="plaintiff.address.state" />
        <ErrorMessage name="plaintiff.address.zip" />
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="plaintiff.contact.phone">
              Phone
            </label>
            <input
              type="tel"
              {...createInputProps('plaintiff.contact.phone')}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name="plaintiff.contact.phone" />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="plaintiff.contact.fax">
              Fax
            </label>
            <input
              type="tel"
              {...createInputProps('plaintiff.contact.fax')}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name="plaintiff.contact.fax" />
          </div>
        </div>
      </div>
      <div>
        <label className="form-label mt-3" htmlFor="plaintiff.contact.firstName">
          Contact Name
        </label>
        <div className="form-row">
          <div className="col-md-3">
            <div className="form-group">
              <input
                type="text"
                {...createInputProps('plaintiff.contact.firstName')}
                className="form-control"
                maxLength={validation.maxLengths.name}
                placeholder="First Name"
              />
              <ErrorMessage name="plaintiff.contact.firstName" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input
                type="text"
                {...createInputProps('plaintiff.contact.lastName')}
                className="form-control"
                placeholder="Last Name"
                maxLength={validation.maxLengths.name}
              />
              <ErrorMessage name="plaintiff.contact.lastName" />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="plaintiff.contact.email">
              Email
            </label>
            <input
              type="email"
              {...createInputProps('plaintiff.contact.email')}
              className="form-control"
              placeholder="name@email.com"
              maxLength={validation.maxLengths.email}
            />
            <ErrorMessage name="plaintiff.contact.email" />
          </div>
        </div>
      </div>

      <h5 className="form-heading mt-4 mb-4">Defendant</h5>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-3" htmlFor="defendant.name">
              Company Name
            </label>
            <input
              type="text"
              {...createInputProps('defendant.name')}
              className="form-control"
              maxLength={validation.maxLengths.name}
            />
            <ErrorMessage name="defendant.name" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.address.line1">
              Address
            </label>
            <AutoCompleteComponent address={setDefendantAddress} />
            <input
              type="text"
              {...createInputProps('defendant.address.line1')}
              className="form-control"
              maxLength={validation.maxLengths.address.line1}
              value={fields?.['defendant.address.line1'] ?? createInputProps('defendant.address.line1').value}
            />
            <ErrorMessage name="defendant.address.line1" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.address.city">
              City
            </label>
            <input
              type="text"
              {...createInputProps('defendant.address.city')}
              className="form-control"
              maxLength={validation.maxLengths.address.city}
              value={fields?.['defendant.address.city'] ?? createInputProps('defendant.address.city').value}
            />
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.address.state">
              State
            </label>
            <React.Fragment>
              <StateSelector
                {...createInputProps('defendant.address.state')}
                value={fields?.['defendant.address.state'] ?? createInputProps('defendant.address.state').value}
              />
            </React.Fragment>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.address.zip">
              Zip Code
            </label>
            <input
              type="text"
              {...createInputProps('defendant.address.zip')}
              className="form-control"
              maxLength={validation.maxLengths.address.zip}
              value={fields?.['defendant.address.zip'] ?? createInputProps('defendant.address.zip').value}
            />
          </div>
        </div>
      </div>
      <div>
        <ErrorMessage name="defendant.address.city" />
        <ErrorMessage name="defendant.address.state" />
        <ErrorMessage name="defendant.address.zip" />
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.contact.phone">
              Phone
            </label>
            <input
              type="tel"
              {...createInputProps('defendant.contact.phone')}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name="defendant.contact.phone" />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.contact.fax">
              Fax
            </label>
            <input
              type="tel"
              {...createInputProps('defendant.contact.fax')}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name="defendant.contact.fax" />
          </div>
        </div>
      </div>
      <div>
        <label className="form-label mt-3" htmlFor="defendant.contact.firstName">
          Contact Name
        </label>
        <div className="form-row">
          <div className="col-md-3">
            <div className="form-group">
              <input
                type="text"
                {...createInputProps('defendant.contact.firstName')}
                className="form-control"
                maxLength={validation.maxLengths.name}
                placeholder="First Name"
              />
              <ErrorMessage name="defendant.contact.firstName" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input
                type="text"
                {...createInputProps('defendant.contact.lastName')}
                className="form-control"
                placeholder="Last Name"
                maxLength={validation.maxLengths.name}
              />
              <ErrorMessage name="defendant.contact.lastName" />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.contact.email">
              Email
            </label>
            <input
              type="email"
              {...createInputProps('defendant.contact.email')}
              className="form-control"
              placeholder="name@email.com"
              maxLength={validation.maxLengths.email}
            />
            <ErrorMessage name="defendant.contact.email" />
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'

import { orderBy } from 'lodash'
import moment from 'moment'

import AdjustersService from '../../../services/AdjustersService'
import format from '../../../services/format'
import ReferralSourcesService from '../../../services/ReferralSourcesService'
import UserService from '../../../services/UserService'
import CaseSummary from '../../CaseSummary'
import Table from '../../DNPTable'
import { DownloadButton } from '../../DownloadButton'
import FAIcon from '../../FAIcon'

import Sections from './CaseReportSections'

import './ReportPageTemplate.scss'

export default ({
  caseNumber,
  isInProgress,
  user,
  actionsComponent,
  settings,
  caseReportPromise,
  filesPromise,
  printableReportPath,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [caseReportModel, setCaseReportModel] = useState()
  const [caseManager, setCaseManager] = useState()
  const [files, setFiles] = useState()
  const [referralSource, setReferralSource] = useState()
  const [adjuster, setAdjuster] = useState()

  useEffect(() => {
    const caseManagerPromise = caseReportPromise.then(report => UserService.get(report.case.caseManager))
    const referralSourcePromise = caseReportPromise.then(caseReportModel => {
      if (!caseReportModel.case.referral?.sourceId) return null

      return ReferralSourcesService.get(caseReportModel.case.referral.sourceId)
    })
    const adjusterPromise = caseReportPromise.then(caseReportModel => {
      if (!caseReportModel.case.referral?.adjusterId) return null

      return AdjustersService.get(caseReportModel.case.referral.adjusterId)
    })

    Promise.all([caseReportPromise, caseManagerPromise, filesPromise, referralSourcePromise, adjusterPromise]).then(
      results => {
        setCaseReportModel(results[0])
        setCaseManager(results[1])
        setFiles(results[2])
        setReferralSource(results[3])
        setAdjuster(results[4])

        setIsLoading(false)
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseReportPromise])

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-2">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  let activityExpensesExportReportFilterUrlPart = `filterOnReportNumber=${caseReportModel.reportNumber}`
  if (isInProgress) {
    activityExpensesExportReportFilterUrlPart = 'filterOnInProgressReport=true'
  }

  const activities = [
    ...caseReportModel.appointments.map(x => x.activities ?? []).reduce((a, b) => a.concat(b), []),
    ...caseReportModel.tasks.map(x => x.activities ?? []).reduce((a, b) => a.concat(b), []),
  ]
  activities.sort((a, b) => (b.date > a.date ? 1 : -1))

  const expenses = [
    ...caseReportModel.appointments.map(x => x.expenses ?? []).reduce((a, b) => a.concat(b), []),
    ...caseReportModel.tasks.map(x => x.expenses ?? []).reduce((a, b) => a.concat(b), []),
  ]
  expenses.sort((a, b) => (a.createdUtc > b.createdUtc ? 1 : -1))

  let statusTextClass = ''
  if (caseReportModel.approvedOnUtc) {
    statusTextClass = 'text-success'
  } else if (caseReportModel.rejectedOnUtc) {
    statusTextClass = 'text-danger'
  }

  return (
    <div className="container ReportsPage-component pt-4 px-4">
      <Helmet
        style={[
          {
            cssText: `
          @media print {
            #report-case-details-section, #report-upcoming-appointments-section {
              page-break-after: always;
            }
            .details-section-subsection-card {
              border: none;
              border-radius: 0;
            }
            .card-body {
              border: 1px solid #dfdfdf;
            }
            .card-header {
              border: 1px solid #dfdfdf;
            }
            .details-section-subsection-card,
            .form-group,
            .single-line-list,
            .report-list-item {
              page-break-inside: avoid;
            }
            .bg-light {
              background-color : #fff !important;
            }
            .tab-bar-content-container,
            .nav-container {
              padding-top: 0 !important;
            }
            .navbar,
            .tab-bar-container,
            #reports-list-back-button,
            #submit-for-review-btn,
            #case-report-reject-btn,
            #case-report-approve-btn {
              display: none;
            }

        `,
          },
        ]}
      />
      <div id="reports-list-back-button" className="mb-2">
        <Link to={`/cases/${caseNumber}/reports`}>
          <FAIcon name="chevron-left" /> All Case Reports
        </Link>
      </div>
      <div className="row">
        <div className="col-md-7">
          <div className="h4 d-inline-block" style={{ paddingTop: '0.25rem' }}>
            Progress Report {caseReportModel.reportNumber}
          </div>
          <div className="btn-group" role="group">
            <Link className="btn btn-sm btn-link align-top" to={printableReportPath} target="_blank">
              <FAIcon name="print" /> Printable Report
            </Link>
            <DownloadButton
              className={'btn btn-sm btn-link align-top'}
              defaultExtension="xlsx"
              url={`/api/productivity-report/download?filterCaseNumber=${caseNumber}&${activityExpensesExportReportFilterUrlPart}`}
            >
              <FAIcon name="download" /> Export
            </DownloadButton>
          </div>
        </div>
        <div className="col-md-5 text-right mb-2">
          {actionsComponent && actionsComponent({ reportModel: caseReportModel })}
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-md-8 overview-info-container">
            <div className="form-row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label text-uppercase" htmlFor="status">
                    Status
                  </label>
                  <h6 id="status" className={`${statusTextClass} text-default-print`}>
                    {caseReportModel.status}
                  </h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label text-uppercase" htmlFor="status">
                    Activity Dates
                  </label>
                  <h6 id="status">
                    {moment(caseReportModel.fromDateTimeUtc).local().format('MM/DD/YYYY')} -{' '}
                    {moment(caseReportModel.toDateTimeUtc).local().format('MM/DD/YYYY')}
                  </h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label text-uppercase" htmlFor="status">
                    Case Manager
                  </label>
                  <h6 id="status">
                    {caseManager.firstName} {caseManager.lastName}
                  </h6>
                </div>
              </div>
            </div>
            {caseReportModel.rejectedReason && (
              <div className="mb-4">
                <div className="form-group">
                  <label className="form-label text-uppercase">
                    Reason for {settings.caseStatuses.rejected.continuousVerb.toLowerCase()}
                  </label>
                  <h6 id="rejectReasonDisplay" className="text-danger text-default-print">
                    {caseReportModel.rejectedReason}
                  </h6>
                </div>
              </div>
            )}
          </div>

          <div className="col-md-4">
            <CaseSummary
              caseSummaryModel={caseReportModel}
              reportHoursTarget={settings.reportHoursTarget}
              reportDaysTarget={settings.reportDaysTarget}
            />
          </div>
        </div>

        <div id="report-case-details-section" className="report-section">
          <h5 className="report-section-header">Details</h5>
          <div className="details-container">
            {Object.keys(Sections).map(key => (
              <div key={key} className="details-section-group-container">
                <label className="text-uppercase details-section-group-header" htmlFor="dummy">
                  {key}
                </label>
                <Accordion>
                  {Sections[key].map((section, i) => {
                    const sectionBodyId = `card-body-${key.replace(' ', '_')}-${i}`

                    return (
                      <Card key={i} className="details-section-subsection-card">
                        <Card.Header
                          className="bg-white collapsed"
                          data-toggle="collapse"
                          data-target={`#${sectionBodyId}`}
                          aria-expanded="false"
                          aria-controls={`#${sectionBodyId}`}
                          role="button"
                        >
                          <div className="d-flex">
                            <div className="flex-grow-1">{section.title}</div>
                            <div className="text-right text-muted">
                              <FAIcon name="plus" className="collapsed-icon" />
                              <FAIcon name="minus" className="opened-icon" />
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body className="collapse" id={sectionBodyId}>
                          <div className="details-section-container">
                            <section.component
                              caseNumber={caseNumber}
                              caseReportModel={caseReportModel}
                              caseFiles={files}
                              adjuster={adjuster}
                              referralSource={referralSource}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    )
                  })}
                </Accordion>
              </div>
            ))}
          </div>
        </div>

        <div id="report-upcoming-appointments-section" className="report-section">
          <h5 className="report-section-header">Upcoming Appointments</h5>
          <Table
            items={orderBy(caseReportModel.upcomingAppointments, x => x.startUtc)}
            noItemsText="No Upcoming Appointments"
            columns={[
              { title: 'Date', getText: item => format.date(item.startUtc) },
              { title: 'Title', getText: item => item.title },
              {
                title: null,
                getText: item => (
                  <div className="text-right">
                    <Link to={`/cases/${caseNumber}/appointments/${item.appointmentNumber}`}>View</Link>
                  </div>
                ),
              },
            ]}
          />
        </div>

        <div className="report-section">
          <h5 className="report-section-header">Time</h5>
          <Table
            items={activities}
            noItemsText="No Time Entries"
            columns={[
              { title: 'Date', getText: item => format.date(item.date) },
              { title: 'Type', getText: item => item.type },
              { title: 'Billable Time', getText: item => `${item.time} Hours` },
              { title: 'Description', getText: item => item.description },
            ]}
          />
        </div>

        <div className="report-section">
          <h5 className="report-section-header">Expenses</h5>
          <Table
            items={expenses}
            noItemsText="No Expenses"
            columns={[
              { title: 'Date', getText: item => format.date(item.date) },
              { title: 'Type', getText: item => item.type },
              { title: 'Amount', getText: item => format.money(item.amount) },
              { title: 'Distance', getText: item => item.distance && `${item.distance} Miles` },
              { title: 'Description', getText: item => item.description },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

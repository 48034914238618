import ApiClient from './ApiClient'

/**
 * @typedef { import('axios').AxiosRequestConfig } AxiosRequestConfig
 */

/**
 * Sends a request using Axios.
 * @template TResult
 * @param {AxiosRequestConfig} options - The request options.
 * @returns {Promise<TResult>} The response data.
 */
export default async function sendRequest(options) {
  const apiClient = new ApiClient()
  const { data } = await apiClient.instance.request(options)
  return data
}

import React from 'react'

import logger from '../../../services/logger'
import setFocusById from '../../../services/setFocusById'
import validation from '../../../validation'
import AddButton from '../../controls/AddButton'
import DatePicker from '../../controls/DatePicker'
import FilePicker from '../../controls/FilePicker'
import RemoveButton from '../../controls/RemoveButton'
import FAIcon from '../../FAIcon'

export default {
  name: 'Diagnoses',
  group: 'Case Details',
  validationSchema: getValidationSchema(),
  Component,
}

// shamelessly using function hoisting for code clarity

function getValidationSchema() {
  return validation.object({
    diagnosis: validation.arrayOf(
      validation.object({
        name: validation.name('Diagnosis').required(),
        physician: validation.shortText('Physician'),
        date: validation.date(),
      }),
    ),
  })
}

function Component({
  addItemToArray,
  getStateByName,
  setStateByName,
  removeItemFromArray,
  createInputProps,
  getValue,
  ErrorMessage,
  caseFiles,
  caseNumber,
  handleNewCaseFileAttached,
}) {
  const categories = {
    primary: 'Primary',
    secondary: 'Secondary',
    unrelated: 'Unrelated',
  }
  const diagnosis = {
    all: getValue('diagnosis', []).map((x, index) => ({
      index,
      category: x.category,
      diagnosis: x,
    })),
  }
  diagnosis.primary = diagnosis.all.filter(x => x.category === categories.primary)
  diagnosis.secondary = diagnosis.all.filter(x => x.category === categories.secondary)
  diagnosis.unrelated = diagnosis.all.filter(x => x.category === categories.unrelated)

  const groupProps = {
    createInputProps,
    ErrorMessage,
    addItemToArray,
    removeItemFromArray,
    getStateByName,
    setStateByName,
    caseFiles,
    caseNumber,
    handleNewCaseFileAttached,
    getValue,
  }

  return (
    <div className="Diagnosis-component">
      <h4 className="pb-3">Diagnoses</h4>
      <h5>Related to Injury</h5>
      <DiagnosisGroup
        name="Primary Diagnoses"
        diagnosis={diagnosis.primary}
        category={categories.primary}
        {...groupProps}
      />

      <DiagnosisGroup
        name="Secondary Diagnoses"
        diagnosis={diagnosis.secondary}
        category={categories.secondary}
        {...groupProps}
      />

      <h5 className="mt-5">Unrelated to Injury</h5>
      <DiagnosisGroup
        name="Unrelated Diagnoses"
        diagnosis={diagnosis.unrelated}
        category={categories.unrelated}
        {...groupProps}
      />
    </div>
  )
}

function DiagnosisGroup({ name, diagnosis, category, removeItemFromArray, addItemToArray, ...rest }) {
  return (
    <>
      <div>
        {diagnosis.map(x => {
          const namePrefix = `diagnosis[${x.index}]`
          return (
            <React.Fragment key={namePrefix}>
              {diagnosis.length > 0 ? <div className="mt-4"></div> : null}
              <Diagnosis
                nameName={name}
                namePrefix={namePrefix}
                handleRemove={() => removeItemFromArray('diagnosis', x.index)}
                addItemToArray={addItemToArray}
                removeItemFromArray={removeItemFromArray}
                {...rest}
              />
              <hr />
            </React.Fragment>
          )
        })}
        <AddButton
          onClick={() => {
            const index = addItemToArray('diagnosis', { category })
            setFocusById(`diagnosis[${index}].name`)
          }}
        >
          Add {name}
        </AddButton>
      </div>
    </>
  )
}

function Diagnosis({
  nameName,
  namePrefix,
  handleRemove,
  createInputProps,
  ErrorMessage,
  getStateByName,
  getValue,
  handleNewCaseFileAttached,
  caseFiles,
  setStateByName,
  addItemToArray,
  caseNumber,
  removeItemFromArray,
}) {
  const filesArrayName = `${namePrefix}.files`
  const files = getValue(filesArrayName, [])

  const fileAttachErrorStateName = `${filesArrayName} Attach Error`
  const setFileAttachError = error => setStateByName(fileAttachErrorStateName, error)
  const fileAttachError = getStateByName(fileAttachErrorStateName)

  const onFileAttach = file => {
    setFileAttachError(null)
    handleNewCaseFileAttached(file)
    addItemToArray(filesArrayName, file.id)
  }

  return (
    <div className="clearfix">
      <div className="form-row">
        <div className="form-group col-md-5">
          <label className="form-label" htmlFor={`${namePrefix}.name`}>
            {nameName}
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.name}
            {...createInputProps(`${namePrefix}.name`)}
          />
          <ErrorMessage name={`${namePrefix}.name`} className="d-md-none" />
        </div>
        <div className="form-group col-md-4">
          <label className="form-label" htmlFor={`${namePrefix}.physician`}>
            Physician
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.shortText}
            {...createInputProps(`${namePrefix}.physician`)}
          />
          <ErrorMessage name={`${namePrefix}.physician`} className="d-md-none" />
        </div>
        <div className="form-group col-md-3">
          <label className="form-label" htmlFor={`${namePrefix}.date`}>
            Date
          </label>
          <div className="d-flex">
            <DatePicker {...createInputProps(`${namePrefix}.date`)} />
          </div>
        </div>
        <ErrorMessage name={`${namePrefix}.name`} className="hide-sm-down" />
        <ErrorMessage name={`${namePrefix}.physician`} className="hide-sm-down" />
        <div></div>
      </div>
      <div className="form-row mb-4">
        <div className="form-group col-4 col-sm-4 col-md-6 col-lg-3">
          <label className="form-label" htmlFor={`${namePrefix}.files`}>
            Records
          </label>
          {files.map((fileId, index) => {
            if (caseFiles) {
              const file = caseFiles.find(x => x.id === fileId)
              if (file) {
                return (
                  <div key={index} className="d-flex">
                    <div className="flex-grow-1 text-truncate">
                      {file.hasBeenSaved ? (
                        <a
                          href={`/api/cases/${caseNumber}/files/${fileId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-link"
                        >
                          {file.name}{' '}
                        </a>
                      ) : (
                        <span className="btn btn-link text-primary text-decoration-none">{file.name}</span>
                      )}
                    </div>
                    <button
                      type="button"
                      id={`removeCaseFile${fileId}Button`}
                      className="btn btn-link"
                      onClick={() => removeItemFromArray(filesArrayName, index)}
                    >
                      <FAIcon name="minus-circle" />
                    </button>
                  </div>
                )
              } else {
                return null
              }
            } else {
              return null
            }
          })}
          {getValue('injury.recordFiles', []).length < 10 && ( // max of 10 files
            <React.Fragment>
              <div style={{ maxWidth: '15rem' }}>
                <FilePicker
                  id="diagnosisFilePicker"
                  onAttach={onFileAttach}
                  onError={err => {
                    logger.error(err)
                    setFileAttachError(err)
                  }}
                />
              </div>
              {fileAttachError && <div className="text-danger">{fileAttachError}</div>}
            </React.Fragment>
          )}
        </div>
        <div className="mt-4 col-8 col-sm-8 col-md-6 col-lg-9">
          <RemoveButton id="removeDiagnosisButton" className="float-right mt-2" onClick={() => handleRemove()}>
            Remove
          </RemoveButton>
        </div>
      </div>
    </div>
  )
}

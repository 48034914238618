import React from 'react'

const CaseSummary = ({ caseSummaryModel, reportHoursTarget, reportDaysTarget }) => {
  const dateFrom = new Date(caseSummaryModel.fromDateTimeUtc)
  const dateTo = new Date(caseSummaryModel.toDateTimeUtc)

  const oneDay = 1000 * 60 * 60 * 24
  const days = (dateTo - dateFrom) / oneDay

  return (
    <React.Fragment>
      <ProgressBar
        text="Billable Time"
        units="hrs"
        value={caseSummaryModel.totalBillableTime}
        max={reportHoursTarget}
        precision={1}
      />
      <ProgressBar text="Days" units="days" value={days} max={reportDaysTarget} precision={0} />
    </React.Fragment>
  )
}

function ProgressBar({ text, units, value, max, precision }) {
  const valuePercent = value < max ? value / max : 1

  const progressColor = (() => {
    if (valuePercent >= 1) return 'danger'
    if (valuePercent >= 0.8) return 'warning'

    return 'primary'
  })()

  let valueString = value ? value.toFixed(precision) : ''
  if (value === parseInt(valueString)) {
    valueString = value.toFixed(0)
  }
  if (valueString === '-0') {
    valueString = '0'
  }

  return (
    <div className="pb-2">
      <div className="row">
        <div className="col text-nowrap">{text}</div>
        <div className="col text-nowrap text-truncate text-right">
          {valueString} {units} of {max} {units}
        </div>
      </div>
      <div className="progress">
        <div
          className={`progress-bar bg-${progressColor}`}
          role="progressbar"
          style={{ width: `${valuePercent * 100}%` }}
          aria-valuenow={valuePercent}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  )
}

export default CaseSummary

import React from 'react'

import EmptyText from './EmptyText'

export default ({ address, hideIfEmpty }) => {
  const populated = (() => {
    if (!address) return false
    if (!address.line1 && !address.city && !address.state && !address.zip) return false

    return true
  })()

  if (hideIfEmpty && !populated) return <EmptyText />

  return (
    <>
      <div className="form-text">
        {!populated ? (
          <EmptyText />
        ) : (
          <>
            <div>{address.line1}</div>
            <div>
              {address.city}, {address.state} {address.zip}
            </div>
          </>
        )}
      </div>
    </>
  )
}

import React from 'react'

import validation from '../../../validation'

export default {
  name: 'Review of Systems',
  group: 'Case Details',
  sectionNumber: 3,
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    general: validation.note('General').nullable(),
    eyes: validation.note('Eyes').nullable(),
    skin: validation.note('Skin').nullable(),
    nose: validation.note('Nose').nullable(),
    head: validation.note('Head').nullable(),
    ears: validation.note('Ears').nullable(),
    throat: validation.note('Throat').nullable(),
  })
}

function Component({ caseModel, createInputProps, getValue, setModelValue, ErrorMessage }) {
  const generalPlaceholderText = 'Weight loss, Fatigue, Fever or chills, Weakness'
  const eyesPlaceholderText = 'Vision problems, eye irritation, eye pain'
  const skinPlaceholderText = 'Rashes, itching, hair/nails changes, lumps, dryness, color changes'
  const nosePlaceholderText = 'Nasal stuffiness, nosebleeds'
  const headPlaceholderText = 'Headache, head injury, ect'
  const earsPlaceholderText = 'Decreased hearing, ringing, earache, drainage'
  const throatPlaceholderText =
    'Teeth, bleeding, thrush, dentures, non-healing, sores, gums, sore tongue, sore throat, dry mouth, or hoarseness'
  const rows = 6

  return (
    <div>
      <h4 className="pb-3">Review of Systems</h4>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="systems.general">
              General
            </label>
            <textarea
              {...createInputProps('systems.general')}
              className="form-control"
              placeholder={generalPlaceholderText}
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'systems.general'} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="systems.eyes">
              Eyes
            </label>
            <textarea
              {...createInputProps('systems.eyes')}
              className="form-control"
              placeholder={eyesPlaceholderText}
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'systems.eyes'} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="systems.skin">
              Skin
            </label>
            <textarea
              {...createInputProps('systems.skin')}
              className="form-control"
              placeholder={skinPlaceholderText}
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'systems.skin'} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="systems.nose">
              Nose
            </label>
            <textarea
              {...createInputProps('systems.nose')}
              className="form-control"
              placeholder={nosePlaceholderText}
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'systems.nose'} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="systems.head">
              Head
            </label>
            <textarea
              {...createInputProps('systems.head')}
              className="form-control"
              placeholder={headPlaceholderText}
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'systems.head'} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="systems.ears">
              Ears
            </label>
            <textarea
              {...createInputProps('systems.ears')}
              className="form-control"
              placeholder={earsPlaceholderText}
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'systems.ears'} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="systems.throat">
              Throat
            </label>
            <textarea
              {...createInputProps('systems.throat')}
              className="form-control"
              placeholder={throatPlaceholderText}
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'systems.throat'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import axios from 'axios'

/**
 * Get the error message from an error object.
 * @param {unknown} error - The error object.
 * @returns {string|undefined} - The error message or undefined if the error is a cancellation.
 */
export default function getErrorMessage(error) {
  if (typeof error === 'string') {
    return error
  }

  if (axios.isCancel(error)) {
    return undefined
  }

  if (axios.isAxiosError(error)) {
    if (error.response) {
      return error.response.data.message
    }
    return error.message
  }

  if (error instanceof Error) {
    return error.message
  }

  if (error && typeof error === 'object') {
    if ('message' in error) {
      return error.message
    }
  }

  return 'There was a problem'
}

import React, { useState } from 'react'

import { wrapFetch } from '../../services/FetchHelper'
import logger from '../../services/logger'
import { getToken } from '../../utils/getToken'

const maxLength = 100 * 1000000 // 100mb
const accepts = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.jpg',
  '.jpeg',
  '.jpe',
  '.bmp',
  '.gif',
  '.png',
  '.tiff',
  '.tif',
]

const FilePicker = ({ value, onAttach, onError, ...props }) => {
  const [fieldId, setFieldId] = useState(props.id)
  const [statusText, setStatusText] = useState()

  if (fieldId === undefined) {
    setFieldId(Math.random().toString(36).substring(7))
  }

  const handleFileChange = e => {
    const file = e.target.files[0]

    if (!file) {
      setStatusText(null)
      return
    }

    if (file.size > maxLength) {
      setStatusText(`${file.name} failed to upload.`)
      onError(`${file.name} is larger than the 100mb maximum size`)
      return
    }

    const fileExt = file.name.substring(file.name.lastIndexOf('.')).toLowerCase()
    if (!accepts.some(x => x === fileExt)) {
      setStatusText(`${file.name} failed to upload.`)
      onError(`${file.name} is not one of the supported file types: ${accepts.join(', ')}`)
      return
    }

    const formData = new FormData()
    formData.set('file', file)

    setStatusText(`${file.name} is uploading...`)

    wrapFetch(
      fetch('/api/files', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }),
    )
      .onSuccess(json => {
        setStatusText(null)
        onAttach(json)
      })
      .onBadRequest(err => {
        setStatusText(`${file.name} failed to upload`)
        onError(err)
      })
      .onStatus(413, () => {
        setStatusText(`${file.name} is too large`)
        onError(`${file.name} is too large`)
      })
      .catch(err => {
        setStatusText(`${file.name} failed to upload`)
        onError('Unexpected error while uploading file')
        logger.error(err)
      })
  }

  return (
    <div className="input-group custom-file">
      <input
        type="file"
        className="form-control custom-file-input form-control-file"
        id={fieldId}
        // eslint-disable-next-line react/no-unknown-property
        accepts={accepts.join(',')}
        onChange={e => handleFileChange(e)}
        aria-describedby={`${fieldId}-paperclip-icon`}
        {...props}
      />
      <label htmlFor={fieldId} className="custom-file-label">
        {getText(value, statusText)}
        <span id={`${fieldId}-paperclip-icon`} className="fas fa-paperclip input-group-append"></span>
      </label>
    </div>
  )
}

function getText(value, statusText) {
  if (statusText) return statusText
  if (!value) return 'Attach a file'

  return value.name
}

export default FilePicker

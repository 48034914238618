import React, { useState } from 'react'

import logger from '../../../services/logger'
import setFocusById from '../../../services/setFocusById'
import { SUGGESTED_FIELD_LENGTH } from '../../../utils/Constants'
import FilePicker from '../../controls/FilePicker'
import FAIcon from '../../FAIcon'
import IntakeFormsvalidationSchemas from '../../IntakeForms/IntakeFormsvalidationSchemas'

export default {
  name: 'Injury',
  group: 'Case Details',
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return IntakeFormsvalidationSchemas.getForStep(2)
}

function Component({
  caseModel,
  caseFiles,
  handleNewCaseFileAttached,
  createInputProps,
  getValue,
  addItemToArray,
  removeItemFromArray,
  ErrorMessage,
}) {
  const [fileAttachError, setFileAttachError] = useState()
  const caseId = caseModel.caseNumber

  const onFileAttach = file => {
    setFileAttachError(null)
    handleNewCaseFileAttached(file)
    addItemToArray('injury.recordFiles', file.id)
  }

  const descriptions = getValue('injury.descriptions', [])
  const handleAddDescription = () => {
    const nextIndex = descriptions.length
    setFocusById(`injury.descriptions[${nextIndex}].description`)
    addItemToArray('injury.descriptions', { description: '', provider: '' })
  }

  return (
    <div>
      <h4 className="pb-3">Injury</h4>
      <div>
        {descriptions.map((injury, index) => (
          <div id={`injury-container-${index}`} key={`injury-container-${index}`} className="mt-1">
            {index === 0 && (
              <div className="form-row">
                <label className="form-label mt-3 col-md-4" htmlFor="injuryDescription">
                  Description of Injury
                </label>
                <label className="form-label mt-3 col-md-4" htmlFor="descriptionProvidedBy">
                  Provided By
                </label>
              </div>
            )}
            <div className="form-row">
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    {...createInputProps(`injury.descriptions[${index}].description`)}
                    className="form-control"
                    maxLength={SUGGESTED_FIELD_LENGTH}
                  />
                  <div style={{ display: 'inline' }}>
                    <ErrorMessage name={`injury.descriptions[${index}].description`} />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <input
                    type="text"
                    {...createInputProps(`injury.descriptions[${index}].provider`)}
                    className="form-control"
                    maxLength={SUGGESTED_FIELD_LENGTH}
                  />
                  <div style={{ display: 'inline' }}>
                    <ErrorMessage name={`injury.descriptions[${index}].provider`} />
                  </div>
                </div>
              </div>
              <div className="col-md-.5">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-link"
                    id={`${index}RemoveBtn`}
                    onClick={() => removeItemFromArray('injury.descriptions', index)}
                  >
                    <FAIcon name="minus-circle" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="form-row col-md-.5">
          <div className="form-group">
            <button
              type="button"
              className="btn btn-link add-field-button"
              id="InjuryDescriptionAddBtn"
              onClick={handleAddDescription}
            >
              <FAIcon name="plus-circle" /> ADD INJURY DESCRIPTION
            </button>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="injury.notes">
              Injury Notes
            </label>
            <textarea {...createInputProps('injury.notes')} maxLength="2000" className="form-control" rows="6" />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'injury.notes'} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 px-0">
        <div className="form-group">
          <label className="form-label mt-2" htmlFor="injury.date">
            Date of Injury
          </label>
          <input
            type="text"
            {...createInputProps('injury.date')}
            className="form-control"
            placeholder="Date of Injury"
          />
          <ErrorMessage name="injury.date" />
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="injuryFiles">
              Records
            </label>
            <React.Fragment>
              {getValue('injury.recordFiles', []).map((fileId, index) => {
                if (caseFiles) {
                  const file = caseFiles.find(x => x.id === fileId)
                  if (file) {
                    return (
                      <div key={index} className="d-flex">
                        <div className="flex-grow-1 text-truncate">
                          {file.hasBeenSaved ? (
                            <a
                              href={`/api/cases/${caseId}/files/${fileId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-link"
                            >
                              {file.name}
                            </a>
                          ) : (
                            <span className="btn btn-link text-primary text-decoration-none">{file.name}</span>
                          )}
                        </div>
                        <button
                          type="button"
                          id={`removeRecord${fileId}Btn`}
                          className="btn btn-link"
                          onClick={() => removeItemFromArray('injury.recordFiles', index)}
                        >
                          <FAIcon name="minus-circle" />
                        </button>
                      </div>
                    )
                  } else {
                    return null
                  }
                } else {
                  return null
                }
              })}
              {getValue('injury.recordFiles', []).length < 10 && ( // max of 10 files
                <React.Fragment>
                  <FilePicker
                    onAttach={onFileAttach}
                    onError={err => {
                      logger.error(err)
                      setFileAttachError(err)
                    }}
                    id="injuryFilePicker"
                  />
                  {fileAttachError && <div className="text-danger">{fileAttachError}</div>}
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  )
}

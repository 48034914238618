import React from 'react'

import setFocusById from '../../../services/setFocusById'
import validation from '../../../validation'
import AddButton from '../../controls/AddButton'
import CheckBox from '../../controls/CheckBox'
import DatePicker from '../../controls/DatePicker'
import RemoveButton from '../../controls/RemoveButton'

export default {
  name: 'Surgeries/Hospitalizations',
  group: 'Case Details',
  validationSchema: getValidationSchema(),
  Component,
}

// shamelessly using function hoisting for code clarity

function getValidationSchema() {
  return validation.object({
    surgeriesAndHospitalizations: validation.arrayOf(
      validation.object({
        title: validation.name('Procedure/Surgery').required(),
        dateAdmitted: validation.date('Date Admitted'),
        dateOfSurgery: validation.date('Date of Surgery'),
        dateDischarged: validation.date('Date Discharged'),
        provider: validation.shortText('Physician/Specialist'),
        results: validation.shortText('Results'),
        // reason: validation.shortText('Reason')
      }),
    ),
  })
}

function Component({ addItemToArray, removeItemFromArray, createInputProps, getValue, ErrorMessage }) {
  const surgeriesAndHospitalizations = getValue('surgeriesAndHospitalizations', [])

  return (
    <div>
      <div className="SurgeriesAndHospitalizations-component">
        <h4 className="pb-3">Surgeries/Hospitalizations</h4>
        {surgeriesAndHospitalizations.map((x, index) => {
          const namePrefix = `surgeriesAndHospitalizations[${index}]`
          return (
            <React.Fragment key={namePrefix}>
              <Procedure
                namePrefix={namePrefix}
                createInputProps={createInputProps}
                getValue={getValue}
                ErrorMessage={ErrorMessage}
                handleRemove={() => removeItemFromArray('surgeriesAndHospitalizations', index)}
              />
              <hr />
            </React.Fragment>
          )
        })}
        <AddButton
          id="addProcedureButton"
          onClick={() => {
            setFocusById(`surgeriesAndHospitalizations[${surgeriesAndHospitalizations.length}].title`)
            addItemToArray('surgeriesAndHospitalizations', {})
          }}
        >
          Add Surgery/Hospitalization
        </AddButton>
      </div>
    </div>
  )
}

function Procedure({ namePrefix, createInputProps, ErrorMessage, handleRemove }) {
  return (
    <div className="clearfix">
      <div className="form-group">
        <label className="form-label" htmlFor={`${namePrefix}.title`}>
          Reason for Admission
        </label>
        <input
          type="text"
          className="form-control"
          maxLength={validation.maxLengths.name}
          {...createInputProps(`${namePrefix}.title`)}
        />
        <ErrorMessage name={`${namePrefix}.title`} />
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label className="form-label" htmlFor={`${namePrefix}.dateAdmitted`}>
            Date Admitted
          </label>
          <DatePicker {...createInputProps(`${namePrefix}.dateAdmitted`)} />
        </div>
        <div className="form-group col-md-4">
          <label className="form-label" htmlFor={`${namePrefix}.dateOfSurgery`}>
            Date of Surgery
          </label>
          <DatePicker {...createInputProps(`${namePrefix}.dateOfSurgery`)} />
        </div>
        <div className="form-group col-md-4">
          <label className="form-label" htmlFor={`${namePrefix}.dateDischarged`}>
            Date Discharged
          </label>
          <DatePicker {...createInputProps(`${namePrefix}.dateDischarged`)} />
        </div>
      </div>
      <div>
        <ErrorMessage name={`${namePrefix}.dateAdmitted`} />
        <ErrorMessage name={`${namePrefix}.dosage`} />
        <ErrorMessage name={`${namePrefix}.dateDischarged`} />
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor={`${namePrefix}.provider`}>
          Physician/Specialist
        </label>
        <input
          type="text"
          className="form-control"
          maxLength={validation.maxLengths.shortText}
          {...createInputProps(`${namePrefix}.provider`)}
        />
        <ErrorMessage name={`${namePrefix}.provider`} />
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor={`${namePrefix}.results`}>
          Results
        </label>
        <input
          type="text"
          className="form-control"
          maxLength={validation.maxLengths.shortText}
          {...createInputProps(`${namePrefix}.results`)}
        />
        <ErrorMessage name={`${namePrefix}.results`} />
      </div>
      {/* <div className="form-group">
      <label className="form-label" htmlFor={`${namePrefix}.reason`}>Reason</label>
      <input type="text" className="form-control" maxLength={validation.maxLengths.shortText} {...createInputProps(`${namePrefix}.reason`)} />
      <ErrorMessage name={`${namePrefix}.reason`} />
    </div> */}
      <div className="form-row">
        <div className="form-group mt-2 col-4 col-sm-6 col-md-3 col-lg-4">
          <CheckBox {...createInputProps(`${namePrefix}.relatedToInjury`)}>Related to Injury</CheckBox>
        </div>
        <div className="col-8 col-sm-6 col-md-9 col-lg-8">
          <RemoveButton className="float-right" onClick={() => handleRemove()}>
            Remove
          </RemoveButton>
        </div>
      </div>
    </div>
  )
}

import React from 'react'

import { Field } from './FormikWrappers'

export default props => (
  <Field {...props}>
    {({ field, form }) => {
      const { name, value } = field

      return (
        <React.Fragment>
          <div className="form-row">
            <div className="col-md-4 mt-3">
              <div className="form-group">
                <label className="form-label" htmlFor={props.name}>
                  {props.title}
                </label>
                <div className="container radio">
                  <input
                    className="mr-2"
                    type="radio"
                    id={props.id + '1'}
                    value={true}
                    checked={value === true}
                    onChange={e => {
                      form.setFieldValue(name, e.target.checked)
                    }}
                  />
                  <label className="mr-4 form-label radio-label" htmlFor={props.id + '1'}>
                    {props.label1}
                  </label>
                  <input
                    className="mr-2"
                    type="radio"
                    id={props.id + '2'}
                    value={false}
                    checked={value === false}
                    onChange={e => {
                      form.setFieldValue(name, !e.target.checked)
                    }}
                  />
                  <label className="mr-4 form-label radio-label" htmlFor={props.id + '2'}>
                    {' '}
                    {props.label2}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }}
  </Field>
)

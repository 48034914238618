import React from 'react'

export default ({ noItemsText, items, itemComponent, showDivider = true }) => {
  if (!noItemsText) throw new Error('noItemsText is required.')

  return (
    <>
      {items.map((item, i) => (
        <div className="report-list-item" key={i}>
          {itemComponent(item)}
          {i !== items.length - 1 && showDivider && <hr className="soft" />}
        </div>
      ))}
    </>
  )
}

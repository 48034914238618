import React from 'react'

export default ({ id, name, value, onChange, onBlur, allowEditing = true }) => {
  const handleOnChange = event => {
    const valueToUse = (() => {
      if (event.target.value === '') {
        return null
      } else {
        return parseFloat(event.target.value)
      }
    })()

    onChange({
      target: {
        type: event.target.type,
        name: event.target.name,
        value: valueToUse,
      },
    })
  }

  let valueAsNumber = ''

  switch (value) {
    case null:
    case undefined:
    case '':
      break

    default:
      valueAsNumber = parseFloat(value)
  }

  return (
    <div className="input-icon input-icon-right">
      <input
        id={id}
        name={name}
        readOnly={allowEditing ? null : true}
        value={valueAsNumber}
        className="form-control"
        type="number"
        step=".1"
        min="0"
        pattern="\\d*"
        onChange={handleOnChange}
        onBlur={onBlur}
        onKeyDown={e => (e.key === 'e' || e.key === '-') && e.preventDefault()}
        aria-describedby="hours"
      />
      <i>hrs</i>
    </div>
  )
}

import React from 'react'

import EmptyText from './EmptyText'
import ReportLabel from './ReportLabel'
import ReportText from './ReportText'

export default ({ items, columns }) => {
  const EmptyRow = () => (
    <div className="row report-list-item">
      {columns.map((column, i) => (
        <div key={i} className={`form-group col-md-${column.size}`}>
          <label className="show-sm-down">{column.title}</label>
          <div>
            <EmptyText />
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <div className="single-line-list">
      <div className="row single-line-list-header-row hide-sm-down">
        {columns.map((c, i) => (
          <div key={i} className={`col-md-${c.size}`}>
            <ReportLabel>{c.title}</ReportLabel>
          </div>
        ))}
      </div>
      {!items || items.length === 0 ? (
        <EmptyRow />
      ) : (
        items.map((item, i) => (
          <React.Fragment key={i}>
            <div className="row report-list-item">
              {columns.map((c, i) => (
                <div key={i} className={`form-group col-md-${c.size}`}>
                  <ReportLabel className="show-sm-down">{c.title}</ReportLabel>
                  {c.component ? <c.component item={item} /> : <ReportText>{c.getText(item)}</ReportText>}
                </div>
              ))}
            </div>
            {items.length - 1 !== i && <hr className="show-sm-down" />}
          </React.Fragment>
        ))
      )}
    </div>
  )
}

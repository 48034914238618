import React, { useEffect, useState } from 'react'

import { orderBy } from 'lodash'
import moment from 'moment'

import AdjustersService from '../../services/AdjustersService'
import CasesService from '../../services/CasesService'
import format from '../../services/format'
import ReferralSourcesService from '../../services/ReferralSourcesService'
import UserService from '../../services/UserService'
import FAIcon from '../FAIcon'

import Address from './CaseReport/components/Address'
import EmptyText from './CaseReport/components/EmptyText'
import ReportLabel from './CaseReport/components/ReportLabel'
import ReportMultiLineList from './CaseReport/components/ReportMultiLineList'
import ReportSingleLineList from './CaseReport/components/ReportSingleLineList'
import ReportText from './CaseReport/components/ReportText'

import './ReportPrintPage.scss'

function Page({ caseNumber, reportNumber, settings }) {
  const [isLoading, setIsLoading] = useState(true)
  const [caseReportModel, setCaseReportModel] = useState()
  const [caseManager, setCaseManager] = useState()
  const [referralSource, setReferralSource] = useState()
  const [adjuster, setAdjuster] = useState()

  useEffect(() => {
    const caseReportPromise = CasesService.getReport(caseNumber, reportNumber)
    const caseManagerPromise = caseReportPromise.then(report => UserService.get(report.case.caseManager))
    const referralSourcePromise = caseReportPromise.then(caseReportModel => {
      if (!caseReportModel.case.referral?.sourceId) return null

      return ReferralSourcesService.get(caseReportModel.case.referral.sourceId)
    })
    const adjusterPromise = caseReportPromise.then(caseReportModel => {
      if (!caseReportModel.case.referral?.adjusterId) return null

      return AdjustersService.get(caseReportModel.case.referral.adjusterId)
    })

    Promise.all([caseReportPromise, caseManagerPromise, referralSourcePromise, adjusterPromise]).then(results => {
      setCaseReportModel(results[0])
      setCaseManager(results[1])
      setReferralSource(results[2])
      setAdjuster(results[3])

      setIsLoading(false)
    })
  }, [caseNumber, reportNumber])

  return (
    <div className="report-print-page">
      {isLoading ? (
        <>Generating Report...</>
      ) : (
        <Report
          caseReportModel={caseReportModel}
          caseManager={caseManager}
          referralSource={referralSource}
          adjuster={adjuster}
          settings={settings}
        />
      )}
    </div>
  )
}

function Report({ caseReportModel, caseManager, referralSource, adjuster, settings }) {
  const allDiagnosis = caseReportModel.case.diagnosis ?? []

  const regularNotes = orderBy(
    (caseReportModel.tasks ?? []).filter(x => x.note),
    /* order by */ x => x.completeDateTimeUtc,
  )

  return (
    <div className="report">
      <div className="text-center">
        <img
          src="/api/settings/logo"
          alt={settings.companyName}
          style={{ maxHeight: '128px', height: 'auto', width: 'auto' }}
        />
      </div>
      <div className="text-center text-bold">{settings.reportHeaderSubtext}</div>
      <div className="text-center">{settings.companyAddress}</div>

      <hr className="hard" />
      <hr className="hard" />

      <section>
        <div className="row">
          <div className="col-3">
            <LabelAndTextGroup
              label="Client Name"
              text={`${caseReportModel.case.client?.firstName} ${caseReportModel.case.client?.lastName}`}
            />
          </div>
          <div className="col-3">
            <LabelAndTextGroup label="Case Manager" text={`${caseManager.firstName} ${caseManager.lastName}`} />
          </div>
          <div className="col-3">
            <LabelAndTextGroup label="Claim #" text={caseReportModel.case.referral?.claimNumber} />
          </div>
          <div className="col-3">
            <LabelAndTextGroup label="Date of Referral" text={format.date(caseReportModel.case.referral?.date)} />
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <LabelAndTextGroup label="Referral Source" text={referralSource.name} />
          </div>
          <div className="col-3">
            <LabelAndTextGroup label="Adjuster" text={adjuster.name} />
          </div>
          <div className="col-3">
            <LabelAndTextGroup label="Employer" text={caseReportModel.case.employer?.name} />
          </div>
          <div className="col-3">
            <LabelAndTextGroup label="Internal Case #" text={caseReportModel.caseNumber} />
          </div>
        </div>

        <LabelAndTextInline
          label="Report Dates"
          labelCols={2}
          text={`${moment(caseReportModel.fromDateTimeUtc).local().format('MM/DD/YYYY')} - ${moment(
            caseReportModel.toDateTimeUtc,
          )
            .local()
            .format('MM/DD/YYYY')}`}
        />
      </section>

      <hr className="hard" />

      <section>
        <header>Injury Details</header>
        <ReportSingleLineList
          items={caseReportModel.case.injury?.descriptions ?? []}
          columns={[
            {
              title: 'Description of Injury',
              size: 6,
              getText: item => item.description,
            },
            { title: 'Provided by', size: 6, getText: item => item.provider },
          ]}
        />

        <div className="form-group">
          <ReportLabel>Injury Notes</ReportLabel>
          <ReportText>{caseReportModel.case.injury?.notes}</ReportText>
        </div>
      </section>

      <section>
        <header>Diagnoses</header>
        <DiagnosisRows name="Primary" category="Primary" allDiagnosis={allDiagnosis} />
        <DiagnosisRows name="Secondary" category="Secondary" allDiagnosis={allDiagnosis} />
        <DiagnosisRows name="Unrelated" category="Unrelated" allDiagnosis={allDiagnosis} />
      </section>

      <section>
        <header>Medications</header>
        <MedicationsSectionBody medications={caseReportModel.case.medications} />
      </section>

      <section>
        <header>Allergies</header>
        <AllergiesSectionBody allergies={caseReportModel.case.allergies} />
      </section>

      <SurgeriesAndHospitalizations elements={caseReportModel.case.surgeriesAndHospitalizations} />

      <ReviewOfSystemsSection systems={caseReportModel.case.systems} />

      <FunctionalStatucSection functionalStatuses={caseReportModel.case.functional} />

      <DiagnosticTestsSection elements={caseReportModel.case.tests} />

      <EquipmentSection elements={caseReportModel.case.equipment} />

      <TransportationSection elements={caseReportModel.case.transportation} />

      <HomeSetupSection elements={caseReportModel.case.homeSetup} />

      <TreatmentProvidersSection elements={caseReportModel.case.treatmentProviders} />

      <EquipmentProvidersSection elements={caseReportModel.case.equipmentProviders} />

      <PharmaciesSection elements={caseReportModel.case.pharmacies} />

      <SOAPNotes elements={orderBy(caseReportModel.appointments ?? [], x => x.startUtc)} />

      <RegularNotes elements={regularNotes} />

      <WorkStatusSection workStatus={caseReportModel.case.status} />

      <UpcomingAppointmentsSection appointments={caseReportModel.upcomingAppointments} />
    </div>
  )
}

function DiagnosisRows({ name, category, allDiagnosis }) {
  const items = allDiagnosis.filter(x => x.category === category)

  if (items.length === 0) return <></>

  return (
    <ReportSingleLineList
      items={allDiagnosis.filter(x => x.category === category)}
      columns={[
        { title: `${name} Diagnoses`, size: 4, getText: item => item.name },
        {
          title: `Physician`,
          size: 4,
          getText: item => item.physician ?? <EmptyText />,
        },
        {
          title: `Date`,
          size: 2,
          getText: item => format.date(item.date) ?? <EmptyText />,
        },
      ]}
    />
  )
}

function MedicationsSectionBody({ medications }) {
  if (!medications || medications.length === 0) return <ReportText>No Medications Reported</ReportText>

  return (
    <ReportSingleLineList
      items={medications}
      columns={[
        {
          title: 'Name',
          size: 3,
          getText: medication => `${medication.name}  ${medication.dosage ?? ''}  ${medication.frequency ?? ''}`,
        },
        {
          title: 'Related?',
          size: 1,
          getText: medication => (medication.relatedToInjury ? 'Yes' : 'No'),
        },
        {
          title: 'Reason',
          size: 4,
          getText: medication => medication.reason,
        },
        {
          title: 'Start',
          size: 2,
          getText: medication => format.date(medication.dateStarted),
        },
        {
          title: "Dc'ed",
          size: 2,
          getText: medication => format.date(medication.dateDiscontinued),
        },
      ]}
    />
  )
}

function AllergiesSectionBody({ allergies }) {
  if (!allergies || allergies.length === 0) return <ReportText>No Allergies Reported</ReportText>

  return (
    <ReportSingleLineList
      items={allergies}
      columns={[
        { title: 'Name', size: 4, getText: item => item.name },
        { title: 'Reaction', size: 4, getText: item => item.reaction },
      ]}
    />
  )
}

function ReviewOfSystemsSection({ systems }) {
  const elements = []
  if (systems?.general) elements.push({ label: 'General', text: systems?.general })
  if (systems?.eyes) elements.push({ label: 'Eyes', text: systems?.eyes })
  if (systems?.skin) elements.push({ label: 'Skin', text: systems?.skin })
  if (systems?.head) elements.push({ label: 'Head', text: systems?.head })
  if (systems?.ears) elements.push({ label: 'Ears', text: systems?.ears })
  if (systems?.throat) elements.push({ label: 'Throat', text: systems?.throat })

  if (elements.length === 0) return null

  return (
    <section>
      <header>Review of Systems</header>
      {elements.map(({ label, text }) => (
        <LabelAndTextInline key={label} label={label} text={text} />
      ))}
    </section>
  )
}

function FunctionalStatucSection({ functionalStatuses }) {
  const elements = []
  if (functionalStatuses?.selfcare) elements.push({ label: 'Self Care', text: functionalStatuses.selfcare })
  if (functionalStatuses?.behavior) elements.push({ label: 'Behavior', text: functionalStatuses.behavior })
  if (functionalStatuses?.mobility)
    elements.push({
      label: 'Mobility Status',
      text: functionalStatuses.mobility,
    })
  if (functionalStatuses?.bbmanagement)
    elements.push({
      label: 'Bowel and Bladder Management',
      text: functionalStatuses.bbmanagement,
    })
  if (functionalStatuses?.nutrition)
    elements.push({
      label: 'Nutrition Program',
      text: functionalStatuses.nutrition,
    })
  if (functionalStatuses?.medication)
    elements.push({
      label: 'Medication Program',
      text: functionalStatuses.medication,
    })
  if (functionalStatuses?.cognition) elements.push({ label: 'Cognition', text: functionalStatuses.cognition })
  if (functionalStatuses?.pain) elements.push({ label: 'Pain', text: functionalStatuses.pain })
  if (functionalStatuses?.household)
    elements.push({
      label: 'Household Management',
      text: functionalStatuses.household,
    })
  if (functionalStatuses?.communication)
    elements.push({
      label: 'Communication',
      text: functionalStatuses.communication,
    })

  if (elements.length === 0) return null

  return (
    <section>
      <header>Functional Statuses</header>
      {elements.map(({ label, text }) => (
        <LabelAndTextGroup key={label} label={label} text={text} />
      ))}
    </section>
  )
}

function SurgeriesAndHospitalizations({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section className="allow-page-break">
      <header>Surgeries/Hospitalizations</header>
      <ReportMultiLineList
        noItemsText="not shown"
        items={elements}
        itemComponent={item => (
          <>
            <div className="row">
              <div className="col-4">
                <LabelAndTextGroup label="Reason for Admission" text={item.title} />
              </div>
              <div className="col-2">
                <LabelAndTextGroup label="Date Admitted" text={format.date(item.dateAdmitted)} hideIfEmpty />
              </div>
              <div className="col-2">
                <LabelAndTextGroup label="Date of Surgery" text={format.date(item.dateOfSurgery)} hideIfEmpty />
              </div>
              <div className="col-2">
                <LabelAndTextGroup label="Date Discharged" text={format.date(item.dateDischarged)} hideIfEmpty />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <LabelAndTextGroup label="Physician/Specialist" text={item.provider} hideIfEmpty />
              </div>
              <div className="col-6">
                <LabelAndTextGroup label="Results" text={item.results} hideIfEmpty />
              </div>
            </div>
            <LabelAndTextGroup label="Related to Injury" text={item.relatedToInjury ? 'Yes' : 'No'} />
          </>
        )}
      />
    </section>
  )
}

function DiagnosticTestsSection({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section>
      <header>Diagnostic Tests</header>
      <ReportSingleLineList
        items={elements}
        columns={[
          { title: 'Type of Test', size: 4, getText: item => item.type },
          {
            title: 'Date of Test',
            size: 2,
            getText: item => format.date(item.date),
          },
        ]}
      />
    </section>
  )
}

function EquipmentSection({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section>
      <header>Equipment</header>

      <ReportSingleLineList
        items={elements}
        columns={[
          { title: 'Item', size: 4, getText: item => item.item },
          {
            title: 'Date Received',
            size: 2,
            getText: item => format.date(item.dateReceived),
          },
          // { title: 'Date Repaired', size: 2, getText: item => format.date(item.dateRepaired) },
          {
            title: 'Warranty Exp.',
            size: 2,
            getText: item => format.date(item.dateWarranty),
          },
          // { title: 'Related to Injury', size: 2, getText: item => item.relatedToInjury ? "Yes" : "No" },
          { title: 'Provider', size: 4, getText: item => item.provider },
        ]}
      />

      {/* waffling between single and multi line, so I'm leaving this in here. */}
      {/* 
      <ReportMulitLineList 
        noItemsText="not shown"
        items={elements}
        itemComponent={item => <>
          <div className="form-row">
            <div className="col-4">
              <LabelAndTextGroup label="Item" text={item.item} />
            </div>
            <div className="col-2">
              <LabelAndTextGroup label="Date Received" text={item.dateReceived} hideIfEmpty />
            </div>
            <div className="col-2">
              <LabelAndTextGroup label="Date Repaired" text={item.dateRepaired} hideIfEmpty />
            </div>
            <div className="col-2">
              <LabelAndTextGroup label="Warranty Exp." text={item.dateWarranty} hideIfEmpty />
            </div>
          </div>
          <LabelAndTextGroup label="Provider" text={item.provider} hideIfEmpty />
          <LabelAndTextGroup label="Related to Injury" text={item.relatedToInjury ? "Yes" : "No"} />
        </>} 
      /> */}
    </section>
  )
}

function TransportationSection({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section className="allow-page-break">
      <header>Transportation</header>

      <NameLocationContactTable items={elements} nameParts={[item => item.type, item => item.vendor]} />
    </section>
  )
}

function HomeSetupSection({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section>
      <header>Home Setup</header>

      <ReportSingleLineList
        items={elements}
        columns={[
          { title: 'Type of Housing', size: 4, getText: item => item.type },
          {
            title: 'Inaccessibilities',
            size: 4,
            getText: item => item.inaccessibilities,
          },
          {
            title: 'Wheelchair Accessible',
            size: 4,
            getText: item => (item.wheelchairAccessible ? 'Yes' : 'No'),
          },
        ]}
      />
    </section>
  )
}

function TreatmentProvidersSection({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section className="allow-page-break">
      <header>Treatment Providers</header>

      <NameLocationContactTable
        items={elements}
        nameParts={[item => item.name, item => item.speciality, item => item.companyName]}
      />
    </section>
  )
}

function EquipmentProvidersSection({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section className="allow-page-break">
      <header>Equipment Providers</header>

      <NameLocationContactTable items={elements} nameParts={[item => item.name]} />
    </section>
  )
}

function PharmaciesSection({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section className="allow-page-break">
      <header>Pharmacies</header>

      <NameLocationContactTable items={elements} nameParts={[item => item.name]} />
    </section>
  )
}

function SOAPNotes({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section className="allow-page-break">
      <header>Appointment Notes</header>

      <ReportMultiLineList
        noItemsText="Not shown"
        items={elements}
        itemComponent={item => (
          <>
            <div className="row">
              <div className="col-4">
                <LabelAndTextGroup label="Appointment" text={item.title} />
              </div>
              <div className="col-4">
                <LabelAndTextGroup label="Date" text={format.date(item.startUtc)} />
              </div>
              {item.provider && (
                <div className="col-4">
                  <LabelAndTextGroup label="Provider" text={item.provider} />
                </div>
              )}
            </div>

            <p>{item.subjectiveNote}</p>
            <p>{item.objectiveNote}</p>
            <p>{item.assessmentNote}</p>
            <p>{item.planNote}</p>
          </>
        )}
      />
    </section>
  )
}

function RegularNotes({ elements }) {
  if (!elements || elements.length === 0) return null

  return (
    <section className="allow-page-break">
      <header>Regular Notes</header>

      <ReportMultiLineList
        noItemsText="Not shown"
        showDivider={false}
        items={elements}
        itemComponent={item => (
          <>
            <div className="row">
              <div className="col-6">
                <ReportText>{item.title}</ReportText>
              </div>
            </div>
            {item.note && <p>{item.note}</p>}
          </>
        )}
      />
    </section>
  )
}

function WorkStatusSection({ workStatus }) {
  if (!workStatus) return null
  if (!workStatus.dateLastWorked && !workStatus.currentTreatment && !workStatus.workStatus && !workStatus.returnStatus)
    return null

  return (
    <section>
      <header>Work Status</header>

      {workStatus.dateLastWorked && (
        <LabelAndTextInline label="Date Last Worked" labelCols={3} text={format.date(workStatus.dateLastWorked)} />
      )}
      {workStatus.currentTreatment && (
        <LabelAndTextInline label="Current Treatment" labelCols={3} text={workStatus.currentTreatment} />
      )}
      {workStatus.workStatus && <LabelAndTextInline label="Work Status" labelCols={3} text={workStatus.workStatus} />}
      {workStatus.returnStatus && (
        <LabelAndTextInline label="Return to Work Status" labelCols={3} text={workStatus.returnStatus} />
      )}
      {workStatus.lightDuty && <LabelAndTextInline label="Light Duty Options" labelCols={3} text="Yes" />}
    </section>
  )
}

function UpcomingAppointmentsSection({ appointments }) {
  if (!appointments || appointments.length === 0) return null

  return (
    <section>
      <header>Upcoming Appointments</header>

      <ReportSingleLineList
        items={appointments}
        columns={[
          { title: 'Title', size: 4, getText: item => item.title },
          {
            title: 'Time',
            size: 3,
            getText: item => format.dateTime(item.startUtc),
          },
          { title: 'Provider', size: 4, getText: item => item.provider },
        ]}
      />
    </section>
  )
}

function NameLocationContactTable({ items, nameParts }) {
  return (
    <ReportMultiLineList
      noItemsText="not shown"
      items={items}
      itemComponent={item => (
        <>
          <div className="row">
            <div className="col-4 form-group">
              <div className="form-label">Name</div>
              {nameParts.map((x, i) => (
                <React.Fragment key={i}>{x(item) && <ReportText>{x(item)}</ReportText>}</React.Fragment>
              ))}
            </div>
            <div className="col-4 form-group">
              <div className="form-label">Location</div>
              <Address address={item.address} hideIfEmpty />
            </div>
            <div className="col-4 form-group">
              <div className="form-label">Contact</div>
              {!item.phone && !item.fax && !item.email && <EmptyText />}
              {item.phone && (
                <div className="form-text">
                  <FAIcon name="phone" />
                  &nbsp;{item.phone}
                </div>
              )}
              {item.fax && (
                <div className="form-text">
                  <FAIcon name="fax" />
                  &nbsp;{item.fax}
                </div>
              )}
              {item.email && (
                <div className="form-text">
                  <FAIcon name="envelope" />
                  &nbsp;{item.email}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    />
  )
}

function LabelAndTextGroup({ label, text, icon, hideIfEmpty }) {
  if (hideIfEmpty && !text) return null

  const iconComponent =
    icon && text ? (
      <>
        <FAIcon name={icon} />
        &nbsp;
      </>
    ) : null

  return (
    <div className="form-group">
      <ReportLabel>{label}</ReportLabel>
      {
        <ReportText>
          {iconComponent}
          {text}
        </ReportText>
      }
    </div>
  )
}

function LabelAndTextInline({ label, text, labelCols = 1 }) {
  if (!text) return null

  return (
    <div className="row form-group">
      <div className={`col-${labelCols}`}>
        <ReportLabel>{label}</ReportLabel>
      </div>
      <div className="col">
        <ReportText>{text}</ReportText>
      </div>
    </div>
  )
}

export default Page

import React from 'react'

import setFocusById from '../../../services/setFocusById'
import validation from '../../../validation'
import AddButton from '../../controls/AddButton'
import CheckBox from '../../controls/CheckBox'
import RemoveButton from '../../controls/RemoveButton'
import HousingSelector from '../../HousingSelector'

export default {
  name: 'Home Setup',
  group: 'Case Details',
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    homeSetup: validation.arrayOf(
      validation.object({
        type: validation.name('Type of Housing').required(),
        inaccessibilities: validation.shortText('Inaccessibilities'),
      }),
    ),
  })
}

function Component({ addItemToArray, removeItemFromArray, createInputProps, getValue, ErrorMessage }) {
  const homeSetups = getValue('homeSetup', [])

  return (
    <div>
      <h4 className="pb-3">Home Setup</h4>
      {homeSetups.map((x, index) => {
        const namePrefix = `homeSetup[${index}]`
        return (
          <HomeSetup
            key={namePrefix}
            namePrefix={namePrefix}
            createInputProps={createInputProps}
            ErrorMessage={ErrorMessage}
            handleRemove={() => removeItemFromArray('homeSetup', index)}
          />
        )
      })}
      <AddButton
        id="TransportationAddButton"
        onClick={() => {
          addItemToArray('homeSetup', {})
          setFocusById(`homeSetup[${homeSetups.length}].type`)
        }}
      >
        Add Home Setup
      </AddButton>
    </div>
  )
}

function HomeSetup({ namePrefix, createInputProps, ErrorMessage, handleRemove }) {
  return (
    <div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={`${namePrefix}.type`}>
            Type of Housing
          </label>
          <React.Fragment>
            <HousingSelector {...createInputProps(`${namePrefix}.type`)} />
            <ErrorMessage name={`${namePrefix}.type`} />
          </React.Fragment>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={`${namePrefix}.inaccessibilities`}>
            Inaccessibilities
          </label>
          <input
            type="text"
            {...createInputProps(`${namePrefix}.inaccessibilities`)}
            className="form-control"
            maxLength={validation.maxLengths.shortText}
          />
          <ErrorMessage name={`${namePrefix}.inaccessibilities`} />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group mt-1 col-6 col-sm-6 col-md-3 col-lg-3">
          <CheckBox {...createInputProps(`${namePrefix}.wheelchairAccessible`)}>Wheelchair Accessible</CheckBox>
        </div>
        <div className="col-6 col-sm-6 col-md-3 col-lg-3">
          <RemoveButton id={`${namePrefix}RemoveBtn`} className="float-right" onClick={() => handleRemove()}>
            Remove
          </RemoveButton>
        </div>
      </div>
    </div>
  )
}

import moment from 'moment'

export default {
  date,
  dateTime,
  shortDateTime,
  time,
  money,
}

function date(value) {
  return value ? moment(value).format('MM/DD/YYYY') : null
}

function dateTime(value) {
  return value ? moment.utc(value).local().format('MM/DD/YYYY h:mm a') : null
}

function shortDateTime(value) {
  return value ? moment.utc(value).local().format('M/D/YY h:mma') : null
}

function time(value) {
  return value ? moment.utc(value).local().format('h:mm a') : null
}

function money(amount, decimalCount = 2, decimal = '.', thousands = ',') {
  if (!amount) return null

  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount

  const negativeSign = amount < 0 ? '-' : ''

  const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
  const j = i.length > 3 ? i.length % 3 : 0

  return (
    negativeSign +
    '$' +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : '')
  )
}

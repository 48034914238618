import { getToken } from '../utils/getToken'

export default {
  addRoles,
  removeRoles,
  createUser,
  get,
  getAll,
  getSelection,
  getCaseManagers,
}

function handleSuccess(result) {
  if (!result.ok) throw new Error(result)

  return result.json()
}

function handleFailure(defaultErrorMessage, err) {
  throw new Error(defaultErrorMessage || err)
}

function get(id) {
  return fetch(`/api/users/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve user information.', err))
}

function getAll() {
  return fetch('/api/users', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve the list of users.', err))
}

function getSelection(requiredIds) {
  if (requiredIds && requiredIds.length !== 0) {
    return fetch(`/api/users?userIds=${requiredIds}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(handleSuccess)
      .catch(err => handleFailure('Unable to retrieve list of users.', err))
  }

  return Promise.resolve([])
}

function getCaseManagers() {
  return fetch('/api/users/casemanagers', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve list of users.', err))
}

function createUser(body) {
  return fetch('/api/users', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to create user.', err))
}

function addRoles(userId, roles) {
  return fetch(`/api/users/${userId}/add-roles`, {
    method: 'PUT',
    body: JSON.stringify({ roles }),
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to add roles for user.', err))
}

function removeRoles(userId, roles) {
  return fetch(`/api/users/${userId}/remove-roles`, {
    method: 'PUT',
    body: JSON.stringify({ roles }),
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to remove roles for user.', err))
}

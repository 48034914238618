import moment from 'moment'

const CaseSchema = [
  { name: 'detailsForCaseManager' },
  { name: 'clientHasBeenContacted', value: false },
  { name: 'attorneyHasBeenContacted', value: false },
  { name: 'employerHasBeenContacted', value: false },
  { name: 'referral.date', value: moment().format('YYYY-MM-DD') },
  { name: 'referral.sourceId' },
  { name: 'referral.adjusterId' },
  { name: 'client.firstName' },
  { name: 'client.lastName' },
  { name: 'client.phone' },
  { name: 'client.fax' },
  { name: 'client.email' },
  { name: 'client.address.line1' },
  { name: 'client.address.city' },
  { name: 'client.address.state' },
  { name: 'client.address.zip' },
  { name: 'client.birthdate' },
  { name: 'client.hasAlternateContact', value: false },
  { name: 'client.alternateContact.firstName' },
  { name: 'client.alternateContact.lastName' },
  { name: 'client.alternateContact.phone' },
  { name: 'client.alternateContact.fax' },
  { name: 'client.alternateContact.email' },
  { name: 'client.firstLanguage' },
  { name: 'client.secondLanguage' },
  { name: 'employer.name' },
  { name: 'employer.address.line1' },
  { name: 'employer.address.city' },
  { name: 'employer.address.state' },
  { name: 'employer.address.zip' },
  { name: 'employer.contact.firstName' },
  { name: 'employer.contact.lastName' },
  { name: 'employer.contact.title' },
  { name: 'employer.contact.phone' },
  { name: 'employer.contact.fax' },
  { name: 'employer.contact.email' },

  { name: 'plaintiff.name' },
  { name: 'plaintiff.address.line1' },
  { name: 'plaintiff.address.city' },
  { name: 'plaintiff.address.state' },
  { name: 'plaintiff.address.zip' },
  { name: 'plaintiff.contact.firstName' },
  { name: 'plaintiff.contact.lastName' },
  { name: 'plaintiff.contact.phone' },
  { name: 'plaintiff.contact.fax' },
  { name: 'plaintiff.contact.email' },
  { name: 'defendant.name' },
  { name: 'defendant.address.line1' },
  { name: 'defendant.address.city' },
  { name: 'defendant.address.state' },
  { name: 'defendant.address.zip' },
  { name: 'defendant.contact' },
  { name: 'defendant.contact.firstName' },
  { name: 'defendant.contact.lastName' },
  { name: 'defendant.contact.phone' },
  { name: 'defendant.contact.fax' },
  { name: 'defendant.contact.email' },

  { name: 'injury.date' },
  { name: 'injury.descriptions', value: [] },
  { name: 'injury.notes' },
  { name: 'injury.recordFiles', value: [] },

  { name: 'caseManager' },

  { name: 'appointments', value: [] },
  { name: 'billableTime', value: null },
  { name: 'billableDate', value: new Date() },
]

export default CaseSchema

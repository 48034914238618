import React from 'react'
import { useAuth } from 'react-oidc-context'
import { Route, withRouter } from 'react-router-dom'

import { COGNITO_SCOPES } from '../utils/Constants'

const PrivateRoute = withRouter(({ children, location, ...rest }) => {
  const auth = useAuth()
  return <Route {...rest}>{auth.isAuthenticated ? children : auth.signinRedirect({ scope: COGNITO_SCOPES })}</Route>
})

export default PrivateRoute

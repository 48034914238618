import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { withRouter } from 'react-router-dom'

import DNPTable from '../components/DNPTable'
import createFormHelpers from '../FormHelpers'
import UserService from '../services/UserService'
import { ADMIN_ROLE_NAME, ROLE_NAMES } from '../utils/Constants'

export default withRouter(({ history, currentUser }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [team, setTeam] = useState([])

  const { getValue } = createFormHelpers({})

  useEffect(() => {
    let usersPromise = null
    if (currentUser.roles.includes(ADMIN_ROLE_NAME)) {
      usersPromise = UserService.getAll()
    } else {
      usersPromise = Promise.resolve([currentUser])
    }

    usersPromise
      .then(teamMembers => {
        setTeam(teamMembers)
        setIsLoading(false)
      })
      .catch(err => {
        setError(err.message)
        setIsLoading(false)
      })

    // eslint-disable-next-line
    }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-2">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  const getUserRoles = user => {
    const roles = getValue('roles', '', user)

    return ROLE_NAMES.filter(rn => roles.includes(rn.key))
      .map(rn => rn.label)
      .join(', ')
  }

  return (
    <div className="container mt-2 pb-3">
      {!!error && (
        <Alert variant="danger" className="mb-4 pr-5 pl-5">
          <strong>Error:</strong> <pre className="mb-0">{error}</pre>
        </Alert>
      )}
      <div className="d-flex justify-content-end my-4">
        {currentUser.roles.includes(ADMIN_ROLE_NAME) && (
          <a className="main-link" href={`/library/team-members/add`}>
            Add Team Member
          </a>
        )}
      </div>
      <DNPTable
        items={team}
        columns={[
          {
            title: 'Name',
            getText: teamMember => (
              <a href={`/library/team-members/${getValue('id', '', teamMember)}`}>
                {`${getValue('firstName', '', teamMember)} ${getValue('lastName', '', teamMember)}`}
              </a>
            ),
          },
          { title: 'Email', getText: teamMember => getValue('email', '', teamMember) },
          { title: 'Roles', getText: teamMember => getUserRoles(teamMember) },
          {
            title: 'Export',
            getText: teamMember => {
              return (
                <a href={`/library/team-members/${teamMember.id}/productivity-report`}>Export Productivity Report</a>
              )
            },
          },
        ]}
        noItemsText="No team members have been added"
      />
    </div>
  )
})

import React from 'react'

import setFocusById from '../../services/setFocusById'
import AddButton from '../controls/AddButton'
import DatePickerField from '../controls/DatePickerField'
import { ErrorMessage, Field, FieldArray } from '../controls/FormikWrappers'
import RadioButtonField from '../controls/RadioButtonField'
import RemoveButton from '../controls/RemoveButton'
import TimeEntryField from '../controls/TimeEntryField'

const CompleteForm = ({ caseModel }) => {
  const addTask = pushToFieldArray => {
    pushToFieldArray({ title: '' })
    setFocusById(`tasks[${caseModel.tasks?.length ?? 0}].title`)
  }

  return (
    <div>
      <ErrorMessage name="caseManager" />
      <h4 className="form-heading mt-5">Details for Case Manager</h4>
      <p className="text-danger">All fields marked with * are required</p>
      <div className="form-row">
        <div className="col-md-6 mt-3">
          <div className="form-group">
            <label className="form-label" htmlFor="detailsForCaseManager">
              Notes for Case Manager
            </label>
            <Field
              component="textarea"
              id="detailsForCaseManager"
              name="detailsForCaseManager"
              className="form-control"
              rows="5"
            />
            <ErrorMessage name="detailsForCaseManager" />
          </div>
        </div>
      </div>
      <RadioButtonField
        name="clientHasBeenContacted"
        title="Has the client been contacted?"
        label1="Yes"
        label2="No"
        id="clientHasBeenContacted"
      />
      <RadioButtonField
        name="attorneyHasBeenContacted"
        title="Has the attorney been contacted?"
        label1="Yes"
        label2="No"
        id="attorneyHasBeenContacted"
      />
      <RadioButtonField
        name="employerHasBeenContacted"
        title="Has the employer been contacted?"
        label1="Yes"
        label2="No"
        id="employerHasBeenContacted"
      />
      <h4 className="mt-3 mb-3">Tasks</h4>
      <div>
        <FieldArray
          name="tasks"
          render={({ push, remove, form }) => (
            <div>
              {(form.values.tasks ?? []).map((task, index) => (
                <div key={index} className="mt-2">
                  <div className="form-row">
                    <Field
                      type="text"
                      id={`tasks[${index}].title`}
                      name={`tasks[${index}].title`}
                      className="form-control col-6"
                    />
                    <RemoveButton id={`removeTask${index}`} onClick={() => remove(index)} />
                  </div>
                  <ErrorMessage name={`tasks[${index}].title`} />
                </div>
              ))}
              <div className="form-row mt-1">
                <AddButton id="addTask" onClick={() => addTask(push)}>
                  Add Task
                </AddButton>
              </div>
            </div>
          )}
        />
      </div>
      <hr className="mt-3 mb-3"></hr>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label" htmlFor="billableDate">
              Date
            </label>
            <DatePickerField name="billableDate" id="billableDate" value={caseModel.billableDate} />
            <ErrorMessage name="billableDate" />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label" htmlFor="billableTime">
              Billable Time*
            </label>
            <TimeEntryField name="billableTime" id="billableTime" value={caseModel.billableTime} />
            <ErrorMessage name="billableTime" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompleteForm

import React, { useEffect, useState } from 'react'

import AutoCompleteComponent, {
  getAddressDetails,
  getStreetAddress,
} from '../../../components/autocomplete/AutoComplete'
import setFocusById from '../../../services/setFocusById'
import validation from '../../../validation'
import AddButton from '../../controls/AddButton'
import RemoveButton from '../../controls/RemoveButton'
import StateSelector from '../../StateSelector'
import TransportationSelector from '../../TransportationSelector'

export default {
  name: 'Transportation',
  group: 'Case Details',
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    transportation: validation.arrayOf(
      validation.object({
        type: validation.name('Type of Transportation').required(),
        vendor: validation.name('Vendor name'),
        address: validation.address('Vendor address'),
        phone: validation.phone('Vendor phone'),
        fax: validation.fax('Vendor fax'),
        email: validation.email('Vendor email'),
      }),
    ),
  })
}

function Component({
  caseModel,
  addItemToArray,
  removeItemFromArray,
  createInputProps,
  getValue,
  ErrorMessage,
  setModelValues,
}) {
  const transportation = getValue('transportation', [])

  const Add = () => {
    addItemToArray('transportation', {})
    setFocusById(`transportation[${transportation.length}].type`)
  }

  return (
    <div>
      <h4 className="pb-3">Transportation</h4>
      {transportation.map((x, index) => {
        const namePrefix = `transportation[${index}]`
        return (
          <Transportation
            caseModel={caseModel}
            key={namePrefix}
            namePrefix={namePrefix}
            createInputProps={createInputProps}
            getValue={getValue}
            ErrorMessage={ErrorMessage}
            handleRemove={() => removeItemFromArray('transportation', index)}
            setModelValues={setModelValues}
          />
        )
      })}
      {transportation.length > 0 ? (
        <div className="force-inline">
          <AddButton id="TransportationAddButton" className="mt-1" onClick={() => Add()}>
            Add Transportation
          </AddButton>
        </div>
      ) : (
        <AddButton id="TransportationAddButton" onClick={() => Add()}>
          Add Transportation
        </AddButton>
      )}
    </div>
  )
}

function Transportation({
  caseModel,
  namePrefix,
  createInputProps,
  ErrorMessage,
  handleRemove,
  getValue,
  setModelValues,
}) {
  const [address, setAddress] = useState(null)

  useEffect(() => {
    const streetAddress = getStreetAddress(address)
    if (!streetAddress) {
      return
    }

    setModelValues([
      [`${namePrefix}.address.line1`, streetAddress],
      [`${namePrefix}.address.city`, getAddressDetails(address, 'locality')],
      [`${namePrefix}.address.zip`, getAddressDetails(address, 'postal_code')],
      [`${namePrefix}.address.state`, getAddressDetails(address, 'administrative_area_level_1')],
    ])
  }, [address, namePrefix, setModelValues])

  return (
    <div className="clearfix">
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={`${namePrefix}.type`}>
            Type of Transportation
          </label>
          <React.Fragment>
            <TransportationSelector {...createInputProps(`${namePrefix}.type`)} />
            <ErrorMessage name={`${namePrefix}.type`} />
          </React.Fragment>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={`${namePrefix}.vendor`}>
            Vendor Name
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.name}
            {...createInputProps(`${namePrefix}.vendor`)}
          />
        </div>
        <ErrorMessage name={`${namePrefix}.vendor`} />
      </div>

      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.line1`}>
              Address
            </label>
            <AutoCompleteComponent address={setAddress} />
            <input
              type="text"
              className="form-control"
              maxLength={validation.maxLengths.address.line1}
              {...createInputProps(`${namePrefix}.address.line1`)}
            />
            <ErrorMessage name={`${namePrefix}.address.line1`} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.city`}>
              City
            </label>
            <input
              type="text"
              className="form-control"
              maxLength={validation.maxLengths.address.line1}
              {...createInputProps(`${namePrefix}.address.city`)}
            />
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.state`}>
              State
            </label>
            <React.Fragment>
              <StateSelector
                name={`${namePrefix}.address.state`}
                {...createInputProps(`${namePrefix}.address.state`)}
              />
            </React.Fragment>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.zip`}>
              Zip Code
            </label>
            <input
              type="text"
              className="form-control"
              maxLength={validation.maxLengths.address.zip}
              {...createInputProps(`${namePrefix}.address.zip`)}
            />
          </div>
        </div>
      </div>
      <div>
        <ErrorMessage name={`${namePrefix}.address.city`} />
        <ErrorMessage name={`${namePrefix}.address.state`} />
        <ErrorMessage name={`${namePrefix}.address.zip`} />
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.phone`}>
              Phone
            </label>
            <input
              type="tel"
              {...createInputProps(`${namePrefix}.phone`)}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name={`${namePrefix}.phone`} />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.fax`}>
              Fax
            </label>
            <input
              type="tel"
              {...createInputProps(`${namePrefix}.fax`)}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name={`${namePrefix}.fax`} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={`${namePrefix}.email`}>
            Email
          </label>
          <input
            type="text"
            className="form-control"
            {...createInputProps(`${namePrefix}.email`)}
            placeholder="name@email.com"
            maxLength={validation.maxLengths.shortText}
          />
          <ErrorMessage name={`${namePrefix}.email`} />
        </div>
      </div>
      <div className="col-md-6">
        <RemoveButton id={`${namePrefix}RemoveBtn`} className="float-right" onClick={() => handleRemove()}>
          Remove
        </RemoveButton>
      </div>
    </div>
  )
}

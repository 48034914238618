import React from 'react'
import { Button, Navbar } from 'react-bootstrap'

import './CaseNavigationBar.scss'

const CaseNavigationBar = ({ title, subtitle, rightButtonText, handleClickLeft, handleRightClick, shadow }) => {
  return (
    <Navbar className={`navbar navbar-expand-md bg-white case-nav-bar ${shadow}`} fixed="top">
      <div className="container">
        <Button variant="link" type="button" id="leftBtn" onClick={handleClickLeft}>
          <i className="fas fa-lg fa-times close-icon"></i>
        </Button>
        <div className="ml-auto mr-auto mt-2 mb-1">
          <h6 className="title">{title}</h6>
          <p className="subtitle">{subtitle}</p>
        </div>
        <div className="save-button-container">
          <Button className="action-button" type="button" variant="link" id="rightBtn" onClick={handleRightClick}>
            {rightButtonText}
          </Button>
        </div>
      </div>
    </Navbar>
  )
}

CaseNavigationBar.Container = ({ children }) => <div style={{ marginTop: '4.2rem' }}>{children}</div>

export default CaseNavigationBar

import React from 'react'

import { formatDate } from '../../utils/formatDate'

/** @typedef { import('../../services/types/CaseSearchResultItem').CaseSearchResultItem } CaseSearchResultItem */

/**
 * @param {{ rowData: CaseSearchResultItem }}} props
 */
export function CaseResultRow(props) {
  const { rowData } = props

  const members = rowData.members?.map(member => `${member.fullName}`).join(', ') ?? ''

  const linkHref = rowData.intakeComplete ? `/cases/${rowData.caseNumber}` : `/case/intake/${rowData.caseNumber}`

  return (
    <tr>
      <th scope="row">
        <a href={linkHref}>
          <div className="column-wrapper">{rowData.clientFullName}</div>
        </a>
      </th>
      <td className="d-none d-md-table-cell">
        <div className="column-wrapper">{members}</div>
      </td>
      <td className="d-none d-xl-table-cell">
        <div className="column-wrapper">{rowData.company}</div>
      </td>
      <td className="d-none d-sm-table-cell">{formatDate(rowData.createdUtc)}</td>
      <td>{rowData.status}</td>
      <td className="d-none d-lg-table-cell">{formatDate(rowData.lastUpdatedUtc)}</td>
    </tr>
  )
}

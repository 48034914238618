import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'

import { CASE_ADMIN_ROLE_NAME, CASE_MANAGER_ROLE_NAME } from '../utils/Constants'

import FAIcon from './FAIcon'

import './BottomNav.scss'

const BottomNav = props => (
  <Navbar className="bottom-nav" variant="dark" fixed="bottom">
    <div className="container">
      <Nav justify>
        <Nav.Item>
          <Nav.Link href="/">
            <FAIcon name="home fa-lg" />
            <p className="nav-text">Home</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/calendar">
            <FAIcon name="calendar fa-lg" fa-lg />
            <p className="nav-text">Calendar</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="dropup">
          <button className="dropup-btn" onClick={props.handleOnSelect}>
            <FAIcon name="plus-circle fa-2x" />
          </button>
          {props.isDropdownToggled && (
            <ul id="drop-down" className="dropup-content">
              {!!props.user && !!props.user.roles && props.user.roles.includes(CASE_ADMIN_ROLE_NAME) && (
                <li>
                  <a className="dropdown-item" href="/case/intake">
                    New Case
                  </a>
                </li>
              )}
              {!!props.user && !!props.user.roles && props.user.roles.includes(CASE_MANAGER_ROLE_NAME) && (
                <React.Fragment>
                  <li>
                    <a className="dropdown-item" href="/appointments">
                      New Appointment
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/tasks">
                      New Task
                    </a>
                  </li>
                </React.Fragment>
              )}
            </ul>
          )}
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/cases">
            <FAIcon name="copy fa-lg" />
            <p className="nav-text">Cases</p>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/library">
            <FAIcon name="bookmark fa-lg" />
            <p className="nav-text">Library</p>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  </Navbar>
)

export default BottomNav

import React from 'react'
import { Link } from 'react-router-dom'

import Moment from 'moment'

import validation from '../../../validation'

import './Tasks.scss'

export default {
  name: 'Regular Note',
  group: 'Notes & Time',
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    tasks: validation.arrayOf(
      validation.object({
        title: validation.shortText('Title'),
        content: validation.note('Content'),
        time: validation.object({
          activity: validation.shortText('Activity'),
          time: validation.shortText('content'),
          description: validation.shortText('Description'),
        }),
        expense: validation.object({
          type: validation.shortText('Type of Expense'),
          amount: validation.shortText('Amount'),
          distance: validation.shortText('Distance'),
          description: validation.shortText('Description'),
        }),
      }),
    ),
  })
}

function Component({ caseModel, caseNumber, tasks }) {
  const taskList = tasks

  function getCompleteTasks(tasks) {
    const completedList = tasks.filter(task => {
      return task.complete === true
    })
    return getSortedTasksDescendingByDate(completedList)
  }

  // sorts tasks in descending order of task completion date and time.
  function getSortedTasksDescendingByDate(tasks) {
    const dateTimeFormat = 'MM/DD/YYYY, hh:mm:ss a'
    return tasks.sort((left, right) => {
      return Moment.utc(left.completeDateTimeUtc).format(dateTimeFormat) >
        Moment.utc(right.completeDateTimeUtc).format(dateTimeFormat)
        ? 1
        : Moment.utc(left.completeDateTimeUtc).format(dateTimeFormat) <
          Moment.utc(right.completeDateTimeUtc).format(dateTimeFormat)
        ? -1
        : 0
    })
  }

  return (
    <div>
      <div className="tasks-section-container">
        <div className="clearfix">
          <h4 className="pb-3 float-left">Regular Notes</h4>
          <Link to={`/cases/${caseNumber}/tasks?asNote=true`} className="btn btn-primary float-right">
            New Note
          </Link>
        </div>
        <div className="card border-light">
          <table className="table mb-0">
            <thead>
              <tr>
                <th scope="col">TITLE</th>
                <th className="" scope="col">
                  NOTES
                </th>
                <th className="" scope="col">
                  DATE COMPLETED
                </th>
              </tr>
            </thead>
            <tbody>
              {!!taskList &&
                getCompleteTasks(taskList).map(task => {
                  const dateTime = Moment.utc(task.completeDateTimeUtc).local().format('M/D/YY h:mma')
                  return (
                    <React.Fragment key={task.taskNumber}>
                      <tr>
                        <td>
                          <Link to={`/cases/${caseNumber}/tasks/${task.taskNumber}?asNote=true`}>
                            <div className="column-wrapper">{`${task.title}`}</div>
                          </Link>
                        </td>
                        <td>
                          <textarea
                            className="form-control display-only-textbox"
                            rows="2"
                            value={task.note || ''}
                            disabled
                          />
                        </td>
                        <td>
                          <div>{dateTime}</div>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
            </tbody>
          </table>
          {(!taskList || taskList.length === 0) && (
            <div className="empty-table-message-container">
              <h6 className="mb-0">No notes have been added</h6>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import React, { Children, useEffect, useState } from 'react'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import Alert from 'react-bootstrap/Alert'
import { withRouter } from 'react-router-dom'

import Moment from 'moment'

import CalendarCustomToolbar from '../components/calendar/CalendarCustomToolbar'
import CalendarEventComponent from '../components/calendar/CalendarEventComponent'
import CalendarTimeEventComponent from '../components/calendar/CalendarTimeEventComponent'
import AppointmentService from '../services/AppointmentsService'

import 'react-big-calendar/lib/sass/styles.scss'
import './CalendarPage.scss'

const CalendarPage = withRouter(({ history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [appointments, setAppointments] = useState([])

  // Switch the calendar view to start with Mondays
  Moment.locale(Moment.lang, {
    week: {
      dow: 1,
      doy: 1,
    },
  })

  const localizer = momentLocalizer(Moment)
  const allViews = Object.keys(Views).map(k => Views[k])

  useEffect(() => {
    setIsLoading(true)
    AppointmentService.getAll()
      .then(r => {
        setAppointments(r)
        setIsLoading(false)
      })
      .catch(err => {
        setError(err.message)
        setIsLoading(false)
      })
    // eslint-disable-next-line
    }, [])

  // Format of event object
  // Event {
  //   id: long,
  //   caseNumber: long
  //   title: string,
  //   start: Date,
  //   end: Date,
  //   isComplete: boolean,
  //   allDay?: boolean
  //   resource?: any,
  // }
  const getEventsList = () => {
    const events = []

    if (appointments) {
      appointments.forEach(appointment => {
        events.push({
          id: appointment.appointmentNumber,
          caseNumber: appointment.caseNumber,
          title: appointment.title,
          isComplete: appointment.complete,
          start: convertUtcToLocalDateTime(appointment.startUtc),
          end: convertUtcToLocalDateTime(appointment.endUtc),
        })
      })
    }

    return events
  }

  const onSelectEvent = event => {
    if (!event.isComplete) {
      history.push(`/cases/${event.caseNumber}/appointments/${event.id}`)
    } else {
      history.push(`/cases/${event.caseNumber}/appointments/${event.id}/complete`)
    }
  }

  const convertUtcToLocalDateTime = utcDateTime => {
    return utcDateTime ? new Date(Moment.utc(utcDateTime).local().format()) : null
  }

  const ColoredDateCellWrapper = ({ children, value }) => {
    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: Moment(value).day() === 6 || Moment(value).day() === 0 ? '#f4f6ff' : 'transparent',
        margin: '1px',
      },
    })
  }

  const formats = {
    timeGutterFormat: 'h A',
  }

  const eventsList = getEventsList()

  return (
    <div className="calendar-page-container">
      {error && (
        <Alert variant="danger">
          <strong>Error:</strong> {error.message}
        </Alert>
      )}
      {isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <Calendar
        className="calendar-component shadow my-4"
        localizer={localizer}
        formats={formats}
        events={eventsList}
        views={allViews}
        defaultDate={new Date()}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={onSelectEvent}
        components={{
          toolbar: CalendarCustomToolbar,
          month: {
            event: CalendarEventComponent,
          },
          week: {
            event: CalendarTimeEventComponent,
          },
          day: {
            event: CalendarTimeEventComponent,
          },
          dateCellWrapper: ColoredDateCellWrapper,
        }}
      />
    </div>
  )
})

export default CalendarPage

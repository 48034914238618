import React from 'react'

import { Field } from 'formik'

export default props => (
  <>
    <Field {...props}>
      {({ field, form }) => {
        const name = field.name
        const value = field.value

        let splitValue = ['', '', '']
        if (value) splitValue = value.split('/')
        if (splitValue.length !== 3) {
          splitValue = ['', '', '']
        }

        const createOnChange = part => {
          return e => {
            splitValue[part] = `${e.target.value}`

            const newValue = splitValue.every(x => x === '') ? null : splitValue.join('/')

            form.setFieldValue(name, newValue)
          }
        }

        const handleOnBlur = e => {
          // only fire blur once this group of fields have been left
          if (e.relatedTarget && e.relatedTarget.hasAttribute('x-date-part')) return

          // raising a fake event, because field.onBlur needs the name in order to trigger validations
          field.onBlur({
            target: {
              name,
            },
          })
        }

        const propsFor = part => ({
          value: splitValue[part],
          onChange: createOnChange(part),
          onBlur: handleOnBlur,
          'x-date-part': part,
        })

        return (
          <>
            <div className="form-row">
              <div className="col-3">
                <input type="number" className="form-control" id={`${props.id}.MM`} placeholder="MM" {...propsFor(0)} />
              </div>
              <div className="col-3">
                <input type="number" className="form-control" id={`${props.id}.DD`} placeholder="DD" {...propsFor(1)} />
              </div>
              <div className="col-6">
                <input
                  type="number"
                  className="form-control"
                  id={`${props.id}.YYYY`}
                  placeholder="YYYY"
                  {...propsFor(2)}
                />
              </div>
            </div>
          </>
        )
      }}
    </Field>
  </>
)

import logger from '../services/logger'
import { getToken } from '../utils/getToken'

export const GET_USER_PROFILE_BEGIN = 'GET_USER_PROFILE_BEGIN'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE'
export const GET_SETTINGS_BEGIN = 'GET_SETTINGS_BEGIN'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE'

export const getUserProfileBegin = response => ({
  type: GET_USER_PROFILE_BEGIN,
})

export const getUserProfileSuccess = response => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: response,
})

export const getUserProfileFailure = error => ({
  type: GET_USER_PROFILE_FAILURE,
  error,
})

export const getSettingsSuccess = response => ({
  type: GET_SETTINGS_SUCCESS,
  settings: response,
})

export const getSettingsFailure = error => ({
  type: GET_SETTINGS_FAILURE,
  error,
})

export function beginLoading() {
  return dispatch => {
    getUserProfile(dispatch)
    getSettings(dispatch)
  }
}

function getUserProfile(dispatch) {
  let getProfileAttempt = 0

  getProfile(dispatch)

  function getProfile(dispatch) {
    fetch('/api/profile', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(async response => {
        if (response.status === 504 /* gateway timeout */) {
          getProfileAttempt++

          if (getProfileAttempt > 30) throw Error('Took too long to get user profile')

          logger.info('Get profile timed out. Retrying...')

          setTimeout(() => getProfile(dispatch), 1000)
          return
        }

        if (!response.ok) {
          if (response.status === 403) {
            throw Error('User is not authorized to use this tenant')
          }

          throw Error(response.statusText)
        }
        const payload = await response.json()
        dispatch(getUserProfileSuccess(payload.user))
      })
      .catch(error => dispatch(getUserProfileFailure(error)))
  }
}

function getSettings(dispatch) {
  let getProfileAttempt = 0

  getSettingsInner(dispatch)

  function getSettingsInner(dispatch) {
    fetch('/api/settings')
      .then(async response => {
        if (response.status === 504 /* gateway timeout */) {
          getProfileAttempt++

          if (getProfileAttempt > 30) throw Error('Took too long to get settings')

          logger.info('Get settings timed out. Retrying...')

          setTimeout(() => getSettingsInner(dispatch), 1000)
          return
        }

        if (!response.ok) {
          throw Error(response.statusText)
        }

        const settings = await response.json()
        dispatch(getSettingsSuccess(settings))
      })
      .catch(error => dispatch(getSettingsFailure(error)))
  }
}

import React from 'react'
import { Modal } from 'react-bootstrap'

export default ({ title, body, size = 'md', onConfirm, onClose, cancelText = 'Cancel', confirmText = 'Confirm' }) => {
  return (
    <Modal show={true} size={size} onHide={onClose}>
      <Modal.Body>
        <h1 className="h6">{title}</h1>
        <div className="mb-4">{body}</div>
        <div className="text-right">
          <button type="button" className="btn btn-secondary mr-1" onClick={onClose}>
            {cancelText}
          </button>
          <button type="button" className="btn btn-primary" onClick={onConfirm}>
            {confirmText}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

/** @typedef { import('../services/types/SortDirection').SortDirection } SortDirection */

/**
 * Returns the next sort direction based on the current sort direction.
 * @param {string} sortDirection - The current sort direction.
 * @returns {SortDirection} The next sort direction.
 */
export function nextSort(sortDirection) {
  switch (sortDirection) {
    case 'Asc':
      return 'Desc'
    case 'Desc':
      return 'None'
    default:
      return 'Asc'
  }
}

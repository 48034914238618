import React from 'react'

import Moment from 'moment'

import 'react-big-calendar/lib/sass/styles.scss'
import './CalendarTimeEventComponent.scss'

const CalendarTimeEventComponent = selectedObject => {
  const isDayInPast = Moment.utc(selectedObject.event.end) < Moment.utc()

  const getTopPercent = startUtc => {
    if (startUtc) {
      const startOfDay = Moment.utc(startUtc).local().startOf('day')
      const startTime = Moment.utc(startUtc).local()
      const timeDifference = startTime.diff(startOfDay, 'minutes')

      return `${(timeDifference / 1440) * 100}%`
    } else {
      return '0%'
    }
  }

  const getHeightPercent = (startUtc, endUtc) => {
    if (startUtc) {
      const startTime = Moment.utc(startUtc).local()
      const endTime = Moment.utc(endUtc).local()
      const timeDifference = endTime.diff(startTime, 'minutes')

      return `${(timeDifference / 1440) * 100}%`
    } else {
      return '0%'
    }
  }

  return (
    <div
      className={`rbc-event-custom-week ${isDayInPast && 'rbc-event-custom-week-past'}`}
      style={{
        top: getTopPercent(selectedObject.event.start),
        height: getHeightPercent(selectedObject.event.start, selectedObject.event.end),
      }}
    >
      <div className={`rbc-event-custom-week-content ${isDayInPast && 'rbc-event-custom-week-content-past'}`}>
        {selectedObject.title}
      </div>
    </div>
  )
}

export default CalendarTimeEventComponent

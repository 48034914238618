import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import { withRouter } from 'react-router-dom'

import Moment from 'moment'

import AppointmentDashboardCard from '../components/AppointmentDashboardCard'
import DashboardWrapper from '../components/DashboardWrapper'
import FAIcon from '../components/FAIcon'
import ReportDashboardCard from '../components/ReportDashboardCard'
import TaskCard from '../components/TaskCard'
import createFormHelpers from '../FormHelpers'
import AppointmentsService from '../services/AppointmentsService'
import CasesService from '../services/CasesService'
import TasksService from '../services/TasksService'
import { ADMIN_ROLE_NAME, CASE_MANAGER_ROLE_NAME } from '../utils/Constants'

import './Home.scss'

const reportModelFields = [
  'caseNumber',
  'reportNumber',
  'case.client.firstName',
  'case.client.lastName',
  'submittedOnUtc',
  'fromDateTimeUtc',
  'toDateTimeUtc',
  'totalBillableTime',
]

export default withRouter(({ user, settings, history }) => {
  const [cases, setCases] = useState([{}])
  const [tasks, setTasks] = useState([])
  const [reports, setReports] = useState([])
  const [appointments, setAppointments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  const { getValue } = createFormHelpers({})

  useEffect(() => {
    const promises = []

    const reportsPromise = CasesService.getReportsByStatus(
      settings.caseStatuses.submitted.statusText,
      reportModelFields,
    ).then(r => setReports(r))
    promises.push(reportsPromise)

    if (user.roles.includes(CASE_MANAGER_ROLE_NAME)) {
      const casePromise = CasesService.getAll(['caseNumber', 'client.firstName', 'client.lastName']).then(caseObjs => {
        setCases(caseObjs)
        return caseObjs
      })
      promises.push(casePromise)

      const tasksPromise = TasksService.getAll(true).then(r => {
        setTasks(r)
      })
      promises.push(tasksPromise)

      const appointmentsPromise = AppointmentsService.getAll(true).then(r => {
        setAppointments(r)
      })
      promises.push(appointmentsPromise)
    }

    Promise.all(promises)
      .then(() => {
        setIsLoading(false)
      })
      .catch(err => {
        setError(err)
        setIsLoading(false)
      })

    // eslint-disable-next-line
  }, []);

  const handleButtonClick = path => {
    history.push(path)
  }

  const getFormattedDateTimeValue = (startLocal, endLocal) => {
    if (!!startLocal && !!endLocal) {
      const date = Moment(startLocal).format('dddd, MMMM DD, YYYY')
      const startTime = Moment(startLocal).format('h:mma')
      const endTime = Moment(endLocal).format('h:mma')

      return (
        <p className="text-details mb-0">
          {date} &#8226; {startTime}-{endTime}
        </p>
      )
    } else {
      return ''
    }
  }

  const convertUtcToLocalDateTime = utcDateTime => {
    return utcDateTime ? Moment.utc(utcDateTime).local().format() : null
  }

  const getAppointmentsList = () => {
    const appointmentsList = []

    if (appointments) {
      // sort the list of appointments by their start date
      const sortedAppointmentsList = appointments
        .slice()
        .sort((a, b) => (a.startUtc > b.startUtc ? 1 : a.startUtc === b.startUtc ? 1 : -1))

      // add the appointments to the list until we have 6 or we run out of appointments
      let i = 0
      while (appointmentsList.length < 6 && i < sortedAppointmentsList.length) {
        appointmentsList.push(sortedAppointmentsList[i])
        i++
      }
    }

    return appointmentsList
  }

  const appointmentsList = getAppointmentsList()

  let showReports = true
  if (reports.length === 0) {
    if (!user.roles.includes(ADMIN_ROLE_NAME)) {
      showReports = false
    }
  }

  return (
    <DashboardWrapper user={user}>
      {!isLoading ? (
        <React.Fragment>
          <div className="dashboard-container">
            {showReports && <CaseReportsSection caseReports={reports} settings={settings} />}

            {!!user && !!user.roles && user.roles.includes(CASE_MANAGER_ROLE_NAME) && (
              <div className="row">
                <div className="dashboard-section appointments-dashboard-container mt-3 mb-4 col-lg-8 col-md-7 col-sm-12">
                  <div className="col-md-12 px-0">
                    <SectionHeader
                      icon="calendar-day"
                      title={`Appointments ${
                        appointmentsList.length !== appointments.length
                          ? `(${appointmentsList.length} of ${appointments.length})`
                          : ''
                      }`}
                      className={!showReports && 'text-white'}
                    />
                    <div className="row">
                      {!!appointmentsList && appointmentsList.length !== 0 ? (
                        appointmentsList.map((appointment, index) => {
                          return (
                            <AppointmentDashboardCard
                              key={`appointment-card-${index}`}
                              appointment={appointment}
                              getValue={getValue}
                              getFormattedDateTimeValue={getFormattedDateTimeValue}
                              convertUtcToLocalDateTime={convertUtcToLocalDateTime}
                              handleButtonClick={handleButtonClick}
                              cases={cases}
                              showCaseDetails={true}
                            />
                          )
                        })
                      ) : (
                        <div className="col-md-12">
                          <Card className="col-12 mb-3 shadow">
                            <Card.Body>
                              <p className="text-center">
                                <strong>No upcoming or recent appointments to complete</strong>
                              </p>
                              <button
                                type="button"
                                className="btn btn-secondary btn-block"
                                onClick={() => handleButtonClick('/appointments')}
                              >
                                Add an Appointment
                              </button>
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="dashboard-section tasks-dashboard-container mt-3 mb-4 col-lg-4 col-md-5 col-sm-12">
                  <SectionHeader icon="calendar-check" title="Tasks" className={!showReports && 'text-white-md-up'} />
                  <TaskCard
                    incompleteTasks={tasks}
                    handleButtonClick={handleButtonClick}
                    getValue={getValue}
                    cases={cases}
                    isDashboardView={true}
                  />
                </div>
              </div>
            )}
            {error && (
              <Alert variant="danger">
                <strong>Error:</strong> {error.message}
              </Alert>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </DashboardWrapper>
  )
})

function SectionHeader({ title, icon, className }) {
  return (
    <h2 className={`h5 ml-1 section-header ${className ?? ''}`}>
      <FAIcon name={icon} className={'mr-3'} />
      {title}
    </h2>
  )
}

function CaseReportsSection({ caseReports, settings }) {
  const reportsToDisplay = caseReports.slice(0, 3)

  let reportCountSuffix = ''
  if (caseReports > reportsToDisplay) {
    reportCountSuffix = `(${reportsToDisplay.length} of ${caseReports.length})`
  }

  return (
    <div className="dashboard-section">
      <SectionHeader icon="file-invoice" title={`Progress Reports ${reportCountSuffix}`} className="text-white" />
      {!caseReports || caseReports.length === 0 ? (
        <div className="card">
          <div className="card-body">
            <div className="text-center">No Reports to review</div>
          </div>
        </div>
      ) : (
        <div className="row">
          {reportsToDisplay.map((caseReport, i) => (
            <div key={i} className="col-md-4">
              <ReportDashboardCard caseReport={caseReport} settings={settings} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

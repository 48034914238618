import React from 'react'

export default ({ value, children, ...props }) => {
  return (
    <>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" checked={value} {...props} />
        <label className="form-check-label" htmlFor={props.id}>
          {children}
        </label>
      </div>
    </>
  )
}

import React, { Component } from 'react'
import { withAuth } from 'react-oidc-context'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import BottomNav from './components/BottomNav'
import ReportPrint from './components/case/ReportPrintPage'
import PrivateRoute from './components/PrivateRoute'
import Profile from './components/Profile'
import TopNav from './components/TopNav'
import CalendarPage from './containers/CalendarPage'
import CasePage from './containers/CasePage'
import Cases from './containers/Cases'
import CompleteAppointment from './containers/CompleteAppointment'
import ExportProductivityReport from './containers/ExportProductivityReport'
import Home from './containers/Home'
import IntakeForm from './containers/IntakeForm'
import Library from './containers/Library'
import LibraryActivitiesAndExpenses from './containers/LibraryActivitiesAndExpenses'
import NewAppointment from './containers/NewAppointment'
import TaskPage from './containers/TaskPage'
import TeamAdd from './containers/TeamAdd'
import TeamEdit from './containers/TeamEdit'
import TeamList from './containers/TeamList'
import { beginLoading } from './actions'

import './App.css'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDropdownToggled: false,
    }

    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.isUserSet = this.isUserSet.bind(this)
    this.props.beginLoading()

    this.AuthElement = React.createRef()
  }

  isUserSet() {
    return (
      this.props.currentUser !== null &&
      Object.keys(this.props.currentUser).length !== 0 &&
      this.props.currentUser.roles !== null
    )
  }

  handleLogout() {
    // Remove the user object from the Redux store
    const { auth } = this.props
    auth?.signoutRedirect()
  }

  handleOnSelect() {
    this.setState(prevState => {
      return { isDropdownToggled: !prevState.isDropdownToggled }
    })
  }

  HasNavigationBarsContainer = props => (
    <div className="nav-container-bottom">
      <TopNav handleLogout={this.handleLogout.bind(this)} currentUser={props.currentUser} title={props.title} />
      <div className="container">{props.component}</div>
      <BottomNav
        user={props.currentUser}
        isDropdownToggled={this.state.isDropdownToggled}
        handleOnSelect={this.handleOnSelect}
      />
    </div>
  )

  HasTopNavigationBarContainer = props => (
    <div>
      <TopNav handleLogout={this.handleLogout.bind(this)} currentUser={props.currentUser} />
      <div className="container">{props.component}</div>
    </div>
  )

  BaseContainer = props => <div className="container">{props.component}</div>

  render() {
    const { currentUser, settings, isLoading, errors } = this.props

    return (
      <div className="nav-container">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : errors?.some(e => e.message === 'User is not authorized to use this tenant') ? (
          <h3 className="text-center">You are not authorized to access this tenant.</h3>
        ) : errors?.length ? (
          errors.map((e, i) => (
            <h4 className="text-center" key={i}>
              {e.message}
            </h4>
          ))
        ) : (
          <Router>
            <Switch>
              <PrivateRoute user={currentUser} exact path="/profile">
                <this.HasNavigationBarsContainer currentUser={currentUser} component={<Profile user={currentUser} />} />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/">
                <this.HasNavigationBarsContainer
                  currentUser={currentUser}
                  component={<Home user={currentUser} settings={settings} />}
                />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/calendar">
                <this.HasNavigationBarsContainer
                  currentUser={currentUser}
                  title="CALENDAR"
                  component={<CalendarPage />}
                />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/library">
                <this.HasNavigationBarsContainer
                  currentUser={currentUser}
                  title="LIBRARY"
                  component={<Library user={currentUser} />}
                />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/library/team-members">
                <this.HasNavigationBarsContainer
                  currentUser={currentUser}
                  title="TEAM"
                  component={<TeamList currentUser={currentUser} />}
                />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/library/team-members/add">
                <this.HasNavigationBarsContainer
                  currentUser={currentUser}
                  title="ADD TEAM MEMBER"
                  component={<TeamAdd currentUser={currentUser} />}
                />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/library/team-members/:userId">
                <this.HasNavigationBarsContainer
                  currentUser={currentUser}
                  title="EDIT TEAM MEMBER"
                  component={<TeamEdit currentUser={currentUser} />}
                />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/library/activities-and-expenses">
                <this.HasNavigationBarsContainer
                  currentUser={currentUser}
                  title="ACTIVITIES AND EXPENSES"
                  component={<LibraryActivitiesAndExpenses />}
                />
              </PrivateRoute>
              <PrivateRoute user={currentUser} path="/library/team-members/:id?/productivity-report">
                <ExportProductivityReport />
              </PrivateRoute>
              <PrivateRoute user={currentUser} path="/case/intake/:id?">
                <this.BaseContainer component={<IntakeForm user={currentUser} />} />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/cases">
                <this.HasNavigationBarsContainer currentUser={currentUser} title="CASES" component={<Cases />} />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/cases/:caseNumber/appointments/:appointmentNumber?">
                <NewAppointment />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/appointments/:appointmentNumber?">
                <NewAppointment />
              </PrivateRoute>
              <PrivateRoute
                user={currentUser}
                exact
                path="/cases/:caseNumber?/appointments/:appointmentNumber?/complete"
              >
                <CompleteAppointment />
              </PrivateRoute>
              <PrivateRoute
                user={currentUser}
                exact
                path="/cases/:caseNumber/reports/:reportNumber/print"
                render={({ match }) => <ReportPrint {...match.params} settings={settings} />}
              />
              <PrivateRoute user={currentUser} exact path="/cases/:id?/tasks/:taskId?">
                <TaskPage caseManager={currentUser} />
              </PrivateRoute>
              <PrivateRoute user={currentUser} exact path="/tasks/:taskId?">
                <TaskPage caseManager={currentUser} />
              </PrivateRoute>
              <PrivateRoute
                user={currentUser}
                path="/cases/:caseNumber"
                render={({ match }) => (
                  <CasePage caseNumber={match.params.caseNumber} settings={settings} user={currentUser} />
                )}
              />
            </Switch>
          </Router>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  settings: state.settings,
  isLoggedIn: state.isLoggedIn,
  isLoading: state.isLoading,
  errors: state.errors,
})

const mapDispatchToProps = dispatch => ({
  beginLoading: () => dispatch(beginLoading()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(App))

import React from 'react'

import validation from '../../../validation'

export default {
  name: 'Functional Status',
  group: 'Case Details',
  sectionNumber: 4,
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    selfcare: validation.note('Self Care'),
    behavior: validation.note('Behavior'),
    mobility: validation.note('Mobility Status'),
    bbmanagement: validation.note('Bowel and Bladder Management'),
    nutrition: validation.note('Nutrition Program'),
    medication: validation.note('Medication Program'),
    cognition: validation.note('Cognition'),
    pain: validation.note('Pain'),
    household: validation.note('Household Management'),
    communication: validation.note('Communication'),
  })
}

function Component({ caseModel, createInputProps, getValue, setModelValue, ErrorMessage }) {
  const rows = 6

  return (
    <div>
      <h4 className="pb-3">Functional Status</h4>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.selfcare">
              Self Care
            </label>
            <textarea
              {...createInputProps('functional.selfcare')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.selfcare'} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.behavior">
              Behavior
            </label>
            <textarea
              {...createInputProps('functional.behavior')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.behavior'} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.mobility">
              Mobility Status
            </label>
            <textarea
              {...createInputProps('functional.mobility')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.mobility'} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.bbmanagement">
              Bowel and Bladder Management
            </label>
            <textarea
              {...createInputProps('functional.bbmanagement')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.bbmanagement'} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.nutrition">
              Nutrition Program
            </label>
            <textarea
              {...createInputProps('functional.nutrition')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.nutrition'} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.medication">
              Medication Program
            </label>
            <textarea
              {...createInputProps('functional.medication')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.medication'} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.cognition">
              Cognition
            </label>
            <textarea
              {...createInputProps('functional.cognition')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.cognition'} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.pain">
              Pain
            </label>
            <textarea
              {...createInputProps('functional.pain')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.pain'} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.household">
              Household Management
            </label>
            <textarea
              {...createInputProps('functional.household')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.household'} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="functional.communication">
              Communication
            </label>
            <textarea
              {...createInputProps('functional.communication')}
              className="form-control"
              rows={rows}
              maxLength={validation.maxLengths.note}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'functional.communication'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { getToken } from '../utils/getToken'

import { wrapFetch } from './FetchHelper'

export default {
  getAll,
  get,
  create,
  update,
}

function handleSuccess(result) {
  if (!result.ok) throw new Error(result)

  return result.json()
}

function handleFailure(defaultErrorMessage, err) {
  throw new Error(defaultErrorMessage || err)
}

function getAll() {
  return fetch('/api/referralsources', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve referral sources list.', err))
}

function get(id) {
  return fetch(`/api/referralsources/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve referral source.', err))
}

function create(model) {
  return wrapFetch(
    fetch('/api/referralsources', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(model),
    }),
  )
}

function update(model) {
  return wrapFetch(
    fetch('/api/referralsources', {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(model),
    }),
  )
}

import React, { useEffect, useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { useParams, withRouter } from 'react-router-dom'

import Slide from '@material-ui/core/Slide'

import Activity from '../components/Activity'
import CaseNavigationBar from '../components/case/CaseNavigationBar'
import AddButton from '../components/controls/AddButton'
import DatePicker from '../components/controls/DatePicker'
import CustomDropDown from '../components/CustomDropDown'
import Expense from '../components/Expense'
import FAIcon from '../components/FAIcon'
import NavigationBar from '../components/NavigationBar'
import createFormHelpers from '../FormHelpers'
import CasesService from '../services/CasesService'
import format from '../services/format'
import setFocusById from '../services/setFocusById'
import TasksService from '../services/TasksService'
import validation from '../validation'

import './TaskPage.scss'

const emptyTaskModel = (userId, caseId) => {
  if (new URLSearchParams(window.location.search).get('asNote')) {
    return {
      caseNumber: `${caseId || ''}`,
      complete: false,
      title: '',
      description: '',
      note: '',
      activities: [
        {
          date: new Date(),
          caseNumber: `${caseId || ''}`,
          type: '',
          time: '',
          description: '',
          userId: `${userId || ''}`,
        },
      ],
      expenses: [],
      dueDateUtc: new Date(),
    }
  } else {
    return {
      caseNumber: `${caseId || ''}`,
      complete: false,
      title: '',
      description: '',
      note: '',
      activities: [],
      expenses: [],
      dueDateUtc: new Date(),
    }
  }
}

window.counter = 0

const TaskPage = withRouter(({ history, caseManager }) => {
  const { id, taskId } = useParams()
  const asNote = new URLSearchParams(window.location.search).get('asNote') === 'true'
  const taskFormRef = useRef(null)
  const [caseModel, setCaseModel] = useState({})
  const [taskModel, setTaskModel] = useState(emptyTaskModel(caseManager.id, id))
  const [cases, setCases] = useState([])
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [showRequiredActivitiesError, setShowRequiredActivitiesError] = useState('')
  const [touched, setTouched] = useState({})

  const {
    createInputProps,
    getValue,
    setModelValue,
    setModelValues,
    handleBlur,
    ErrorMessage,
    addNestedItemToArray,
    removeNestedItemFromArray,
  } = createFormHelpers({
    touchedObject: touched,
    setTouchedFunction: setTouched,
    submitAttempted,
    validationSchema: getValidationSchema(),
  })

  function getValidationSchema() {
    return validation.object({
      title: validation.shortText('Title').required(),
      caseNumber: validation.shortText('Client').required(),
      note: validation.note('Note').nullable(),
      activities: validation.arrayOf(validation.activity('Activity', true)),
      expenses: validation.arrayOf(validation.expense('Expense', true)),
    })
  }

  const triggerFormSubmission = e => {
    e.preventDefault()
    taskFormRef.current.dispatchEvent(new Event('submit'))
  }

  useEffect(() => {
    const promises = []

    const casesPromise = CasesService.getAll(['caseNumber', 'client']).then(result => {
      const caseList = setCaseOptions(result)
      setCases(caseList)
    })
    promises.push(casesPromise)

    if (id) {
      const casePromise = CasesService.get(id).then(caseObj => {
        setCaseModel(caseObj)
      })
      promises.push(casePromise)
    }
    if (taskId) {
      const tasksPromise = TasksService.get(id, taskId).then(r => {
        // this is just some nice fluff to add an empty activity to an existing task if
        // it is being completed, and it doesn't already have activities
        if (asNote && (!r.activities || r.activities.length === 0)) {
          r.activities = [{ date: new Date(), userId: caseManager.id }]
        }

        setTaskModel(r)
      })
      promises.push(tasksPromise)
    } else {
      setIsLoading(false)
    }

    Promise.all(promises)
      .then(() => {
        setIsLoading(false)
      })
      .catch(err => {
        setError(err.message)
        setIsLoading(false)
      })

    // eslint-disable-next-line
  }, []);

  const areFieldsValid = () => {
    const result = validation.validate(taskModel, getValidationSchema())

    return !!result && result.success
  }

  const checkIfComplete = task => {
    if (new URLSearchParams(window.location.search).get('asNote')) {
      if (getValue('activities', [], task).length >= 1) {
        return true
      } else {
        setShowRequiredActivitiesError(true)
        return false
      }
    }
    return false
  }

  const handleClickExit = () => {
    history.goBack()
  }

  const handleClickSave = () => {
    setSubmitAttempted(true)
    const caseNumber = getValue('caseNumber', '', taskModel)

    if (!!caseNumber && areFieldsValid()) {
      const updatedTask = taskModel
      if (!checkIfComplete(taskModel) && new URLSearchParams(window.location.search).get('asNote')) {
        return
      }

      if (checkIfComplete(taskModel) && new URLSearchParams(window.location.search).get('asNote')) {
        updatedTask.complete = true
      }

      setIsLoading(true)
      ;(() => {
        if (taskId) {
          return TasksService.update(updatedTask, caseNumber, taskId)
        } else {
          return TasksService.create(updatedTask, caseNumber)
        }
      })()
        .then(() => {
          setError(null)
          handleClickExit()
          setIsLoading(false)
        })
        .catch(() => {
          setModelValue('complete', false, taskModel, setTaskModel)
          setError('An unexpected error occurred while trying to save the task.')
          setIsLoading(false)
        })
    } else {
      const hasActivities = getValue('activities', [], taskModel).length !== 0

      if (!hasActivities && new URLSearchParams(window.location.search).get('asNote')) {
        setShowRequiredActivitiesError(true)
      }
    }
  }

  const handleClientSelectChange = e => {
    setModelValue(e.target.name, e.target.value, taskModel, setTaskModel)
  }

  const expenseOnChangeHandler = (e, namePrefix) => {
    const modelPaths = [{ name: e.target.name, value: e.target.value }]

    if (e.target.name.includes('type') && e.target.value === 'Mileage') {
      modelPaths.push({ name: `${namePrefix}.amount`, value: 0 })
    } else if (e.target.name.includes('type')) {
      modelPaths.push({ name: `${namePrefix}.distance`, value: 0 })
    }

    setModelValues(modelPaths, taskModel, setTaskModel)
  }

  const handleAddExpense = () => {
    const expenses = getValue('expenses', [], taskModel)
    const nextIndex = expenses.length

    setFocusById(`expenses[${nextIndex}].type`)
    addNestedItemToArray(
      'expenses',
      { date: new Date(), type: '', amount: 0, distance: 0, description: '', userId: caseManager.id },
      taskModel,
      setTaskModel,
    )
  }

  const handleAddActivity = () => {
    const activities = getValue('activities', [], taskModel)
    const nextIndex = activities.length

    setFocusById(`activities[${nextIndex}].type`)
    addNestedItemToArray(
      'activities',
      { date: new Date(), type: '', time: '', description: '', userId: caseManager.id },
      taskModel,
      setTaskModel,
    )
  }

  function setCaseOptions(caseList) {
    const parsedCases = caseList
      ? caseList.map(caseObj => ({
          id: getValue('caseNumber', '', caseObj),
          name: `${getValue('caseNumber', '', caseObj)} - ${getValue('client.firstName', '', caseObj)} ${getValue(
            'client.lastName',
            '',
            caseObj,
          )}`,
        }))
      : []

    setCases(parsedCases)
    if (!taskId) {
      setFocusById('title')
    }

    return parsedCases
  }

  return (
    <Slide className="container-case-page background" direction="up" in={true} mountOnEnter unmountOnExit>
      <form ref={taskFormRef} className="new-task-container" onSubmit={handleClickSave}>
        {asNote || id !== undefined ? (
          <CaseNavigationBar
            title={`${getValue('client.firstName', '', caseModel).toUpperCase()} ${getValue(
              'client.lastName',
              '',
              caseModel,
            ).toUpperCase()}`}
            subtitle={`CASE NO: ${id}`}
            rightButtonText={'SAVE'}
            handleClickLeft={handleClickExit}
            handleRightClick={triggerFormSubmission}
            shadow="shadow"
          />
        ) : (
          <NavigationBar
            title="NEW TASK"
            rightBtnText="SAVE"
            leftBtnText={<FAIcon type="fa fa-lg close-icon" name={'times'} />}
            handleClickLeft={handleClickExit}
            handleClickRight={triggerFormSubmission}
          />
        )}
        {!isLoading ? (
          <React.Fragment>
            <div className="new-task-container">
              {!!error && (
                <Alert variant="danger" className="mb-3 pr-5 pl-5">
                  {getValue('activities', [], taskModel).length === 0 ||
                  getValue('note', '', taskModel) === '' ? null : (
                    <strong>Error: </strong>
                  )}
                  <pre className="mb-0">{error}</pre>
                </Alert>
              )}
              <div className="container">
                <div>
                  {taskId ? (
                    <React.Fragment>
                      <h4 className="pb-3 header-title">{getValue('title', '', taskModel)}</h4>
                      <div id="dateCreated" className="date-created">
                        Created on {format.shortDateTime(getValue('createdUtc', '', taskModel))}
                      </div>
                      <div>Due on {format.date(getValue('dueDateUtc', '', taskModel), false)}</div>
                    </React.Fragment>
                  ) : null}
                </div>
                {taskId ? null : (
                  <div className="form-row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label mt-2" htmlFor="title">
                          Title
                        </label>
                        <input
                          className="form-control"
                          id="title"
                          {...createInputProps({ name: 'title', model: taskModel, setModelFunction: setTaskModel })}
                        />
                        <ErrorMessage name="title" model={taskModel} />
                        <label className="form-label mt-2" htmlFor="title">
                          Due Date
                        </label>
                        <DatePicker
                          {...createInputProps({
                            name: 'dueDateUtc',
                            model: taskModel,
                            setModelFunction: setTaskModel,
                          })}
                        />
                        <ErrorMessage name="due-date" model={taskModel} />
                      </div>
                    </div>
                  </div>
                )}
                {taskId === undefined || taskId === '' ? (
                  <div className="form-row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="form-label mt-2" htmlFor="caseNumber">
                          Client
                        </label>
                        <CustomDropDown
                          id="caseNumber"
                          name="caseNumber"
                          value={id || getValue('caseNumber', null, taskModel)}
                          isDisabled={!!id}
                          className="drop-down-container"
                          items={cases}
                          placeholderText="Search Clients"
                          noOptionsMessage="No results found"
                          onChange={handleClientSelectChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="caseNumber" model={taskModel} />
                      </div>
                    </div>
                  </div>
                ) : null}
                <hr className="subsection-divider" />
                <div>
                  <h5 className="pb-3 header-title">Time</h5>
                </div>
                {getValue('activities', [], taskModel).length > 0 && (
                  <div className="form-row hide-sm-down">
                    <div className="form-label col-md-2">Date</div>
                    <div className="form-label col-md-2">Type of Activity</div>
                    <div className="form-label col-md-2">Time</div>
                    <div className="form-label col-md-6">Description</div>
                  </div>
                )}
                <div className="position-relative">
                  {getValue('activities', [], taskModel).map((x, index) => {
                    const namePrefix = `activities[${index}]`
                    return (
                      <Activity
                        namePrefix={namePrefix}
                        key={index}
                        index={index}
                        model={taskModel}
                        setModelFunction={setTaskModel}
                        createInputProps={createInputProps}
                        ErrorMessage={ErrorMessage}
                        handleRemove={() => removeNestedItemFromArray('activities', index, taskModel, setTaskModel)}
                      />
                    )
                  })}
                  <AddButton
                    id="addActivityButton"
                    className={getValue('activities', [], taskModel).length > 0 ? 'inline-add-button' : ''}
                    onClick={handleAddActivity}
                  >
                    Add Activity
                  </AddButton>
                  {!!showRequiredActivitiesError && (
                    <div className={'text-danger form-text'}>At least one activity must be added</div>
                  )}
                </div>
                <hr className="subsection-divider" />
                <div className="form-row">
                  <div className="col-md-8">
                    <div className="form-group">
                      <label className="form-label" htmlFor="note">
                        <h5 className="header-title">Notes</h5>
                      </label>
                      <textarea
                        className="form-control"
                        {...createInputProps({ name: 'note', model: taskModel, setModelFunction: setTaskModel })}
                        rows="3"
                      />
                      <ErrorMessage name={'note'} model={taskModel} />
                    </div>
                  </div>
                </div>
                <hr className="subsection-divider" />
                <div>
                  <h5 className="pb-3 header-title">Expenses</h5>
                </div>
                {getValue('expenses', [{}], taskModel).length > 0 && (
                  <div className="form-row hide-sm-down">
                    <div className="form-label col-md-2">Date</div>
                    <div className="form-label col-md-2">Type of Expense</div>
                    <div className="form-label col-md-2">Amount</div>
                    <div className="form-label col-md-2">Distance</div>
                    <div className="form-label col-md-4">Description</div>
                  </div>
                )}
                <div className="position-relative">
                  {getValue('expenses', [], taskModel).map((x, index) => {
                    const namePrefix = `expenses[${index}]`
                    const expenseIsMileage = getValue(`expenses[${index}].type`, '', taskModel) === 'Mileage'
                    return (
                      <Expense
                        namePrefix={namePrefix}
                        key={index}
                        createInputProps={createInputProps}
                        onChangeHandler={expenseOnChangeHandler}
                        model={taskModel}
                        setModelFunction={setTaskModel}
                        ErrorMessage={ErrorMessage}
                        getValue={getValue}
                        expenseIsMileage={expenseIsMileage}
                        handleRemove={() => removeNestedItemFromArray('expenses', index, taskModel, setTaskModel)}
                      />
                    )
                  })}
                  <AddButton
                    id="addExpenseButton"
                    className={getValue('expenses', [{}], taskModel).length > 0 ? 'inline-add-button' : ''}
                    onClick={handleAddExpense}
                  >
                    Add Expense
                  </AddButton>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </form>
    </Slide>
  )
})

export default TaskPage

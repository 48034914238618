import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import InProgressReport from './CaseReport/InProgressReport'
import Report from './CaseReport/Report'
import ReportsListPage from './CaseReport/ReportsListPage'

export default function ({ ...props }) {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/in-progress`} render={() => <InProgressReport {...props} />} />
      <Route path={`${path}/:reportNumber`} render={({ match }) => <Report {...match.params} {...props} />} />
      <Route path={`${path}`} render={() => <ReportsListPage {...props} />} />
    </Switch>
  )
}

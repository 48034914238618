import React from 'react'
import { Modal } from 'react-bootstrap'

import AdjustersService from '../../services/AdjustersService'

import NewAdjuster from './NewAdjuster'

export default ({ onSaveSuccess, onSave, onClosed, oldModel }) => {
  const handleSave = (model, isUpdate) => {
    if (onSave) {
      return onSave(model, isUpdate)
    }

    if (isUpdate) {
      return AdjustersService.update(model)
    } else {
      return AdjustersService.create(model)
    }
  }

  return (
    <Modal show={true} size="md" onHide={() => {}}>
      <Modal.Body>
        <NewAdjuster onSave={handleSave} onSaveSuccess={onSaveSuccess} onClose={onClosed} oldModel={oldModel} />
      </Modal.Body>
    </Modal>
  )
}

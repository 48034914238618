import React from 'react'

import get from 'lodash/fp/get'
import Moment from 'moment'
import { set } from 'object-path-immutable'

import validation from './validation'

export default function createFormHelpers({ touchedObject, setTouchedFunction, submitAttempted, validationSchema }) {
  return {
    createInputProps,
    getValue,
    setModelValue,
    setModelValues,
    handleBlur,
    getUtcDateTime,
    isValidTimeRange,
    getDateTimeErrors,
    ErrorMessage: errorMessage,
    removeNestedItemFromArray,
    addNestedItemToArray,
    removeItemFromArray,
    addItemToArray,
  }

  function createInputProps({ name, model, setModelFunction, onChangeHandler = null, defaultValue = '' }) {
    return {
      id: name,
      name,
      value: getValue(name, defaultValue, model),
      onChange: e => (onChangeHandler ? onChangeHandler(e) : defaultOnChangeHandler(e, name, model, setModelFunction)),
      onBlur: e => handleBlur(name),
    }
  }

  function defaultOnChangeHandler(e, name, model, setModelFunction) {
    const value = (() => {
      switch (e.target.type) {
        case 'checkbox':
          return e.target.checked
        default:
          return e.target.value
      }
    })()
    setModelValue(name, value, model, setModelFunction)
  }

  function setModelValue(name, value, model, setModelFunction) {
    const path = name.replace('[', '.').replace(']', '') // change array to a format object-path recognizes
    const newModel = set(model, path, value)
    setModelFunction(newModel)
  }

  function setModelValues(modelPaths, model, setModelFunction) {
    let newModel = model

    modelPaths.forEach(modelPath => {
      const path = modelPath.name.replace('[', '.').replace(']', '') // change array to a format object-path recognizes
      newModel = set(newModel, path, modelPath.value)
    })

    setModelFunction(newModel)
  }

  function getValue(name, defaultValue, model) {
    if (typeof model === 'undefined') {
      defaultValue = ''
      model = defaultValue
    }
    if (defaultValue != null) {
      return get(name, model) || defaultValue
    } else {
      return get(name, model)
    }
  }

  function handleBlur(name) {
    setTouchedFunction({
      ...touchedObject,
      [name]: true,
    })
  }

  function errorMessage({ name, model, className, ...props }) {
    // If the field hasn't been touched yet and submit hasn't been attempted,
    // then don't show any errors
    if (!touchedObject[name] && !submitAttempted) return null

    // Check if the fields are valid
    const validationResult = validation.validate(model, validationSchema)

    if (!validationResult.errors) return null

    const msg = validationResult.errors[name]
    if (!msg) return null

    return (
      <div className={`text-danger form-text ${className}`} {...props}>
        {msg}
      </div>
    )
  }

  function getDateTimeErrors(dateTimeModel) {
    const date = getValue('date', null, dateTimeModel)
    const startTime = getValue('startTime', null, dateTimeModel)
    const endTime = getValue('endTime', null, dateTimeModel)

    const errors = {
      errorMessage: null,
      requiredErrorMessage: null,
    }

    // Check for invalid time ranges
    if (!!startTime && !!endTime && !isValidTimeRange(startTime, endTime)) {
      errors.errorMessage = 'Invalid time range selected.'
    }

    // Check for missing fields
    // Show the required error message if a date field was touched but a value wasn't selected, or
    // if the submit button was clicked and values are missing
    if (
      (touchedObject.date && !date) ||
      (touchedObject.startTime && !startTime) ||
      (touchedObject.endTime && !endTime) ||
      (submitAttempted && (!date || !startTime || !endTime))
    ) {
      errors.requiredErrorMessage = 'Date, start time, and end time are required fields.'
    }

    return errors
  }

  function getUtcDateTime(timeName, model) {
    if (!!getValue(timeName, null, model) && !!getValue('date', null, model)) {
      const date = Moment(getValue('date', null, model)).format('YYYY-MM-DD')
      const time = Moment(getValue(timeName, null, model)).format('HH:mm:ss')

      const dateTime = Moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss').format()
      return Moment.utc(dateTime).format()
    } else {
      return null
    }
  }

  function isValidTimeRange(startTime, endTime) {
    if (!!startTime && endTime) {
      const startDateTime = new Date(startTime)
      const endDateTime = new Date(endTime)

      return (
        startDateTime.getTime() < endDateTime.getTime() && startDateTime.toUTCString() !== endDateTime.toUTCString()
      )
    } else {
      return false
    }
  }

  function removeNestedItemFromArray(name, index, model, setModelFunction) {
    const orig = getValue(name, [], model)
    const newArray = [...orig.slice(0, index), ...orig.slice(index + 1)]
    setModelValue(name, newArray, model, setModelFunction)
  }

  function addNestedItemToArray(name, item, model, setModelFunction) {
    const newArray = getValue(name, [], model).concat(item)
    setModelValue(name, newArray, model, setModelFunction)
    return newArray.length - 1
  }

  function removeItemFromArray(index, origArray, setModelFunction) {
    if (origArray) {
      const newArray = [...origArray.slice(0, index), ...origArray.slice(index + 1)]
      setModelFunction(newArray)
    }
  }

  function addItemToArray(item, origArray, setModelFunction) {
    const newArray = origArray.concat(item)
    setModelFunction(newArray)
    return newArray.length - 1
  }
}

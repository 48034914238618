/*

Fetch calls always make us check for response.ok and need special
handling of the 400 errors coming back from the server.
This is to try to make it easier to handle these.

Code using this helper should look like this:

wrapFetch(fetch('/some-url'))
  .onSuccess(json => {
    // yay!
  })
  .onBadRequest((err, json) => {
    // err will be a multi-line message. json will be the error json body returned
  })
  .onStatus(413, () => {
    // handle request too large
  })
  .catch(err => {
    // something unexpected
  });

*/

function wrapFetch(promise) {
  let onSuccessHandler = response => response
  let onBadRequestHandler

  const onStatusHandlers = {}

  const promise2 = promise.then(async response => {
    if (onStatusHandlers[response.status]) {
      return onStatusHandlers[response.status](response)
    }

    if (response.ok) {
      const json = await response.json()
      if (onSuccessHandler) return onSuccessHandler(json)
    } else if (response.status === 400) {
      // https://docs.microsoft.com/en-us/aspnet/core/web-api/?view=aspnetcore-3.0#default-badrequest-response
      const json = await response.json()
      const errorMsg = convertApiBadRequestBodyToMessage(json)
      if (onBadRequestHandler) {
        return onBadRequestHandler(errorMsg, json)
      } else {
        const error = new Error('Bad request')
        error.response = response
        throw error
      }
    } else {
      const error = new Error('Unexpected response.')
      error.response = response
      throw error
    }
  })

  promise2.onSuccess = handler => {
    onSuccessHandler = handler
    return promise2
  }
  promise2.onBadRequest = handler => {
    onBadRequestHandler = handler
    return promise2
  }

  const registerHandlerForStatus = (status, handler) => {
    onStatusHandlers[status] = handler
    return promise2
  }

  promise2.onStatus = registerHandlerForStatus

  return promise2
}

function convertApiBadRequestBodyToMessage(json) {
  let msg = `${json.title}\n`
  Object.keys(json.errors).forEach(prop => {
    const errors = json.errors[prop]
    errors.forEach(error => {
      msg += `${prop}: ${error}\n`
    })
  })

  return msg
}

export { wrapFetch }

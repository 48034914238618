import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import FAIcon from '../components/FAIcon'
import format from '../services/format'

import './TaskCard.scss'

const TaskCard = ({
  incompleteTasks = [],
  completeTasks = [],
  cases,
  getValue,
  caseNumber,
  handleButtonClick,
  isDashboardView = false,
}) => {
  const getFormattedDateValue = dateUtc => {
    if (dateUtc) {
      return format.date(dateUtc)
    } else {
      return ''
    }
  }

  function getClientInfo(task) {
    // eslint-disable-next-line
    var fullName = cases.map((caseObj) => {
      if (caseObj.caseNumber === task.caseNumber) {
        return `${getValue('client.firstName', '', caseObj)} ${getValue('client.lastName', '', caseObj)}`
      } else {
        return ''
      }
    })
    return (
      <p className="text-truncate">
        {fullName} <span>&#183;</span> Case #: {task.caseNumber}
      </p>
    )
  }

  const orderedIncompleteTasks = incompleteTasks?.sort((a, b) => {
    return new Date(a.dueDateUtc) - new Date(b.dueDateUtc)
  })

  const orderedCompleteTasks = completeTasks?.sort((a, b) => {
    return new Date(b.dueDateUtc) - new Date(a.dueDateUtc)
  })

  return (
    <React.Fragment>
      {orderedIncompleteTasks.length >= 1 ? (
        <Card className="shadow task-card">
          <Card.Body className="p-0">
            {incompleteTasks.map((task, index) => {
              return (
                <div key={index} className="task cardrow">
                  <Link to={`/cases/${task.caseNumber}/tasks/${task.taskNumber}?asNote=true`}>
                    <div className="d-flex">
                      <div className="row w-100 m-0">
                        <div className="inline-circle align-self-center col-1">
                          <FAIcon name="circle" type="far fa-md check-box" />
                        </div>
                        {isDashboardView ? (
                          <React.Fragment>
                            <div className="col-7">
                              <div className="title-text text-truncate">{task.title}</div>
                              <div className="client-case-line">{getClientInfo(task)}</div>
                            </div>
                            <div className="col-3">
                              <div className="date text-primary text-align-right">
                                <i>{getFormattedDateValue(task.dueDateUtc)}</i>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className="col-7">
                              <div className="title-text text-truncate">{task.title}</div>
                            </div>
                            <div className="col-3">
                              <div className="date text-primary text-align-right">
                                <i>{getFormattedDateValue(task.dueDateUtc)}</i>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
            {orderedCompleteTasks.map((task, index) => {
              return (
                <div key={index} className="task cardrow">
                  <Link to={`/cases/${task.caseNumber}/tasks/${task.taskNumber}?asNote=true`}>
                    <div className="d-flex">
                      <div className="row w-100 m-0">
                        <div className="inline-circle align-self-center col-1">
                          <FAIcon name="check-circle" type="far fa-md check-box" />
                        </div>
                        <React.Fragment>
                          <div className="col-7">
                            <div className="title-text text-truncate">
                              <s>{task.title}</s>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="date text-primary text-align-right">
                              <i>
                                <s>{getFormattedDateValue(task.dueDateUtc)}</s>
                              </i>
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </Card.Body>
        </Card>
      ) : (
        <Card className="shadow task-card-container mb-3">
          <Card.Body>
            <p className="text-center">
              <strong>No current tasks to complete</strong>
            </p>
            <button
              type="button"
              className="btn btn-secondary btn-block"
              onClick={() => handleButtonClick(caseNumber ? `/cases/${caseNumber}/tasks` : '/tasks')}
            >
              Add a Task
            </button>
          </Card.Body>
        </Card>
      )}
    </React.Fragment>
  )
}

export default TaskCard

export const FIELD_NORMAL_LENGTH = 225

export const MAX_FIELD_LENGTH = 2048

export const SUGGESTED_FIELD_LENGTH = 1250

export const COGNITO_POOL_ID = 'us-east-2_jVPYX9Eex'
export const COGNITO_REGION = 'us-east-2'
export const COGNITO_CLIENT_ID = '5tr8vruucj4g5u2rm02le91rvu'
export const COGNITO_AUTHORITY = `https://cognito-idp.${COGNITO_REGION}.amazonaws.com/${COGNITO_POOL_ID}`
export const COGNITO_SCOPES = 'aws.cognito.signin.user.admin email openid phone profile'

export const INVOICER_ROLE_NAME = 'invoicer'

export const PEER_REVIEWER_ROLE_NAME = 'peer-reviewer'

export const SERVICE_PROVIDER_ROLE_NAME = 'service-provider'

export const CLAIM_REP_ROLE_NAME = 'claim-rep'

export const SOCIAL_WORKER_ROLE_NAME = 'social-worker'

export const CASE_MANAGER_ROLE_NAME = 'case-manager'

export const CASE_ADMIN_ROLE_NAME = 'case-admin'

export const ADMIN_ROLE_NAME = 'admin'

export const ROLE_NAMES = [
  { key: INVOICER_ROLE_NAME, label: 'Invoicer' },
  { key: PEER_REVIEWER_ROLE_NAME, label: 'Peer Reviewer' },
  { key: SERVICE_PROVIDER_ROLE_NAME, label: 'Service Provider' },
  { key: CLAIM_REP_ROLE_NAME, label: 'Claims Rep' },
  { key: SOCIAL_WORKER_ROLE_NAME, label: 'Social Worker' },
  { key: CASE_MANAGER_ROLE_NAME, label: 'Case Manager' },
  { key: CASE_ADMIN_ROLE_NAME, label: 'Case Admin' },
  { key: ADMIN_ROLE_NAME, label: 'Admin' },
]

import React from 'react'

import setFocusById from '../../../services/setFocusById'
import validation from '../../../validation'
import AddButton from '../../controls/AddButton'
import CheckBox from '../../controls/CheckBox'
import DatePicker from '../../controls/DatePicker'
import RemoveButton from '../../controls/RemoveButton'

import './MedicationsSection.scss'

export default {
  name: 'Medications/Allergies',
  group: 'Case Details',
  validationSchema: getValidationSchema(),
  Component,
}

// shamelessly using function hoisting for code clarity

function getValidationSchema() {
  return validation.object({
    medications: validation.arrayOf(
      validation.object({
        name: validation.name('Medication Name').required(),
        dosage: validation.shortText('Dosage'),
        frequency: validation.shortText('Frequency'),
        reason: validation.shortText('Reason'),
        dateStarted: validation.date('Date Started').nullable(),
        dateDiscontinued: validation.date('Date Discontinued').nullable(),
        pharmacy: validation.shortText('Pharmacy'),
      }),
    ),
    allergies: validation.arrayOf(
      validation.object({
        name: validation.name('Allergy Name').required(),
        reaction: validation.shortText('Reaction'),
      }),
    ),
  })
}

function Component({ addItemToArray, removeItemFromArray, createInputProps, getValue, ErrorMessage }) {
  const medications = getValue('medications', [])
  const allergies = getValue('allergies', [])

  return (
    <div className="MedicationsSection-component">
      <h4 className="pb-3">Medications</h4>
      {medications.map((x, index) => {
        const namePrefix = `medications[${index}]`
        return (
          <React.Fragment key={namePrefix}>
            <Medication
              namePrefix={namePrefix}
              createInputProps={createInputProps}
              getValue={getValue}
              ErrorMessage={ErrorMessage}
              handleRemove={() => removeItemFromArray('medications', index)}
            />
            <hr />
          </React.Fragment>
        )
      })}
      <AddButton
        id="addMedicationButton"
        onClick={() => {
          addItemToArray('medications', {})
          setFocusById(`medications[${medications.length}].name`)
        }}
      >
        Add Medication
      </AddButton>
      <h4 className="pb-3 mt-5">Allergies</h4>
      {allergies.length > 0 && (
        <div className="form-row hide-sm-down">
          <div className="form-label col-md-6">Allergy Name</div>
          <div className="form-label col-md-6">Reaction</div>
        </div>
      )}
      {allergies.map((x, index) => {
        const namePrefix = `allergies[${index}]`
        return (
          <Allergy
            key={namePrefix}
            namePrefix={namePrefix}
            createInputProps={createInputProps}
            ErrorMessage={ErrorMessage}
            handleRemove={() => removeItemFromArray('allergies', index)}
          />
        )
      })}
      <AddButton
        id="addAllergyButton"
        onClick={() => {
          setFocusById(`allergies[${allergies.length}].name`)
          addItemToArray('allergies', {})
        }}
      >
        Add Allergy
      </AddButton>
    </div>
  )
}

function Medication({ namePrefix, createInputProps, ErrorMessage, handleRemove, getValue }) {
  const medication = getValue(namePrefix)

  return (
    <div className={`clearfix ${medication.dateDiscontinued ? 'discontinued' : ''}`}>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={`${namePrefix}.name`}>
            Medication Name
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.name}
            {...createInputProps(`${namePrefix}.name`)}
          />
        </div>
        <div className="form-group col-md-3">
          <label className="form-label" htmlFor={`${namePrefix}.dosage`}>
            Dosage
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.shortText}
            {...createInputProps(`${namePrefix}.dosage`)}
          />
        </div>
        <div className="form-group col-md-3">
          <label className="form-label" htmlFor={`${namePrefix}.frequency`}>
            Frequency
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.shortText}
            {...createInputProps(`${namePrefix}.frequency`)}
          />
        </div>
        <ErrorMessage name={`${namePrefix}.name`} />
        <ErrorMessage name={`${namePrefix}.dosage`} />
        <ErrorMessage name={`${namePrefix}.frequency`} />
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={`${namePrefix}.reason`}>
            Reason
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.shortText}
            {...createInputProps(`${namePrefix}.reason`)}
          />
        </div>
        <div className="form-group col-md-3">
          <label className="form-label" htmlFor={`${namePrefix}.dateStarted`}>
            Date Started
          </label>
          <DatePicker {...createInputProps(`${namePrefix}.dateStarted`)} />
        </div>
        <div className="form-group col-md-3">
          <label className="form-label" htmlFor={`${namePrefix}.dateDiscontinued`}>
            Date Discontinued
          </label>
          <DatePicker {...createInputProps(`${namePrefix}.dateDiscontinued`)} />
        </div>
        <ErrorMessage name={`${namePrefix}.reason`} />
        <ErrorMessage name={`${namePrefix}.dateStarted`} />
        <ErrorMessage name={`${namePrefix}.dateDiscontinued`} />
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={`${namePrefix}.pharmacy`}>
            Pharmacy
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.shortText}
            {...createInputProps(`${namePrefix}.pharmacy`)}
          />
        </div>
        <div className="form-group align-center mt-4 col-6 col-md-3">
          <CheckBox {...createInputProps(`${namePrefix}.relatedToInjury`)}>Related to Injury</CheckBox>
        </div>
        <div className="form-group align-center mt-4 col-6 col-md-3">
          <RemoveButton id={`${namePrefix}RemoveBtn`} className={'float-right'} onClick={() => handleRemove()}>
            Remove
          </RemoveButton>
        </div>
        <ErrorMessage name={`${namePrefix}.pharmacy`} />
      </div>
    </div>
  )
}

function Allergy({ namePrefix, createInputProps, ErrorMessage, handleRemove }) {
  return (
    <div className="allergy clearfix">
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor={`${namePrefix}.name`} className="form-label d-md-none">
            Allergy Name
          </label>
          <input
            type="text"
            maxLength={validation.maxLengths.name}
            className="form-control"
            {...createInputProps(`${namePrefix}.name`)}
          />
          <ErrorMessage name={`${namePrefix}.name`} />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor={`${namePrefix}.reaction`} className="form-label d-md-none">
            Reaction
          </label>
          <div className="d-flex">
            <input
              type="text"
              maxLength={validation.maxLengths.shortText}
              className="form-control allergy-reaction"
              {...createInputProps(`${namePrefix}.reaction`)}
            />
            <RemoveButton
              id={`${namePrefix}RemoveBtn`}
              className="hide-sm-down"
              onClick={() => handleRemove()}
            ></RemoveButton>
          </div>
          <ErrorMessage name={`${namePrefix}.reaction`} />
        </div>
      </div>
    </div>
  )
}

import React from 'react'

import AttorneyFormDefendantSection from './AttorneyFormDefendantSection'
import AttorneyFormPlaintiffSection from './AttorneyFormPlaintiffSection'

const AttorneyForm = ({ caseModel, handleFieldChange, setFieldValue }) => {
  return (
    <React.Fragment>
      <AttorneyFormPlaintiffSection
        caseModel={caseModel}
        handleFieldChange={handleFieldChange}
        setFieldValue={setFieldValue}
      />
      <AttorneyFormDefendantSection
        caseModel={caseModel}
        handleFieldChange={handleFieldChange}
        setFieldValue={setFieldValue}
      />
    </React.Fragment>
  )
}

export default AttorneyForm

import axios from 'axios'

import { getToken } from '../../utils/getToken'

import AxiosDefaultConfig from './AxiosRequestConfig'

/** @typedef { import('axios').AxiosInstance } AxiosInstance */
/** @typedef { import('axios').InternalAxiosRequestConfig } InternalAxiosRequestConfig */

/**
 * Represents an API client.
 */
export default class ApiClient {
  /**
   * The Axios instance used for making HTTP requests.
   * @type {AxiosInstance}
   */
  instance

  /**
   * Creates an instance of ApiClient.
   */
  constructor() {
    /**
     * The Axios instance with default configuration.
     */
    const instance = axios.create({
      ...AxiosDefaultConfig,
    })

    instance.interceptors.request.use(
      /**
       * Interceptor function to add authorization token to the request headers.
       * @param {InternalAxiosRequestConfig} config - The Axios request configuration.
       * @returns {Promise<InternalAxiosRequestConfig>} The modified Axios request configuration.
       */
      async config => {
        const token = await getCurrentAuthToken()

        if (token) {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${token}`,
            },
          }
        }

        return config
      },
      /**
       * Error handler for the interceptor.
       * @param {any} error - The intercepted error.
       * @returns {Promise<never>} A rejected promise with the intercepted error.
       */
      error => Promise.reject(error),
    )

    this.instance = instance
  }
}

/**
 * Retrieves the current authentication token.
 * @returns {Promise<string>} A promise that resolves to the authentication token.
 */
async function getCurrentAuthToken() {
  const token = getToken()
  return token ? Promise.resolve(token) : Promise.reject(new Error('No token available'))
}

import * as types from '../actions'

const initialState = {
  currentUser: {},
  currentUserIsLoading: true,
  settings: {},
  isLoading: true,
  settingsIsLoading: true,
  errors: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_USER_PROFILE_BEGIN:
      return {
        ...state,
        currentUser: {},
        currentUserIsLoading: true,
        isLoading: true,
      }
    case types.GET_USER_PROFILE_SUCCESS:
      return setIsLoading({
        ...state,
        currentUser: action.payload,
        currentUserIsLoading: false,
      })
    case types.GET_USER_PROFILE_FAILURE:
      return setIsLoading({
        ...state,
        errors: state.errors.concat(action.error),
        currentUserIsLoading: false,
      })
    case types.GET_SETTINGS_BEGIN:
      return {
        ...state,
        settings: null,
        settingsIsLoading: true,
        isLoading: true,
      }
    case types.GET_SETTINGS_SUCCESS:
      return setIsLoading({
        ...state,
        settings: action.settings,
        settingsIsLoading: false,
      })
    case types.GET_SETTINGS_FAILURE:
      return setIsLoading({
        ...state,
        settings: {},
        settingsIsLoading: false,
        errors: state.errors.concat(action.error),
      })

    default:
      return state
  }
}

function setIsLoading(state) {
  return {
    ...state,
    isLoading: state.settingsIsLoading || state.currentUserIsLoading,
  }
}

import React from 'react'

import './CaseManagerForm.scss'

const ResourcesForm = ({ caseModel, setFieldValue, caseManagers }) => {
  return (
    <div className="case-managers-form-container">
      <h4 className="mb-4 mt-4"> Assign a Case Manager </h4>
      <div className="card border-light">
        <table className="table mb-0">
          <thead>
            <tr>
              <th scope="col">NAME</th>
              <th className="d-none d-md-table-cell" scope="col">
                REGION
              </th>
              <th className="d-none d-lg-table-cell" scope="col">
                LANGUAGE(S)
              </th>
              <th className="d-none d-xl-table-cell" scope="col">
                QUALIFICATIONS
              </th>
              <th scope="col">
                <div className="d-none d-sm-block text-center">AVAILABILITY</div>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!!caseManagers &&
              caseManagers.map(caseManager => {
                const isCaseManagerSelected = !!caseModel.caseManager && caseModel.caseManager === caseManager.id
                const isAvailable = true
                return (
                  <tr key={caseManager.id}>
                    <th scope="row">
                      <div className="column-wrapper">{`${caseManager.firstName} ${caseManager.lastName}`}</div>
                    </th>
                    <td className="d-none d-md-table-cell">
                      <div className="column-wrapper">{caseManager.region}</div>
                    </td>
                    <td className="d-none d-lg-table-cell">
                      <div className="column-wrapper">
                        {caseManager.languages ? caseManager.languages.toString() : ''}
                      </div>
                    </td>
                    <td className="d-none d-xl-table-cell">
                      <div className="column-wrapper">
                        {caseManager.qualifications ? caseManager.qualifications.split(';').join(', ') : ''}
                      </div>
                    </td>
                    <td className="text-center">
                      <i className={`text-primary fa-lg ${isAvailable ? 'far fa-check-circle' : 'fas fa-ban'}`}></i>
                    </td>
                    <td className="text-center">
                      <button
                        type="button"
                        disabled={!isAvailable}
                        className={`btn btn-select ${isCaseManagerSelected ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={e =>
                          setFieldValue('caseManager', caseManager.id !== caseModel.caseManager ? caseManager.id : null)
                        }
                      >
                        {isCaseManagerSelected ? 'Selected' : 'Select'}
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
        {(!caseManagers || caseManagers.length === 0) && (
          <div className="empty-table-message-container">
            <h6 className="mb-0">No case managers have been added</h6>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResourcesForm

/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useParams, withRouter } from 'react-router-dom'

import UserService from '../services/UserService'
import { ADMIN_ROLE_NAME, ROLE_NAMES } from '../utils/Constants'

export default withRouter(({ history, currentUser }) => {
  const { userId } = useParams()
  const [user, setUser] = useState()
  const [allRoles, setAllRoles] = useState([])
  const [availableRoles, setAvailableRoles] = useState([])

  useEffect(() => {
    if (userId) {
      ;(async () => {
        setUser(await UserService.get(userId))
      })()
    }
  }, [setUser, userId])

  useEffect(() => {
    setAllRoles(ROLE_NAMES)
  }, [setAllRoles])

  useEffect(() => {
    const roles = [...allRoles.filter(r => !user?.roles.some(sr => sr === r.key))]
    setAvailableRoles(roles)
  }, [allRoles, user?.roles, setAvailableRoles])

  const handleOnRoleSelected = async e => {
    const value = e.target.value
    if (value) {
      const user = await UserService.addRoles(userId, [value])
      setUser(user)
    }
  }

  const handleRoleRemoval = async roleId => {
    if (roleId) {
      const user = await UserService.removeRoles(userId, [roleId])
      setUser(user)
    }
  }

  return (
    <div>
      <Form className="mt-4">
        <Form.Group controlId="firstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" required name="firstName" value={user?.firstName ?? ''} readOnly />
        </Form.Group>
        <Form.Group controlId="lastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="text" required name="lastName" value={user?.lastName ?? ''} readOnly />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" required name="email" value={user?.email ?? ''} readOnly />
        </Form.Group>
        <Form.Group controlId="roles">
          <Form.Label>Roles</Form.Label>
          <Form.Control as="select" onChange={handleOnRoleSelected}>
            <option></option>
            {availableRoles.map(ar => (
              <option key={ar.key} value={ar.key}>
                {ar.label}
              </option>
            ))}
          </Form.Control>
          <div className="h4 mx-n1 my-2">
            {ROLE_NAMES.filter(rn => user?.roles?.some(r => r === rn.key) === true).map(sr => (
              <Badge key={sr.key} variant="primary" className="mx-1">
                {sr.label}
                <Button type="button" size="sm" className="ml-1" onClick={() => handleRoleRemoval(sr.key)}>
                  ×
                </Button>
              </Badge>
            ))}
          </div>
        </Form.Group>
      </Form>
    </div>
  )
})

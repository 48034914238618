import qs from 'qs'

/** @typedef { import('axios').AxiosRequestConfig } AxiosRequestConfig */

/** @type {AxiosRequestConfig} */
const AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat', sort: (a, b) => a.localeCompare(b) }),
}

export default AxiosRequestConfig

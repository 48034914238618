import React from 'react'

import FAIcon from '../FAIcon'

/** @typedef { import('./CaseSearchColumnHeader').CaseSearchColumnHeaderProps } CaseSearchColumnHeaderProps */

/**
 * Renders a table column header for the case search results.
 * @param {CaseSearchColumnHeaderProps} props
 */
export function CaseSearchColumHeader(props) {
  const { label, sortDirection, sortable, onClick, ...thProps } = props

  const icon = sortDirection === 'Asc' ? 'arrow-up' : sortDirection === 'Desc' ? 'arrow-down' : 'sort'

  return (
    <th {...thProps} scope="col">
      <a href="#!" onClick={onClick}>
        {label} {sortable && <FAIcon name={icon} />}
      </a>
    </th>
  )
}

import React from 'react'

import { ErrorMessage as FormikErrorMessage, Field as FormikField, FieldArray as FormikFieldArray } from 'formik'

// I want these wrappers because I can foresee a few customizations we need to do, and we have a LOT of fields to update when we make changes.

export const Field = FormikField
export const ErrorMessage = props => (
  <div className="text-danger">
    <FormikErrorMessage {...props} />
  </div>
)
export const FieldArray = FormikFieldArray

import React from 'react'

import './CompanySummary.scss'

const CompanySummary = ({ company }) => {
  if (!company) return null

  const valueOrEmpty = value => {
    if (value == null || value === '') return <>&mdash;</>

    return value
  }

  return (
    <div className="company-summary mt-3">
      <div className="form-text text-muted">
        <div>
          <div className="align-top sub-label">Address</div>
          <div className="d-inline-block">
            {valueOrEmpty(company?.addressLine1)}
            <br />
            {company?.addressCity && `${company?.addressCity}, `}
            {company?.addressState} {company?.addressZip}
          </div>
        </div>
        <div>
          <div className="sub-label">Phone</div>
          {valueOrEmpty(company?.phone)}
        </div>
        <div>
          <div className="sub-label">Fax</div>
          {valueOrEmpty(company?.fax)}
        </div>
        <div>
          <div className="sub-label">Email</div>
          {valueOrEmpty(company?.email)}
        </div>
      </div>
    </div>
  )
}

export default CompanySummary

import React, { useEffect, useState } from 'react'

import AutoCompleteComponent, { getAddressDetails, getStreetAddress } from '../autocomplete/AutoComplete'
import { ErrorMessage, Field } from '../controls/FormikWrappers'
import StateSelector from '../StateSelector'

const AttorneyFormDefendantSection = props => {
  const { caseModel, handleFieldChange, setFieldValue } = props
  const [address, setAddress] = useState(null)

  useEffect(() => {
    const streetAddress = getStreetAddress(address)
    if (!streetAddress) {
      return
    }

    setFieldValue('defendant.address.line1', streetAddress)
    setFieldValue('defendant.address.city', getAddressDetails(address, 'locality'))
    setFieldValue('defendant.address.zip', getAddressDetails(address, 'postal_code'))
    setFieldValue('defendant.address.state', getAddressDetails(address, 'administrative_area_level_1'))
  }, [address, setFieldValue])

  return (
    <React.Fragment>
      <h4 className="form-heading mt-4 mb-4">Defendant</h4>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-3" htmlFor="defendant.name">
              Company Name
            </label>
            <Field type="text" name="defendant.name" className="form-control" id="defendant.name" />
            <ErrorMessage name="defendant.name" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.address.line1">
              Address
            </label>
            <AutoCompleteComponent address={setAddress} />
            <Field
              type="text"
              name="defendant.address.line1"
              className="form-control"
              id="defendant.address.line1"
              value={caseModel.defendant.address?.line1 ?? ''}
              onChange={handleFieldChange('defendant.address.line1')}
            />
            <ErrorMessage name="defendant.address.line1" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.address.city">
              City
            </label>
            <Field
              type="text"
              name="defendant.address.city"
              className="form-control"
              id="defendant.address.city"
              value={caseModel.defendant.address?.city ?? ''}
              onChange={handleFieldChange('defendant.address.city')}
            />
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.address.state">
              State
            </label>
            <Field id="defendant.address.state" name="defendant.address.state">
              {({ field }) => (
                <StateSelector
                  {...field}
                  value={caseModel.defendant.address?.state ?? ''}
                  onChange={handleFieldChange('defendant.address.state')}
                />
              )}
            </Field>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.address.zip">
              Zip Code
            </label>
            <Field
              type="text"
              name="defendant.address.zip"
              className="form-control"
              id="defendant.address.zip"
              value={caseModel.defendant.address?.zip ?? ''}
              onChange={handleFieldChange('defendant.address.zip')}
            />
          </div>
        </div>
      </div>
      <div>
        <ErrorMessage name="defendant.address.city" />
        <ErrorMessage name="defendant.address.state" />
        <ErrorMessage name="defendant.address.zip" />
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.contact.phone">
              Phone
            </label>
            <Field
              type="tel"
              name="defendant.contact.phone"
              id="defendant.contact.phone"
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name="defendant.contact.phone" />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.contact.fax">
              Fax
            </label>
            <Field
              type="tel"
              name="defendant.contact.fax"
              className="form-control"
              placeholder="(123) 456-7890"
              id="defendant.contact.fax"
            />
            <ErrorMessage name="defendant.contact.fax" />
          </div>
        </div>
      </div>
      <div>
        <label className="form-label mt-3" htmlFor="defendant.contact.firstName">
          Contact Name
        </label>
        <div className="form-row">
          <div className="col-md-3">
            <div className="form-group">
              <Field
                type="text"
                name="defendant.contact.firstName"
                id="defendant.contact.firstName"
                className="form-control"
                placeholder="First Name"
              />
              <ErrorMessage name="defendant.contact.firstName" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <Field
                type="text"
                name="defendant.contact.lastName"
                id="defendant.contact.lastName"
                className="form-control"
                placeholder="Last Name"
              />
              <ErrorMessage name="defendant.contact.lastName" />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="defendant.contact.email">
              Email
            </label>
            <Field
              type="email"
              name="defendant.contact.email"
              className="form-control"
              placeholder="name@email.com"
              id="defendant.contact.email"
            />
            <ErrorMessage name="defendant.contact.email" />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AttorneyFormDefendantSection

import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools as composeWithReduxDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import reducers from '../reducers'

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__

export default () => {
  const store = createStore(reducers, composeWithReduxDevTools(applyMiddleware(thunkMiddleware)))

  return store
}

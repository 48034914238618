import { getToken } from '../utils/getToken'

import { wrapFetch } from './FetchHelper'

export default {
  getAll,
  getAllForCase,
  get,
  create,
  update,
}

function handleSuccess(result) {
  if (!result.ok) throw new Error(result)

  return result.json()
}

function handleFailure(defaultErrorMessage, err) {
  throw new Error(defaultErrorMessage || err)
}

function getAll(incompleteOnly = false) {
  return fetch(`/api/tasks?incompleteOnly=${incompleteOnly}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve case tasks.', err))
}

function getAllForCase(caseNumber, filter) {
  let url = `/api/cases/${caseNumber}/tasks`

  if (filter) {
    url += `?$filter="${encodeURIComponent(filter)}"`
  }

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve task.', err))
}

function get(caseNumber, taskNumber) {
  return fetch(`/api/cases/${caseNumber}/tasks/${taskNumber}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(handleSuccess)
    .catch(err => handleFailure('Unable to retrieve task.', err))
}

function create(taskObj, caseNumber) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/tasks`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(taskObj),
    }),
  )
}

function update(taskObj, caseNumber, taskNumber) {
  return wrapFetch(
    fetch(`/api/cases/${caseNumber}/tasks/${taskNumber}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(taskObj),
    }),
  )
}

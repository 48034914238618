import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

const Profile = withRouter(({ history, ...props }) => {
  return (
    <div>
      <h1 className="mb-5">{props.user.name}</h1>

      <Card className="mb-1">
        <Card.Header>Roles</Card.Header>
        <Card.Body>
          {props.user.roles.map(role => (
            <Card.Text key={role}>{role}</Card.Text>
          ))}
        </Card.Body>
      </Card>

      <div>
        <Button
          variant="link"
          onClick={() => {
            props.logoutUser()
            history.push('/')
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  )
})

export default Profile

import React, { useEffect, useState } from 'react'

import Moment from 'moment'

import { ADMIN_ROLE_NAME } from '../../../utils/Constants'
import validation from '../../../validation'
import AdjusterSelecter from '../../AdjusterSelecter'
import AutoCompleteComponent, { getAddressDetails, getStreetAddress } from '../../autocomplete/AutoComplete'
import CompanySummary from '../../CompanySummary'
import DateOfBirthPicker from '../../controls/DateOfBirthPicker'
import LanguageSelector from '../../LanguageSelector'
import ReferralSourceSelector from '../../ReferralSourceSelecter'
import StateSelector from '../../StateSelector'

import 'react-datepicker/dist/react-datepicker.css'

export default {
  name: 'Referral',
  group: 'Client',
  sectionNumber: 1,
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    referral: validation.object({
      sourceId: validation.shortText('Referral Source').required(),
      adjusterId: validation.shortText('Adjuster').required(),
    }),
    client: validation.object({
      firstName: validation.name('Client first name').required(),
      lastName: validation.name('Client last name').required(),
      address: validation.address('Client address', false),
      phone: validation.phone('Client phone').required(),
      fax: validation.fax('Client fax'),
      email: validation.email('Client email').required(),
      birthdate: validation.birthdate('Client date of birth'),
      alternateContact: validation.object({
        firstName: validation.name('Alternate contact first name'),
        lastName: validation.name('Alternate contact last name'),
        phone: validation.phone('Alternate contact phone'),
        fax: validation.fax('Alternate contact fax'),
        email: validation.email('Alternate contact email'),
      }),
      firstLanguage: validation.shortText('Client first language'),
      secondLanguage: validation.shortText('Client second language'),
    }),
    employer: validation.object({
      name: validation.name('Employer name').required(),
      address: validation.address('Employer address'),
      contact: validation.object({
        title: validation.shortText('Employer contact title'),
        firstName: validation.name('Employer contact first name'),
        lastName: validation.name('Employer contact last name'),
        phone: validation.phone('Employer contact phone'),
        fax: validation.fax('Employer contact fax'),
        email: validation.email('Employer contact email'),
      }),
    }),
  })
}

const formatDate = date => {
  if (date) {
    return Moment(date).format('MM/DD/YYYY')
  }
  return ''
}

function Component({
  caseModel,
  referralSources,
  onAddNewReferralSource,
  onEditReferralSource,
  adjusters,
  onAddNewAdjuster,
  onEditAdjuster,
  createInputProps,
  getValue,
  setModelValue,
  ErrorMessage,
  user,
}) {
  const [fields, setFields] = useState()
  const [address, setAddress] = useState(null)
  const [employerAddress, setEmployerAddress] = useState(null)

  useEffect(() => {
    const streetAddress = getStreetAddress(address)
    if (!streetAddress) {
      return
    }

    setFields(current => {
      return {
        ...current,
        'client.address.line1': streetAddress,
        'client.address.city': getAddressDetails(address, 'locality'),
        'client.address.zip': getAddressDetails(address, 'postal_code'),
        'client.address.state': getAddressDetails(address, 'administrative_area_level_1'),
      }
    })
  }, [address])

  useEffect(() => {
    const employerStreetAddress = getStreetAddress(employerAddress)
    if (!employerStreetAddress) {
      return
    }

    setFields(current => {
      return {
        ...current,
        'employer.address.line1': employerStreetAddress,
        'employer.address.city': getAddressDetails(employerAddress, 'locality'),
        'employer.address.zip': getAddressDetails(employerAddress, 'postal_code'),
        'employer.address.state': getAddressDetails(employerAddress, 'administrative_area_level_1'),
      }
    })
  }, [employerAddress])

  const adjuster = adjusters.find(x => x.id === caseModel.referral?.adjusterId)
  const referralSource = referralSources.find(x => x.id === caseModel.referral?.sourceId)
  const isAdmin = !!user && !!user.roles && user.roles.includes(ADMIN_ROLE_NAME)

  return (
    <div>
      <h4 className="pb-3">Referral</h4>
      <div className="form-row">
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-3" htmlFor="referral.date">
              Date of Referral*
            </label>
            <span className="form-control-plaintext">{formatDate(getValue('referral.date', '---', caseModel))}</span>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-7">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="referral.sourceId">
              Company/Individual*
            </label>
            <React.Fragment>
              <ReferralSourceSelector
                {...createInputProps('referral.sourceId')}
                onEditRequested={onEditReferralSource}
                referralSources={referralSources}
                onAddRequested={onAddNewReferralSource}
                isAdmin={isAdmin}
              />
              <ErrorMessage name="referral.sourceId" />
            </React.Fragment>
            <CompanySummary company={referralSource} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-7">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="referral.adjusterId">
              Adjuster*
            </label>
            <AdjusterSelecter
              {...createInputProps('referral.adjusterId')}
              onEditRequested={onEditAdjuster}
              adjusters={adjusters}
              onAddRequested={onAddNewAdjuster}
              isAdmin={isAdmin}
            />
            <ErrorMessage name="referral.adjusterId" />
            <CompanySummary company={adjuster} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="referral.claimNumber">
              Claim Number
            </label>
            <input
              type="text"
              className="form-control"
              {...createInputProps('referral.claimNumber')}
              maxLength={validation.maxLengths.shortText}
            />
            <ErrorMessage name="referral.claimNumber" />
          </div>
        </div>
      </div>

      <hr className="mt-5 mb-5" />
      <h4 className="form-heading">Client</h4>
      <div>
        <label className="form-label mt-3" htmlFor="client.firstName">
          Full Name*
        </label>
        <div className="form-row">
          <div className="col-md-3">
            <div className="form-group">
              <input
                type="text"
                {...createInputProps('client.firstName')}
                className="form-control"
                placeholder="First Name"
                maxLength={validation.maxLengths.name}
              />
              <ErrorMessage name="client.firstName" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input
                type="text"
                {...createInputProps('client.lastName')}
                className="form-control"
                placeholder="Last Name"
                maxLength={validation.maxLengths.name}
              />
              <ErrorMessage name="client.lastName" />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.address.line1">
              Address
            </label>
            <AutoCompleteComponent address={setAddress} />
            <input
              type="text"
              {...createInputProps('client.address.line1')}
              className="form-control"
              maxLength={validation.maxLengths.address.line1}
              value={fields?.['client.address.line1'] ?? createInputProps('client.address.line1').value}
            />
            <ErrorMessage name="client.address.line1" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.address.city">
              City
            </label>
            <input
              type="text"
              {...createInputProps('client.address.city')}
              className="form-control"
              maxLength={validation.maxLengths.address.city}
              value={fields?.['client.address.city'] ?? createInputProps('client.address.city').value}
            />
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.address.state">
              State
            </label>
            <React.Fragment>
              <StateSelector
                {...createInputProps('client.address.state')}
                value={fields?.['client.address.state'] ?? createInputProps('client.address.state').value}
              />
            </React.Fragment>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.address.zip">
              Zip Code
            </label>
            <input
              type="text"
              {...createInputProps('client.address.zip')}
              className="form-control"
              maxLength={validation.maxLengths.address.zip}
              value={fields?.['client.address.zip'] ?? createInputProps('client.address.zip').value}
            />
          </div>
        </div>
      </div>
      <div>
        <ErrorMessage name="client.address.city" />
        <ErrorMessage name="client.address.state" />
        <ErrorMessage name="client.address.zip" />
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.phone">
              Phone*
            </label>
            <input
              type="tel"
              {...createInputProps('client.phone')}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name="client.phone" />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.fax">
              Fax
            </label>
            <input
              type="tel"
              {...createInputProps('client.fax')}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name="client.fax" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.email">
              Email*
            </label>
            <input
              type="email"
              {...createInputProps('client.email')}
              className="form-control"
              placeholder="name@email.com"
              maxLength={validation.maxLengths.email}
            />
            <ErrorMessage name="client.email" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label" htmlFor="client.birthdate">
              Date of Birth
            </label>
            <React.Fragment>
              <DateOfBirthPicker
                name="client.birthdate"
                {...createInputProps('client.birthdate')}
                setValue={setModelValue}
              />
              <ErrorMessage name="client.birthdate" />
            </React.Fragment>
          </div>
        </div>
      </div>
      <div className="form-row">
        <h5 className="mt-2 mr-3 ml-1">Alternate Contact</h5>
        <label className="custom-switch ToggleField-component">
          <input
            type="checkbox"
            id="alternateContactSwitch"
            className="custom-control-input"
            checked={getValue('client.hasAlternateContact', false)}
            {...createInputProps('client.hasAlternateContact')}
          />
          <span
            className="custom-control-label d-inline-block"
            {
              ...{
                /* needs to not be a label because eslint can't handle it */
              }
            }
          />
        </label>
      </div>
      {getValue('client.hasAlternateContact') && (
        <React.Fragment>
          <div>
            <label className="form-label mt-3" htmlFor="client.alternateContact.firstName">
              Full Name
            </label>
            <div className="form-row">
              <div className="col-md-3">
                <div className="form-group">
                  <input
                    {...createInputProps('client.alternateContact.firstName')}
                    className="form-control"
                    placeholder="First Name"
                    maxLength={validation.maxLengths.name}
                  />
                  <ErrorMessage name="client.alternateContact.firstName" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <input
                    {...createInputProps('client.alternateContact.lastName')}
                    className="form-control"
                    placeholder="Last Name"
                    maxLength={validation.maxLengths.address.name}
                  />
                  <ErrorMessage name="client.alternateContact.lastName" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label mt-2" htmlFor="client.alternateContact.phone">
                    Phone
                  </label>
                  <input
                    {...createInputProps('client.alternateContact.phone')}
                    className="form-control"
                    type="tel"
                    placeholder="(123) 456-7890"
                  />
                  <ErrorMessage name="client.alternateContact.phone" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label className="form-label mt-2" htmlFor="client.alternateContact.fax">
                    Fax
                  </label>
                  <input
                    {...createInputProps('client.alternateContact.fax')}
                    className="form-control"
                    type="tel"
                    placeholder="(123) 456-7890"
                  />
                  <ErrorMessage name="client.alternateContact.fax" />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-6">
                <label className="form-label mt-2" htmlFor="client.alternateContact.email">
                  Email
                </label>
                <input
                  {...createInputProps('client.alternateContact.email')}
                  className="form-control"
                  type="email"
                  placeholder="name@email.com"
                  maxLength={validation.maxLengths.email}
                />
                <ErrorMessage name="client.alternateContact.email" />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <h4 className="form-heading mt-5">Employer Information</h4>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-3" htmlFor="employer.name">
              Employer Name
            </label>
            <input
              type="text"
              {...createInputProps('employer.name')}
              className="form-control"
              maxLength={validation.maxLengths.name}
            />
            <ErrorMessage name="employer.name" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.address.line1">
              Address
            </label>
            <AutoCompleteComponent address={setEmployerAddress} />
            <input
              {...createInputProps('employer.address.line1')}
              className="form-control"
              maxLength={validation.maxLengths.address.line1}
              value={fields?.['employer.address.line1'] ?? createInputProps('employer.address.line1').value}
            />
            <ErrorMessage name="employer.address.line1" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.address.city">
              City
            </label>
            <input
              {...createInputProps('employer.address.city')}
              className="form-control"
              maxLength={validation.maxLengths.address.city}
              value={fields?.['employer.address.city'] ?? createInputProps('employer.address.city').value}
            />
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.address.state">
              State
            </label>
            <React.Fragment>
              <StateSelector
                {...createInputProps('employer.address.state')}
                value={fields?.['employer.address.state'] ?? createInputProps('employer.address.state').value}
              />
            </React.Fragment>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.address.zip">
              Zip Code
            </label>
            <input
              {...createInputProps('employer.address.zip')}
              className="form-control"
              maxLength={validation.maxLengths.address.zip}
              value={fields?.['employer.address.zip'] ?? createInputProps('employer.address.zip').value}
            />
          </div>
        </div>
      </div>
      <div>
        <ErrorMessage name="employer.address.city" />
        <ErrorMessage name="employer.address.state" />
        <ErrorMessage name="employer.address.zip" />
      </div>
      <div>
        <label className="form-label mt-3" htmlFor="employer.contact.firstName">
          Contact Name
        </label>
        <div className="form-row">
          <div className="col-md-3">
            <div className="form-group">
              <input
                {...createInputProps('employer.contact.firstName')}
                className="form-control"
                placeholder="First Name"
                name="employer.contact.firstName"
                maxLength={validation.maxLengths.name}
              />
              <ErrorMessage name="employer.contact.firstName" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <input
                {...createInputProps('employer.contact.lastName')}
                className="form-control"
                placeholder="Last Name"
                name="employer.contact.lastName"
                maxLength={validation.maxLengths.name}
              />
              <ErrorMessage name="employer.contact.lastName" />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.contact.title">
              Contact Title
            </label>
            <input
              {...createInputProps('employer.contact.title')}
              className="form-control"
              maxLength={validation.maxLengths.name}
            />
            <ErrorMessage name="employer.contact.title" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.contact.phone">
              Phone
            </label>
            <input
              type="tel"
              {...createInputProps('employer.contact.phone')}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name="employer.contact.phone" />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.contact.fax">
              Fax
            </label>
            <input
              type="tel"
              {...createInputProps('employer.contact.fax')}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name="employer.contact.fax" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.contact.email">
              Email
            </label>
            <input
              type="email"
              {...createInputProps('employer.contact.email')}
              className="form-control"
              placeholder="name@email.com"
              maxLength={validation.maxLengths.email}
            />
            <ErrorMessage name="employer.contact.email" />
          </div>
        </div>
      </div>
      <h4 className="form-heading mt-5">Language(s)</h4>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.firstLanguage">
              Primary
            </label>
            <React.Fragment>
              <LanguageSelector
                {...createInputProps('client.firstLanguage')}
                maxLength={validation.maxLengths.shortText}
              />
              <ErrorMessage name="client.firstLanguage" />
            </React.Fragment>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="client.secondLanguage">
              Secondary
            </label>
            <React.Fragment>
              <LanguageSelector
                {...createInputProps('client.secondLanguage')}
                maxLength={validation.maxLengths.shortText}
              />
              <ErrorMessage name="client.secondLanguage" />
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  )
}

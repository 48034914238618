import React, { useEffect, useState } from 'react'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { connect } from 'react-redux'

const AutoCompleteComponent = ({ address = () => null, placesApiKey }) => {
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (value) {
      geocodeByPlaceId(value.value.place_id).then(result => {
        const addressComponents = result[0].address_components
        address(addressComponents)
      })
    }
  }, [value, address])

  return (
    <GooglePlacesAutocomplete
      autocompletionRequest={{
        types: ['address'],
        componentRestrictions: {
          country: ['us'],
        },
      }}
      apiKey={placesApiKey}
      selectProps={{
        value,
        onChange: setValue,
      }}
    />
  )
}

export const getAddressDetails = (address, detail) => {
  if (!address) {
    return ''
  }
  if (detail === 'administrative_area_level_1') {
    return address?.filter(x => x.types.some(element => element === detail))[0]?.short_name
  }
  if (detail === 'locality') {
    const locality = address?.filter(x => x.types.some(element => element === detail))[0]?.long_name
    return locality ?? address?.filter(x => x.types.some(element => element === 'neighborhood'))[0]?.long_name
  }
  return address?.filter(x => x.types.some(element => element === detail))[0]?.long_name
}

export const getStreetAddress = address => {
  if (!address) {
    return ''
  }
  return `${getAddressDetails(address, 'street_number')} ${getAddressDetails(address, 'route')}`
}

const mapStateToProps = state => ({
  placesApiKey: state.settings.placesApiKey,
})

export default connect(mapStateToProps)(AutoCompleteComponent)

import React, { useEffect, useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { Route, Switch } from 'react-router'
import { Link, matchPath, useLocation, useRouteMatch, withRouter } from 'react-router-dom'

import { Button } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { orderBy } from 'lodash'
import Moment from 'moment'

import DetailsPage from '../components/case/CaseDetails/DetailsPage'
import CaseNavigationBar from '../components/case/CaseNavigationBar'
import OverviewPage from '../components/case/OverviewPage'
import ReportsPage from '../components/case/ReportsPage'
import { BackButton } from '../components/controls/BackButton'
import FAIcon from '../components/FAIcon'
import LoadingSpinner from '../components/LoadingSpinner'
import AppointmentService from '../services/AppointmentsService'
import CasesService from '../services/CasesService'
import format from '../services/format'
import TasksService from '../services/TasksService'

import './CasePage.scss'

const CasePage = withRouter(({ caseNumber, user, history, settings }) => {
  const { path, url } = useRouteMatch()
  const returnToParam = new URLSearchParams(useLocation().search).get('returnTo')
  const saveFormRef = useRef(null)
  const [returnToUrl] = useState(returnToParam)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()
  const [client, setClient] = useState()
  const [incompleteAppointments, setIncompleteAppointments] = useState()
  const [incompleteTasks, setIncompleteTasks] = useState()
  const [completeTasks, setCompleteTasks] = useState()

  const [isAppointmentsMenuToggled, setIsAppointmentsMenuToggled] = useState(false)
  const [isTasksMenuToggled, setIsTasksMenuToggled] = useState(false)

  const tabs = [
    { name: 'Overview', link: `${url}`, pathProps: { path, exact: true }, component: OverviewPage },
    {
      name: 'Details',
      link: `${url}/details`,
      needsSave: true,
      pathProps: { path: `${path}/details` },
      component: DetailsPage,
    },
    { name: 'Reports', link: `${url}/reports`, pathProps: { path: `${path}/reports` }, component: ReportsPage },
  ]
  const selectedTabIndex = tabs.findIndex(x => {
    const matchedPath = matchPath(window.location.pathname, x.pathProps)
    return matchedPath !== null
  })
  const selectedTab = tabs[selectedTabIndex]
  const getTabClass = tabIndex => (tabIndex === selectedTabIndex ? 'active-tab-item' : 'tab-items')

  useEffect(() => {
    const casePromise = CasesService.get(caseNumber, ['client']).then(r => {
      setClient(r.client)
      return r
    })

    const appointmentsPromise = AppointmentService.getCaseAppointments(caseNumber, 'complete eq 0').then(r => {
      setIncompleteAppointments(orderBy(r, x => x.startUtc))
    })

    const incompleteTasksPromise = TasksService.getAllForCase(caseNumber, 'complete eq 0').then(r => {
      setIncompleteTasks(orderBy(r, x => x.createdUtc))
    })

    const completeTasksPromise = TasksService.getAllForCase(caseNumber, 'complete eq 1').then(r => {
      setCompleteTasks(orderBy(r, x => x.createdUtc))
    })

    // wait for everything to be done before we clear the loading indicator.
    Promise.all([casePromise, incompleteTasksPromise, appointmentsPromise, completeTasksPromise])
      .then(() => {
        setIsLoading(false)
      })
      .catch(err => {
        setError(err.message)
        setIsLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  if (isLoading) return <LoadingSpinner />

  function getFutureAppointments(appointments) {
    const nowUtc = Moment.utc()
    return appointments.filter(appointment => Moment.utc(appointment.endUtc).isSameOrAfter(nowUtc))
  }

  const handleClickExit = e => {
    e.preventDefault()

    if (returnToUrl) {
      history.push(returnToUrl)
      return
    }

    history.push('/cases')
  }

  const triggerFormSubmission = () => {
    saveFormRef.current.dispatchEvent(new Event('submit'))
  }

  const slideAppointmentsMenu = () => {
    setIsAppointmentsMenuToggled(!isAppointmentsMenuToggled)
    if (isTasksMenuToggled) setIsTasksMenuToggled(false)
  }

  const handleNewAppointmentClicked = () => {
    slideAppointmentsMenu()
    history.push(`/cases/${caseNumber}/appointments`)
  }

  const slideTasksMenu = () => {
    setIsTasksMenuToggled(!isTasksMenuToggled)
    if (isAppointmentsMenuToggled) setIsAppointmentsMenuToggled(false)
  }

  const handleNewTaskClicked = () => {
    slideTasksMenu()
    history.push(`/cases/${caseNumber}/tasks`)
  }

  return (
    <Slide className="container-case-page background" direction="up" in={true} mountOnEnter unmountOnExit>
      <div>
        <CaseNavigationBar
          title={`${client.firstName?.toUpperCase()} ${client.lastName?.toUpperCase()}`}
          subtitle={`CASE NO: ${caseNumber}`}
          rightButtonText={selectedTab.needsSave ? 'SAVE' : ''}
          handleClickLeft={handleClickExit}
          handleRightClick={triggerFormSubmission}
        />
        <React.Fragment>
          <div className="tab-bar-container w-100">
            <ul className="tab-bar container">
              {tabs.map((tab, index) => (
                <li key={index} className={getTabClass(index)}>
                  {index === selectedTabIndex ? (
                    <span>{tab.name}</span>
                  ) : (
                    <Link id={`${tab.name.toLowerCase()}Tab`} to={tab.link}>
                      {tab.name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            <div className="right-side-menu-buttons-container">
              <Button className="h-100" id="appointmentBtn" onClick={() => slideAppointmentsMenu()}>
                <FAIcon type="far fa-lg text-white" name="calendar" />
              </Button>
              <Button className="h-100" id="taskBtn" onClick={() => slideTasksMenu()}>
                <FAIcon type="far fa-lg text-white" name="check-circle" />
              </Button>
            </div>
          </div>
          <div className="tab-bar-content-container">
            <div
              className={`right-side-menu-container ${
                !isAppointmentsMenuToggled ? 'right-side-menu-container-closed' : ''
              }`}
            >
              <BackButton url={returnToUrl} />
              <div className="right-side-menu-header-container">
                <h6 className="right-side-menu-header text-primary">APPOINTMENTS</h6>
                <button
                  className="right-side-menu-button"
                  id="addAppointmentBtn"
                  onClick={() => handleNewAppointmentClicked()}
                >
                  <FAIcon name="plus-circle" type="fa text-primary" />
                </button>
              </div>
              <hr className="mt-2 ml-4 mr-4" />
              <div className="right-side-menu-content-container">
                {getFutureAppointments(incompleteAppointments).map(appointment => {
                  return (
                    <div key={appointment.appointmentNumber}>
                      <Link
                        className="page-link text-truncate"
                        to={`/cases/${appointment.caseNumber}/appointments/${appointment.appointmentNumber}`}
                      >
                        {appointment.title}
                      </Link>
                      <p className="text-details">
                        {format.date(appointment.startUtc)} &#8226; {format.time(appointment.startUtc)} -{' '}
                        {format.time(appointment.endUtc)}
                      </p>
                      <hr />
                    </div>
                  )
                })}
              </div>
            </div>
            <div
              className={`right-side-menu-container ${!isTasksMenuToggled ? 'right-side-menu-container-closed' : ''}`}
            >
              <div className="right-side-menu-header-container">
                <h6 className="right-side-menu-header text-primary">TASKS</h6>
                <button
                  className="right-side-menu-button btn-ghost"
                  id="addTaskBtn"
                  onClick={() => handleNewTaskClicked()}
                >
                  <FAIcon name="plus-circle" type="fa fa-lg text-primary" />
                </button>
              </div>
              <hr className="mt-2 ml-4 mr-4" />
              <div className="right-side-menu-content-container">
                {incompleteTasks.map(task => {
                  return (
                    <div key={task.taskNumber}>
                      <div className="task-list-item">
                        <div className="row">
                          <button
                            className="check-box-btn task-btn"
                            type="button"
                            onClick={() => {
                              history.push(`${task.caseNumber}/tasks/${task.taskNumber}?asNote=true`)
                            }}
                          >
                            <FAIcon name="circle" type="far fa-md check-box" />
                          </button>
                          <Link
                            className="col-8 text-non-linking text-truncate"
                            to={`/cases/${task.caseNumber}/tasks/${task.taskNumber}?asNote=true`}
                          >
                            {task.title}
                          </Link>
                        </div>
                        <div className="task-date">{format.date(task.dueDateUtc)}</div>
                      </div>
                      <hr />
                    </div>
                  )
                })}
              </div>
            </div>

            {!!error && (
              <Alert variant="danger" className="mb-0 pr-5 pl-5">
                <strong>Error:</strong> <pre className="mb-0">{error}</pre>
              </Alert>
            )}

            <Switch>
              {tabs.map(({ name, pathProps, component: Component }) => (
                <Route key={name} {...pathProps}>
                  <Component
                    caseNumber={caseNumber}
                    user={user}
                    settings={settings}
                    showError={setError}
                    formRef={saveFormRef}
                    incompleteAppointments={incompleteAppointments}
                    incompleteTasks={incompleteTasks}
                    completeTasks={completeTasks}
                  />
                </Route>
              ))}
            </Switch>
          </div>
        </React.Fragment>
      </div>
    </Slide>
  )
})

export default CasePage

import React from 'react'

import Moment from 'moment'

import './SoapNote.scss'

export default {
  name: 'SOAP Notes',
  group: 'Notes & Time',
  Component,
}

function Component({ getValue, appointments = [] }) {
  const getFormattedDateTimeValue = (startUtc, endUtc) => {
    if (!!startUtc && !!endUtc) {
      const date = Moment.utc(startUtc).local().format('M/DD/YYYY')
      const startTime = Moment.utc(startUtc).local().format('h:mma')
      const endTime = Moment.utc(endUtc).local().format('h:mma')

      return `${date} ${startTime} - ${endTime}`
    } else {
      return ''
    }
  }

  function getCompletedAndPastAppointments(appointments) {
    const dateFormat = 'MM/DD/YYYY, hh:mm:ss a'
    const list = appointments.filter(appointment => {
      return (
        appointment.complete === true ||
        Moment(Moment.utc(appointment.startUtc).local().format(dateFormat)).isSameOrBefore(
          Moment().local().format(dateFormat),
        )
      )
    })
    return getSortedAppointmentsDescendingByDate(list)
  }

  function getSortedAppointmentsDescendingByDate(appointments) {
    const format = 'MM/DD/YYYY, hh:mm:ss a'
    return appointments.sort((left, right) => {
      return Moment.utc(left.endUtc).format(format) > Moment.utc(right.endUtc).format(format)
        ? 1
        : Moment.utc(left.endUtc).format(format) < Moment.utc(right.endUtc).format(format)
        ? -1
        : 0
    })
  }

  const soapNotes = getCompletedAndPastAppointments(appointments)

  return (
    <div className="case-details-soap-note-container">
      <h4 className="pb-4">SOAP Notes</h4>
      <div className="card border-light">
        <table className="table mb-0">
          <thead>
            <tr>
              <th scope="col">APPOINTMENT</th>
              <th scope="col">DATE</th>
              <th scope="col">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {soapNotes.map(appointment => {
              const caseNumber = getValue('caseNumber')
              return (
                <tr key={appointment.appointmentNumber}>
                  <th scope="row">
                    <div className="column-wrapper">{getValue('title', '', appointment)}</div>
                  </th>
                  <td>
                    {getFormattedDateTimeValue(
                      getValue('startUtc', '', appointment),
                      getValue('endUtc', '', appointment),
                    )}
                  </td>
                  <td>
                    <a
                      className="font-weight-bold"
                      href={`/cases/${caseNumber}/appointments/${appointment.appointmentNumber}/complete`}
                    >
                      {!getValue('complete', '', appointment) ? 'Add Notes' : 'Completed'}
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {(!soapNotes || soapNotes.length === 0) && (
          <div className="empty-table-message-container">
            <h6 className="mb-0">No appointments have been created</h6>
          </div>
        )}
      </div>
    </div>
  )
}

import React from 'react'

import validation from '../../../validation'

import AttorneySection from './AttorneySection'
import DiagnosisSection from './DiagnosisSection'
import DiagnosticTestsSection from './DiagnosticTestsSection'
import EquipmentProvidersSection from './EquipmentProvidersSection'
import EquipmentSection from './EquipmentSection'
import FunctionalSection from './FunctionalSection'
import HomeSetupSection from './HomeSetupSection'
import InjurySection from './InjurySection'
import MedicationsSection from './MedicationsSection'
import PharmaciesSection from './PharmaciesSection'
import ReferralSection from './ReferralSection'
import SoapNote from './SoapNote'
import SurgeriesAndHospitalizationsSection from './SurgeriesAndHospitalizationsSection'
import SystemsSection from './SystemsSection'
import TasksSection from './TasksSection'
import TransportationSection from './TransportationSection'
import TreatmentProvidersSection from './TreatmentProvidersSection'
import WorkStatusSection from './WorkStatusSection'

const sections = [
  InjurySection,
  DiagnosisSection,
  MedicationsSection,
  SurgeriesAndHospitalizationsSection,
  SystemsSection,
  FunctionalSection,
  DiagnosticTestsSection,
  EquipmentSection,
  TransportationSection,
  HomeSetupSection,
  TreatmentProvidersSection,
  EquipmentProvidersSection,
  PharmaciesSection,
  SoapNote,
  TasksSection,
  WorkStatusSection,
  ReferralSection,
  AttorneySection,
]

if (new URLSearchParams(window.location.search).get('showModel')) {
  sections.push({
    name: 'Model',
    group: 'Diagnostics',
    validationSchema: validation.object({}),
    Component: ({ caseModel }) => (
      <div>
        <pre>{JSON.stringify(caseModel, null, 2)}</pre>
      </div>
    ),
  })

  sections.push({
    name: 'Validations',
    group: 'Diagnostics',
    validationSchema: validation.object({}),
    Component: ({ validStateBySection }) => (
      <div>
        <pre>{JSON.stringify(validStateBySection, null, 2)}</pre>
      </div>
    ),
  })

  sections.push({
    name: 'Named State',
    group: 'Diagnostics',
    validationSchema: validation.object({}),
    Component: ({ namedState }) => (
      <div>
        <pre>{JSON.stringify(namedState, null, 2)}</pre>
      </div>
    ),
  })

  sections.push({
    name: 'Other',
    group: 'Diagnostics',
    validationSchema: validation.object({}),
    Component: ({ modelIsDirty }) => (
      <div>
        <div>
          <label htmlFor="nothing">Is Model Dirty?</label>{' '}
          {modelIsDirty ? <span className="text-danger">Yes</span> : <span>No</span>}
        </div>
      </div>
    ),
  })
}

export default sections

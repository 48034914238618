import { User } from 'oidc-client-ts'

import { COGNITO_AUTHORITY, COGNITO_CLIENT_ID } from './Constants'

export const getUser = () => {
  const oidcStorage = localStorage.getItem(`oidc.user:${COGNITO_AUTHORITY}:${COGNITO_CLIENT_ID}`)
  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

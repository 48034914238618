import React, { useEffect, useRef, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Helmet from 'react-helmet'
import { useParams, withRouter } from 'react-router-dom'

import Slide from '@material-ui/core/Slide'
import Moment from 'moment'

import AutoCompleteComponent, { getAddressDetails, getStreetAddress } from '../components/autocomplete/AutoComplete'
import CaseNavigationBar from '../components/case/CaseNavigationBar'
import CheckBox from '../components/controls/CheckBox'
import DatePicker from '../components/controls/DatePicker'
import TimePicker from '../components/controls/TimePicker'
import CustomDropDown from '../components/CustomDropDown'
import FAIcon from '../components/FAIcon'
import NavigationBar from '../components/NavigationBar'
import StateSelector from '../components/StateSelector'
import createFormHelpers from '../FormHelpers'
import AppointmentService from '../services/AppointmentsService'
import CasesService from '../services/CasesService'
import { SUGGESTED_FIELD_LENGTH } from '../utils/Constants'
import validation from '../validation'

import './NewAppointment.scss'

const NewAppointment = withRouter(({ history }) => {
  const { caseNumber, appointmentNumber } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const formRef = useRef(null)
  const [appointment, setAppointment] = useState({})
  const [appointmentDateAndTime, setAppointmentDateAndTime] = useState({})
  const [cases, setCases] = useState([])
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [treatmentProviders, setTreatmentProviders] = useState([])
  const [clientName, setClientName] = useState('')
  const [touched, setTouched] = useState({})
  const [address, setAddress] = useState(null)

  const {
    createInputProps,
    getValue,
    setModelValues,
    ErrorMessage,
    handleBlur,
    getDateTimeErrors,
    getUtcDateTime,
    isValidTimeRange,
  } = createFormHelpers({
    touchedObject: touched,
    setTouchedFunction: setTouched,
    submitAttempted,
    validationSchema: getValidationSchema(),
  })

  function getValidationSchema() {
    return validation.object({
      title: validation.name('Event title').required(),
      caseNumber: validation.shortText('Client').required(),
      provider: validation.shortText('Provider').nullable(),
      description: validation.note('Description').nullable(),
      address: validation.address('Appointment address'),
      transportationService: validation.shortText('Transportation service').nullable(),
    })
  }

  useEffect(() => {
    const streetAddress = getStreetAddress(address)
    if (!streetAddress) {
      return
    }

    setAppointment(current => ({
      ...current,
      address: {
        line1: streetAddress,
        city: getAddressDetails(address, 'locality'),
        zip: getAddressDetails(address, 'postal_code'),
        state: getAddressDetails(address, 'administrative_area_level_1'),
      },
    }))
  }, [address])

  useEffect(() => {
    setIsLoading(true)
    if (!!caseNumber && !!appointmentNumber) {
      const casePromise = CasesService.get(caseNumber).then(caseObj => {
        setCases([caseObj])
        setClientName(
          `${getValue('client.firstName', '', caseObj).toUpperCase()} ${getValue(
            'client.lastName',
            '',
            caseObj,
          ).toUpperCase()}`,
        )
        setTreatmentProviders(getValue('treatmentProviders', [], caseObj))
      })

      const appointmentPromise = AppointmentService.get(caseNumber, appointmentNumber).then(appointment => {
        if (!!appointment && !!appointment.startUtc && !!appointment.endUtc) {
          const utcStartTime = Moment.utc(appointment.startUtc).local().format()
          const utcEndTime = Moment.utc(appointment.endUtc).local().format()

          const dateTime = {
            date: utcStartTime,
            startTime: utcStartTime,
            endTime: utcEndTime,
          }

          setAppointmentDateAndTime(dateTime)
        }
        setAppointment(appointment)
      })

      // wait for everything to be done before we clear the loading indicator.
      Promise.all([casePromise, appointmentPromise])
        .then(() => {
          setIsLoading(false)
        })
        .catch(err => {
          setError(err.message)
          setIsLoading(false)
        })
    } else if (caseNumber) {
      CasesService.get(caseNumber)
        .then(caseObj => {
          setAppointment({ caseNumber })
          setCases([caseObj])
          setClientName(
            `${getValue('client.firstName', '', caseObj).toUpperCase()} ${getValue(
              'client.lastName',
              '',
              caseObj,
            ).toUpperCase()}`,
          )
          setTreatmentProviders(getValue('treatmentProviders', [], caseObj))
          setIsLoading(false)
        })
        .catch(err => {
          setError(err.message)
          setIsLoading(false)
        })
    } else {
      CasesService.getAll(['caseNumber', 'client', 'treatmentProviders'])
        .then(result => {
          setCases(result)
          setIsLoading(false)
        })
        .catch(err => {
          setError(err.message)
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line
  }, [])

  const handleClickExit = () => {
    history.goBack()
  }

  const handleClickSave = () => {
    setSubmitAttempted(true)

    const appointmentCaseNumber = caseNumber || getValue('caseNumber', '', appointment)
    const startUtc = getUtcDateTime('startTime', appointmentDateAndTime)
    const endUtc = getUtcDateTime('endTime', appointmentDateAndTime)

    if (!!appointmentCaseNumber && !!startUtc && !!endUtc && areFieldsValid() && isValidTimeRange(startUtc, endUtc)) {
      // Update the model with the appointment times
      const appointmentObject = appointment
      appointmentObject.caseNumber = appointmentCaseNumber
      appointmentObject.startUtc = startUtc
      appointmentObject.endUtc = endUtc

      setIsLoading(true)

      if (appointmentNumber) {
        return AppointmentService.update(appointmentCaseNumber, appointmentNumber, appointmentObject)
          .then(() => {
            setIsLoading(false)
            setError(null)
            handleClickExit()
          })
          .catch(() => {
            setIsLoading(false)
            setError('An unexpected error occurred while trying to save the appointment')
          })
      } else {
        return AppointmentService.create(appointmentCaseNumber, appointmentObject)
          .then(() => {
            setIsLoading(false)
            setError(null)
            handleClickExit()
          })
          .catch(() => {
            setIsLoading(false)
            setError('An unexpected error occurred while trying to create the appointment')
          })
      }
    }
  }

  const areFieldsValid = () => {
    const result = validation.validate(appointment, getValidationSchema())
    return !!result && result.success
  }

  const triggerFormSubmission = () => {
    formRef.current.dispatchEvent(new Event('submit'))
  }

  const handleClientSelectChange = e => {
    setModelValues(
      [
        { name: 'provider', value: null },
        { name: e.target.name, value: e.target.value },
      ],
      appointment,
      setAppointment,
    )

    const selectedCase = cases ? cases.find(caseObj => caseObj.caseNumber === e.target.value) : {}
    setTreatmentProviders(getValue('treatmentProviders', [], selectedCase))
  }

  const clientOptions = cases
    ? cases.map(caseObj => ({
        id: getValue('caseNumber', '', caseObj),
        name: `${getValue('caseNumber', '', caseObj)} - ${getValue('client.firstName', '', caseObj)} ${getValue(
          'client.lastName',
          '',
          caseObj,
        )}`,
      }))
    : []

  const providerOptions = treatmentProviders
    ? treatmentProviders.map((treatmentProviders, index) => ({
        id: getValue('name', '', treatmentProviders),
        name: getValue('name', '', treatmentProviders),
      }))
    : []

  const appointmentDateTimeErrors = getDateTimeErrors(appointmentDateAndTime)

  return (
    <React.Fragment>
      <Helmet bodyAttributes={{ style: 'background-color : #fff !important' }} />
      <Slide className="new-appointment-container background" direction="up" in={true} mountOnEnter unmountOnExit>
        <div>
          {caseNumber !== undefined ? (
            <CaseNavigationBar
              title={clientName}
              subtitle={`CASE NO: ${caseNumber}`}
              rightButtonText={'SAVE'}
              handleClickLeft={handleClickExit}
              handleRightClick={triggerFormSubmission}
              shadow="shadow"
            />
          ) : (
            <NavigationBar
              title="NEW APPOINTMENT"
              rightBtnText="SAVE"
              leftBtnText={<FAIcon type="fa fa-lg close-icon" name={'times'} />}
              handleClickLeft={handleClickExit}
              handleClickRight={triggerFormSubmission}
            />
          )}
          {!!error && (
            <Alert variant="danger" className="mb-0 pr-5 pl-5">
              <strong>Error:</strong> <pre className="mb-0">{error}</pre>
            </Alert>
          )}
          {!isLoading ? (
            <form className="container new-appointment-form mt-5 mb-5" ref={formRef} onSubmit={handleClickSave}>
              <div className="form-row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'title'}>
                      Event Title
                    </label>
                    <input
                      type="text"
                      {...createInputProps({ name: 'title', model: appointment, setModelFunction: setAppointment })}
                      className="form-control"
                      maxLength={SUGGESTED_FIELD_LENGTH}
                    />
                    <ErrorMessage name="title" model={appointment} />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'client'}>
                      Client
                    </label>
                    <CustomDropDown
                      id="caseNumber"
                      name="caseNumber"
                      value={caseNumber || getValue('caseNumber', null, appointment)}
                      isDisabled={!!caseNumber}
                      className="drop-down-container"
                      items={clientOptions}
                      placeholderText="Search Clients"
                      noOptionsMessage="No results found"
                      onChange={handleClientSelectChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage name="caseNumber" model={appointment} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'provider'}>
                      Provider
                    </label>
                    <CustomDropDown
                      className="drop-down-container"
                      items={providerOptions}
                      placeholderText="Search Providers"
                      noOptionsMessage="No results found"
                      allowFreeType="true"
                      {...createInputProps({
                        name: 'provider',
                        model: appointment,
                        setModelFunction: setAppointment,
                        defaultValue: null,
                      })}
                    />
                    <ErrorMessage name="provider" model={appointment} />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-4">
                  <div className="multi-field-group">
                    <label className="form-label mt-2" htmlFor={'date'}>
                      Date
                    </label>
                    <DatePicker
                      {...createInputProps({
                        name: 'date',
                        model: appointmentDateAndTime,
                        setModelFunction: setAppointmentDateAndTime,
                      })}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="multi-field-group  full-width-time-picker">
                    <label className="form-label mt-2" htmlFor={'startTime'}>
                      Start Time
                    </label>
                    <TimePicker
                      {...createInputProps({
                        name: 'startTime',
                        model: appointmentDateAndTime,
                        setModelFunction: setAppointmentDateAndTime,
                      })}
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="full-width-time-picker">
                    <label className="form-label mt-2" htmlFor={'endTime'}>
                      End Time
                    </label>
                    <TimePicker
                      {...createInputProps({
                        name: 'endTime',
                        model: appointmentDateAndTime,
                        setModelFunction: setAppointmentDateAndTime,
                      })}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                {appointmentDateTimeErrors.requiredErrorMessage && (
                  <div className="form-row pl-1">
                    <div className="text-danger form-text">{appointmentDateTimeErrors.requiredErrorMessage}</div>
                  </div>
                )}
                {appointmentDateTimeErrors.errorMessage && (
                  <div className="form-row pl-1">
                    <div className="text-danger form-text">{appointmentDateTimeErrors.errorMessage}</div>
                  </div>
                )}
              </div>
              <div className="form-row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'address.line1'}>
                      Address
                    </label>
                    <AutoCompleteComponent address={setAddress} />
                    <input
                      type="text"
                      {...createInputProps({
                        name: 'address.line1',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                      className="form-control"
                      maxLength={validation.maxLengths.address.line1}
                    />
                    <ErrorMessage name="address.line1" model={appointment} />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'address.city'}>
                      City
                    </label>
                    <input
                      type="text"
                      {...createInputProps({
                        name: 'address.city',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                      className="form-control"
                      maxLength={validation.maxLengths.address.city}
                      value={appointment.address?.city}
                    />
                    <ErrorMessage name="address.city" model={appointment} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'address.state'}>
                      State
                    </label>
                    <React.Fragment>
                      <StateSelector
                        {...createInputProps({
                          name: 'address.state',
                          model: appointment,
                          setModelFunction: setAppointment,
                        })}
                      />
                    </React.Fragment>
                    <ErrorMessage name="address.state" model={appointment} />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'address.zip'}>
                      Zip Code
                    </label>
                    <input
                      type="text"
                      {...createInputProps({
                        name: 'address.zip',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                      className="form-control"
                      maxLength={validation.maxLengths.address.zip}
                    />
                    <ErrorMessage name="address.zip" model={appointment} />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'description'}>
                      Description
                    </label>
                    <input
                      type="text"
                      {...createInputProps({
                        name: 'description',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                      className="form-control"
                      maxLength={SUGGESTED_FIELD_LENGTH}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row mt-2">
                <div className="col-md-4">
                  <div className="form-group">
                    <CheckBox
                      {...createInputProps({
                        name: 'transportationServicesNeeded',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                    >
                      Transportation Services
                    </CheckBox>
                  </div>
                  {!!getValue('transportationServicesNeeded', '', appointment) && (
                    <input
                      type="text"
                      placeholder="Transportation Service Co."
                      {...createInputProps({
                        name: 'transportationService',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                      className="form-control"
                      maxLength={SUGGESTED_FIELD_LENGTH}
                    />
                  )}
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <CheckBox
                      {...createInputProps({
                        name: 'translationServicesNeeded',
                        model: appointment,
                        setModelFunction: setAppointment,
                      })}
                    >
                      Translation Services
                    </CheckBox>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </Slide>
    </React.Fragment>
  )
})

export default NewAppointment

import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import FAIcon from '../FAIcon'

import './Button.scss'

export const BackButton = ({ buttonText = 'Return Back Home', url }) => {
  const history = useHistory()

  const handleClick = () => {
    if (url) {
      return history.push(url)
    }
    return history.goBack()
  }

  return (
    <Button className="back-button" onClick={handleClick} type="button">
      <FAIcon name="arrow-left" /> {buttonText}
    </Button>
  )
}

import React from 'react'
import { Card } from 'react-bootstrap'

import AddButton from '../components/controls/AddButton'
import FAIcon from '../components/FAIcon'

import './AppointmentDashboardCard.scss'

function AppointmentDashboardCard({
  appointment,
  getValue,
  getFormattedDateTimeValue,
  convertUtcToLocalDateTime,
  handleButtonClick,
  cases = [],
  showCaseDetails = false,
}) {
  function getCaseInfoForAppointment(appointment, classes = '') {
    const fullName = cases.map(caseObj => {
      if (caseObj.caseNumber === appointment.caseNumber) {
        return `${getValue('client.firstName', '', caseObj)} ${getValue('client.lastName', '', caseObj)}`
      } else {
        return ''
      }
    })

    return (
      <p className={classes}>
        <strong>{fullName}</strong>
        <br />
        Case #: {appointment.caseNumber}
      </p>
    )
  }

  const addressLine1 = getValue('address.line1', '', appointment)
  const addressCity = getValue('address.city', '', appointment)
  const addressState = getValue('address.state', '', appointment)
  const addressZip = getValue('address.zip', '', appointment)

  const startTimeLocal = convertUtcToLocalDateTime(getValue('startUtc', null, appointment))
  const endTimeLocal = convertUtcToLocalDateTime(getValue('endUtc', null, appointment))
  const isUpcoming = new Date() < new Date(startTimeLocal)

  return (
    <div className="col-lg-6 col-md-12 col-sm-12 mb-3 px-0 appointment-card-container">
      <div className="col-md-12 h-100">
        <Card className="shadow h-100">
          <Card.Body className="appointment-card-body">
            <p className="card-label">{isUpcoming ? 'UPCOMING' : 'PAST'}</p>
            <a
              className="main-link"
              href={`/cases/${appointment.caseNumber}/appointments/${appointment.appointmentNumber}`}
            >
              <p className="mb-0">
                <strong>{getValue('title', '', appointment)}</strong>
              </p>
              {getFormattedDateTimeValue(startTimeLocal, endTimeLocal)}
            </a>
            <div>
              <hr className="mt-1 mt-2" />
              {!!showCaseDetails && (
                <div className="d-table mb-2">
                  <FAIcon type="fas" name="poll-h" className="d-table-cell cool-grey-text align-middle" />
                  {getCaseInfoForAppointment(appointment, 'mb-0 ml-2 d-inline-block footnote')}
                </div>
              )}
              {!!addressLine1 && !!addressCity && !!addressState && !!addressZip ? (
                <React.Fragment>
                  <FAIcon name="fas fa-map-marker-alt" className="d-inline-block float-left cool-grey-text" />
                  <p className="mb-0 address-line footnote">{`${addressLine1} ${addressCity}, ${addressState} ${addressZip}`}</p>
                </React.Fragment>
              ) : (
                <AddButton
                  className="footnote p-0"
                  onClick={() =>
                    handleButtonClick(`/cases/${appointment.caseNumber}/appointments/${appointment.appointmentNumber}`)
                  }
                >
                  Add Location
                </AddButton>
              )}
            </div>
            <button
              type="button"
              className={`btn btn-secondary mt-3 ${isUpcoming ? 'hide-element' : ''}`}
              onClick={() =>
                handleButtonClick(
                  `/cases/${appointment.caseNumber}/appointments/${appointment.appointmentNumber}/complete`,
                )
              }
            >
              Complete
            </button>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default AppointmentDashboardCard

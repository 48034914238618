import React from 'react'

const DateOfBirthPicker = props => {
  const name = props.name
  const value = props.value

  let splitValue = ['', '', '']
  if (value) splitValue = value.split('/')
  if (splitValue.length !== 3) {
    splitValue = ['', '', '']
  }

  const createOnChange = part => {
    return e => {
      splitValue[part] = `${e.target.value}`

      const newValue = splitValue.every(x => x === '') ? null : splitValue.join('/')

      props.setValue(name, newValue)
    }
  }

  const handleOnBlur = e => {
    // only fire blur once this group of fields have been left
    if (e.relatedTarget && e.relatedTarget.hasAttribute('x-date-part')) {
      // eslint-disable-next-line no-useless-return
      return
    }
  }

  const propsFor = part => ({
    value: splitValue[part],
    onChange: createOnChange(part),
    onBlur: handleOnBlur,
    'x-date-part': part,
  })

  return (
    <>
      <div className="form-row">
        <div className="col-3">
          <input type="number" className="form-control" id={`${props.id}.MM`} placeholder="MM" {...propsFor(0)} />
        </div>
        <div className="col-3">
          <input type="number" className="form-control" id={`${props.id}.DD`} placeholder="DD" {...propsFor(1)} />
        </div>
        <div className="col-6">
          <input type="number" className="form-control" id={`${props.id}.YYYY`} placeholder="YYYY" {...propsFor(2)} />
        </div>
      </div>
    </>
  )
}

export default DateOfBirthPicker

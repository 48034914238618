import React from 'react'

import setFocusById from '../../../services/setFocusById'
import validation from '../../../validation'
import AddButton from '../../controls/AddButton'
import DatePicker from '../../controls/DatePicker'
import RemoveButton from '../../controls/RemoveButton'

export default {
  name: 'Diagnostic Tests',
  group: 'Case Details',
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    tests: validation.arrayOf(
      validation.object({
        type: validation.shortText('Type of Test').required(),
        date: validation.shortText('Date of Test').required().nullable(),
      }),
    ),
  })
}

function Component({ addItemToArray, removeItemFromArray, createInputProps, getValue, ErrorMessage }) {
  const tests = getValue('tests', [])

  return (
    <div>
      <h4 className="pb-3">Diagnostic Tests</h4>
      {tests.length > 0 && (
        <div className="form-row hide-sm-down">
          <div className="form-label col-md-6">Type of Test</div>
          <div className="form-label col-md-3">Date of Test</div>
        </div>
      )}
      {tests.map((x, index) => {
        const namePrefix = `tests[${index}]`
        return (
          <Test
            key={namePrefix}
            namePrefix={namePrefix}
            createInputProps={createInputProps}
            ErrorMessage={ErrorMessage}
            handleRemove={() => removeItemFromArray('tests', index)}
          />
        )
      })}
      <AddButton
        id="addTestButton"
        onClick={() => {
          setFocusById(`tests[${tests.length}].type`)
          addItemToArray('tests', {})
        }}
      >
        Add Diagnostic Test
      </AddButton>
    </div>
  )
}

function Test({ namePrefix, createInputProps, ErrorMessage, handleRemove }) {
  return (
    <div className="clearfix">
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor={`${namePrefix}.type`} className="form-label d-md-none">
            Type of Test
          </label>
          <input
            type="text"
            maxLength={validation.maxLengths.name}
            className="form-control"
            {...createInputProps(`${namePrefix}.type`)}
          />
          <ErrorMessage name={`${namePrefix}.type`} />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor={`${namePrefix}.date`} className="form-label d-md-none">
            Date of Test
          </label>
          <div className="d-flex">
            <DatePicker {...createInputProps(`${namePrefix}.date`)} />
            <RemoveButton
              id={`remove${namePrefix}Button`}
              className="hide-sm-down"
              onClick={() => handleRemove()}
            ></RemoveButton>
          </div>
          <ErrorMessage name={`${namePrefix}.date`} />
        </div>
      </div>
    </div>
  )
}

import React from 'react'

const activityTypes = [
  'Interview',
  'Phone Call',
  'Record Review',
  'Travel',
  'Wait',
  'Meeting',
  'Coordination of Services',
  'Update',
  'Report',
]
const expenseTypes = [
  'Meals',
  'Lodging',
  'Office Supplies',
  'Parking',
  'Tolls',
  'Copies',
  'Postage',
  'Air Travel',
  'Rental Car',
  'Mileage',
]

activityTypes.sort().push('Other')
expenseTypes.sort().push('Other')

const ActivityExpenseSelector = ({ value, onChange, id, type, allowEditing = true, ...rest }) => {
  const getSelectorOptions = () => {
    switch (type) {
      case 'activity':
        return activityTypes
      case 'expense':
        return expenseTypes
      default:
        return activityTypes
    }
  }

  return (
    <select
      id={id}
      value={value}
      disabled={allowEditing ? null : true}
      onChange={onChange}
      className="form-control"
      {...rest}
    >
      <option value=""></option>
      {getSelectorOptions().map((type, i) => (
        <option key={i} value={type}>
          {type}
        </option>
      ))}
    </select>
  )
}

export default ActivityExpenseSelector

import React from 'react'

import Moment from 'moment'

import FAIcon from '../FAIcon'

import 'react-big-calendar/lib/sass/styles.scss'
import './CalendarCustomToolbar.scss'

const CalendarCustomToolbar = toolbar => {
  const goToDayView = () => {
    toolbar.onView('day')
  }

  const goToWeekView = () => {
    toolbar.onView('week')
  }

  const goToMonthView = () => {
    toolbar.onView('month')
  }

  const goToBack = () => {
    const mDate = toolbar.date
    const newDate = getNewDateBack(mDate)
    toolbar.onNavigate('prev', newDate)
  }

  const goToNext = () => {
    const mDate = toolbar.date
    const newDate = getNewDateNext(mDate)
    toolbar.onNavigate('next', newDate)
  }

  const getNewDateBack = currentDate => {
    if (toolbar.view === 'month') {
      return new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    } else if (toolbar.view === 'week') {
      return Moment(currentDate).subtract(7, 'days').toDate()
    } else {
      return Moment(currentDate).subtract(1, 'days').toDate()
    }
  }

  const getNewDateNext = currentDate => {
    if (toolbar.view === 'month') {
      return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    } else if (toolbar.view === 'week') {
      return Moment(currentDate).add(7, 'days').toDate()
    } else {
      return Moment(currentDate).add(1, 'days').toDate()
    }
  }

  return (
    <div className="toolbar-container position-relative">
      <div className="filter-container">
        <button className={`bg-filter-off ${toolbar.view === 'month' ? 'selected-tab' : ''}`} onClick={goToMonthView}>
          <span className="label-filter-off">Month</span>
        </button>
        <button className={`bg-filter-off ${toolbar.view === 'week' ? 'selected-tab' : ''}`} onClick={goToWeekView}>
          <span className="label-filter-off">Week</span>
        </button>
        <button className={`bg-filter-off ${toolbar.view === 'day' ? 'selected-tab' : ''}`} onClick={goToDayView}>
          <span className="label-filter-off">Day</span>
        </button>
      </div>
      <div className="navigation-buttons">
        <button className="pl-1 pr-3 pb-2 d-inline-block" onClick={goToBack}>
          <FAIcon className="text-primary" type="fas" name="chevron-left" />
        </button>
        <h4 className="label-date d-inline-block">{toolbar.label}</h4>
        <button className="pr-1 pl-3 pb-2" onClick={goToNext}>
          <FAIcon className="text-primary" type="fas" name="chevron-right" />
        </button>
      </div>
      <a href="/appointments">
        <FAIcon className="text-primary fa-2x py-2 px-1 position-absolute plus-icon" name="plus-circle" />
      </a>
    </div>
  )
}

export default CalendarCustomToolbar

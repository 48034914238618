import React, { useEffect, useState } from 'react'

import setFocusById from '../../../services/setFocusById'
import { SUGGESTED_FIELD_LENGTH } from '../../../utils/Constants'
import validation from '../../../validation'
import AutoCompleteComponent, { getAddressDetails, getStreetAddress } from '../../autocomplete/AutoComplete'
import AddButton from '../../controls/AddButton'
import CheckBox from '../../controls/CheckBox'
import RemoveButton from '../../controls/RemoveButton'
import StateSelector from '../../StateSelector'

export default {
  name: 'Treatment Providers',
  group: 'Providers',
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    treatmentProviders: validation.arrayOf(
      validation.object({
        name: validation.name('Provider name').required(),
        companyName: validation.shortText('Company name'),
        address: validation.address('Provider address'),
        phone: validation.phone('Phone'),
        fax: validation.phone('Fax'),
        email: validation.email('Provider email'),
        speciality: validation.shortText('Speciality'),
      }),
    ),
  })
}

function Component({ getValue, addItemToArray, ...rest }) {
  const treatmentProviders = getValue('treatmentProviders', [])

  return (
    <div>
      <h4 className="pb-3">Treatment Providers</h4>
      {treatmentProviders.map((provider, index) => {
        const namePrefix = `treatmentProviders[${index}]`
        return (
          <TreatmentProviders
            {...rest}
            key={`treatment-providers-container-${index}`}
            index={index}
            namePrefix={namePrefix}
            id={`treatment-providers-container-${index}`}
            className={`${index !== 0 ? 'mt-6' : 'mt-1'}`}
          />
        )
      })}

      <AddButton
        id="treatmentProviders.addButton"
        onClick={() => {
          setFocusById(`treatmentProviders[${treatmentProviders.length}].name`)
          addItemToArray('treatmentProviders', {})
        }}
      >
        Add Provider
      </AddButton>
    </div>
  )
}

function TreatmentProviders({
  createInputProps,
  addItemToArray,
  removeItemFromArray,
  ErrorMessage,
  namePrefix,
  setModelValues,
  index,
  id,
  className,
  ...rest
}) {
  const [address, setAddress] = useState(null)

  useEffect(() => {
    const streetAddress = getStreetAddress(address)
    if (!streetAddress) {
      return
    }

    setModelValues([
      [`${namePrefix}.address.line1`, streetAddress],
      [`${namePrefix}.address.city`, getAddressDetails(address, 'locality')],
      [`${namePrefix}.address.zip`, getAddressDetails(address, 'postal_code')],
      [`${namePrefix}.address.state`, getAddressDetails(address, 'administrative_area_level_1')],
    ])
  }, [address, namePrefix, setModelValues])

  return (
    <div id={id} className={className}>
      <div className="form-row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.name`}>
              Provider Name
            </label>
            <input
              type="text"
              {...createInputProps(`${namePrefix}.name`)}
              className="form-control"
              maxLength={SUGGESTED_FIELD_LENGTH}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={`${namePrefix}.name`} />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.companyName`}>
              Company Name
            </label>
            <input
              type="text"
              {...createInputProps(`${namePrefix}.companyName`)}
              className="form-control"
              maxLength={SUGGESTED_FIELD_LENGTH}
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={`${namePrefix}.companyName`} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-8">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.line1`}>
              Address
            </label>
            <AutoCompleteComponent address={setAddress} />
            <input
              type="text"
              {...createInputProps(`${namePrefix}.address.line1`)}
              className="form-control"
              maxLength={validation.maxLengths.address.line1}
            />
            <ErrorMessage name={`${namePrefix}.address.line1`} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.city`}>
              City
            </label>
            <input
              type="text"
              {...createInputProps(`${namePrefix}.address.city`)}
              className="form-control"
              maxLength={validation.maxLengths.address.city}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.state`}>
              State
            </label>
            <React.Fragment>
              <StateSelector {...createInputProps(`${namePrefix}.address.state`)} />
            </React.Fragment>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.address.zip`}>
              Zip Code
            </label>
            <input
              type="text"
              {...createInputProps(`${namePrefix}.address.zip`)}
              className="form-control"
              maxLength={validation.maxLengths.address.zip}
            />
          </div>
        </div>
      </div>
      <div>
        <ErrorMessage name={`${namePrefix}.address.city`} />
        <ErrorMessage name={`${namePrefix}.address.state`} />
        <ErrorMessage name={`${namePrefix}.address.zip`} />
      </div>
      <div className="form-row">
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.phone`}>
              Phone
            </label>
            <input
              type="tel"
              {...createInputProps(`${namePrefix}.phone`)}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name={`${namePrefix}.phone`} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.fax`}>
              Fax
            </label>
            <input
              type="tel"
              {...createInputProps(`${namePrefix}.fax`)}
              className="form-control"
              placeholder="(123) 456-7890"
            />
            <ErrorMessage name={`${namePrefix}.fax`} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-8">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.email`}>
              Email
            </label>
            <input
              type="email"
              {...createInputProps(`${namePrefix}.email`)}
              className="form-control"
              placeholder="name@email.com"
              maxLength={validation.maxLengths.email}
            />
            <ErrorMessage name={`${namePrefix}.email`} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-12 col-md-8">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor={`${namePrefix}.speciality`}>
              Speciality
            </label>
            <input
              type="text"
              {...createInputProps(`${namePrefix}.speciality`)}
              className="form-control"
              placeholder="Family Medicine, Occupational Therapy"
              maxLength={SUGGESTED_FIELD_LENGTH}
            />
            <ErrorMessage name={`${namePrefix}.speciality`} />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group align-center col-6 col-md-5">
          <CheckBox {...createInputProps(`${namePrefix}.isPrimaryCarePhysician`)}>Primary Care Physician</CheckBox>
        </div>
        <div className="col-6 col-md-3">
          <RemoveButton
            id={`${namePrefix}.removeButton`}
            className="float-right"
            onClick={() => removeItemFromArray('treatmentProviders', index)}
          >
            Remove
          </RemoveButton>
        </div>
      </div>
    </div>
  )
}

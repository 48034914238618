import React, { useEffect, useState } from 'react'
import { AuthProvider, hasAuthParams, useAuth } from 'react-oidc-context'

import { WebStorageStateStore } from 'oidc-client-ts'

import { COGNITO_AUTHORITY, COGNITO_CLIENT_ID, COGNITO_SCOPES } from '../utils/Constants'

const onSigninCallback = user => {
  window.history.replaceState({}, document.title, window.location.pathname)
}

const oidcConfig = {
  authority: COGNITO_AUTHORITY,
  client_id: COGNITO_CLIENT_ID,
  redirect_uri: window.location.origin,
  onSigninCallback,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

const Content = props => {
  const auth = useAuth()
  const [hasTriedSignin, setHasTriedSignin] = useState(false)

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect()
      setHasTriedSignin(true)
    }
  }, [auth, hasTriedSignin])

  if (auth.isLoading) {
    return <div>Loading...</div>
  }

  if (auth.error) {
    if (auth.error.message === 'No end session endpoint') {
      window.location.replace('https://google.com')
      return
    }

    return <div>Oops... {auth.error.message}</div>
  }

  if (auth.isAuthenticated) {
    return props.children
  }

  auth.signinRedirect({ scope: COGNITO_SCOPES })
}

export default props => {
  return (
    <AuthProvider {...oidcConfig}>
      <Content>{props.children}</Content>
    </AuthProvider>
  )
}

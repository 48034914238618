import React, { useState } from 'react'

import CasesService from '../../../services/CasesService'
import { ADMIN_ROLE_NAME } from '../../../utils/Constants'
import validation from '../../../validation'
import ConfirmationModal from '../../controls/ConfirmationModal'

import ReportPageTemplate from './ReportPageTemplate'

const Report = ({ caseNumber, reportNumber, goToReports, showError, settings, user }) => {
  const [caseReportPromise, setCaseReportPromise] = useState(() => CasesService.getReport(caseNumber, reportNumber))
  const [filesPromise] = useState(() => CasesService.getReportFiles(caseNumber, reportNumber))
  const [confirmApprove, setConfirmApprove] = useState(false)
  const [confirmReject, setConfirmReject] = useState(false)
  const [rejectReason, setRejectReason] = useState()
  const [showRejectReasonValidation, setShowRejectReasonValidation] = useState(false)

  const handleApproveReport = () => {
    CasesService.approveReport(caseNumber, reportNumber)
      .onSuccess(r => setCaseReportPromise(Promise.resolve(r)))
      .onBadRequest(err => showError(err))
      .catch(() =>
        showError(
          `An unexpected error occurred while ${settings.caseStatuses.approved.continuousVerb.toLowerCase()} the report`,
        ),
      )
      .then(() => setConfirmApprove(false))
  }

  const handleRejectReport = () => {
    if (!rejectReason) {
      setShowRejectReasonValidation(true)
      return
    }

    setShowRejectReasonValidation(false)
    CasesService.rejectReport(caseNumber, reportNumber, rejectReason)
      .onSuccess(r => setCaseReportPromise(Promise.resolve(r)))
      .onBadRequest(err => showError(err))
      .catch(() =>
        showError(
          `An unexpected error occurred while ${settings.caseStatuses.rejected.continuousVerb.toLowerCase()} the report`,
        ),
      )
      .then(() => setConfirmReject(false))
  }

  const handleCloseRejectConfirmation = () => {
    setConfirmReject(false)
    setShowRejectReasonValidation(false)
    setRejectReason(null)
  }

  const AdminActions = ({ reportModel }) => {
    if (!user.roles.includes(ADMIN_ROLE_NAME)) return null
    if (
      !reportModel.submittedOnUtc ||
      reportModel.approvedOnUtc ||
      reportModel.rejectedOnUtc ||
      reportModel.canceledOnUtc
    )
      return null

    return (
      <>
        <div>
          <button
            id="case-report-reject-btn"
            type="button"
            className="btn btn-outline-primary mr-1"
            onClick={() => setConfirmReject(true)}
          >
            {settings.caseStatuses.rejected.presentTenseVerb}
          </button>
          <button
            id="case-report-approve-btn"
            type="button"
            className="btn btn-primary"
            onClick={() => setConfirmApprove(true)}
          >
            {settings.caseStatuses.approved.presentTenseVerb}
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <ReportPageTemplate
        caseNumber={caseNumber}
        isInProgress={false}
        user={user}
        caseReportPromise={caseReportPromise}
        filesPromise={filesPromise}
        settings={settings}
        goToReports={goToReports}
        actionsComponent={AdminActions}
        printableReportPath={`/cases/${caseNumber}/reports/${reportNumber}/print`}
      />

      {confirmReject && (
        <ConfirmationModal
          title={`${settings.caseStatuses.rejected.presentTenseVerb} Report`}
          body={
            <>
              <div>
                Are you sure you want to {settings.caseStatuses.rejected.presentTenseVerb.toLowerCase()} this report?
                This action cannot be undone.
              </div>

              <div className="form-group mt-3">
                <label htmlFor="rejectReason" className="form-label">
                  Reason for {settings.caseStatuses.rejected.continuousVerb.toLowerCase()}
                </label>
                <textarea
                  id="rejectReason"
                  className="form-control"
                  maxLength={validation.maxLengths.shortText}
                  value={rejectReason ?? ''}
                  onChange={e => setRejectReason(e.target.value)}
                  onBlur={e => setShowRejectReasonValidation(true)}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
                {!showRejectReasonValidation || rejectReason ? null : (
                  <div className="form-text text-danger">
                    Reason for {settings.caseStatuses.rejected.continuousVerb.toLowerCase()} is required
                  </div>
                )}
              </div>
            </>
          }
          onConfirm={handleRejectReport}
          onClose={handleCloseRejectConfirmation}
        />
      )}
      {confirmApprove && (
        <ConfirmationModal
          title={`${settings.caseStatuses.approved.presentTenseVerb} Report`}
          body={`Are you sure you want to ${settings.caseStatuses.approved.presentTenseVerb.toLowerCase()} this report? This action cannot be undone.`}
          onConfirm={handleApproveReport}
          onClose={() => setConfirmApprove(false)}
        />
      )}
    </>
  )
}

export default Report

import React, { useCallback, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Pagination from 'react-bootstrap/Pagination'
import { Element, scroller } from 'react-scroll'

import { useDebouncedCallback } from 'use-debounce'

import { CaseResultRow } from '../components/case-search/CaseResultRow.js'
import { CaseSearchColumHeader } from '../components/case-search/CaseSearchColumHeader.js'
import { SearchBox } from '../components/case-search/SearchBox.js'
import getErrorMessage from '../services/api/getErrorMessage'
import caseSearch from '../services/caseSearch'
import { nextSort } from '../utils/nextSort.js'
import { paginate } from '../utils/paginate.js'

import './Cases.scss'

/** @typedef { import('../services/types/useState.d.ts') } */
/** @typedef { import('../services/types/CaseSearchInput').CaseSearchInput } CaseSearchInput */
/** @typedef { import('../services/types/CaseSearchResultItem').CaseSearchResult } CaseSearchResult */

export default function Cases() {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  /** @type {useState<CaseSearchResult>} */
  const [cases, setCases] = useState()
  const [showClosed, setShowClosed] = useState(false)

  /** @type {useState<CaseSearchInput>} */
  const [caseSearchInput, setCaseSearchInput] = useState({
    orderBy: {
      ClientLastName: 'Asc',
    },
    page: 1,
    perPage: 20,
  })

  useEffect(() => {
    const abortController = new AbortController()

    caseSearch(caseSearchInput, abortController.signal)
      .then(setCases)
      .catch(err => {
        setError(getErrorMessage(err))
      })
      .finally(() => {
        setIsLoading(false)
        scroller.scrollTo('search-container', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        })
      })

    return () => {
      abortController.abort()
    }
  }, [caseSearchInput])

  const handleSearch = searchInput => {
    setCaseSearchInput(searchInput)
    setIsLoading(true)
  }

  const setPage = useCallback(page => {
    return () => {
      handleSearch(s => ({ ...s, page }))
    }
  }, [])

  const toggleSort = column => {
    return e => {
      e.preventDefault()
      handleSearch(s => ({
        ...s,
        orderBy: {
          [column]: nextSort(s.orderBy[column]),
        },
      }))
    }
  }

  const setSearchText = searchText => {
    handleSearch(s => ({ ...s, searchText, page: 1 }))
  }

  const debouncedSetSearchText = useDebouncedCallback(setSearchText, 500, { maxWait: 2000 })

  const hasSearchResults = cases?.results?.length > 0

  const { orderBy } = caseSearchInput

  const pagingItems = hasSearchResults
    ? paginate(cases.page, cases.totalPages).map((number, index) => {
        if (number === '…') {
          return <Pagination.Ellipsis key={number + index} disabled />
        }
        return (
          <Pagination.Item key={number} active={number === cases.page} onClick={setPage(number)}>
            {number}
          </Pagination.Item>
        )
      })
    : []

  const handleShowCasesToggle = () => {
    setShowClosed(s => !s)
  }

  useEffect(() => {
    handleSearch(s => ({ ...s, showClosed, page: 1 }))
  }, [showClosed])

  return (
    <div className="cases-list-container pb-3">
      <Element name="scroll-to-container" className="scroll-to-container" />
      {error && (
        <Alert variant="danger">
          <strong>Error:</strong> {error.message}
        </Alert>
      )}
      {isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-row my-2 align-items-center">
        <SearchBox
          className="d-flex flex-grow-1"
          onChange={debouncedSetSearchText}
          onSubmit={setSearchText}
          placeholder="Search by claimant name"
        />
        <label className="closed-cases-checkbox">
          Show Closed Cases <input type="checkbox" onChange={handleShowCasesToggle} checked={showClosed} />
        </label>
      </div>
      <div className="card border-light">
        <table className="table mb-0">
          <thead>
            <tr>
              <CaseSearchColumHeader
                label="CLAIMANT"
                sortable
                sortDirection={orderBy.ClientLastName}
                onClick={toggleSort('ClientLastName')}
              />
              <CaseSearchColumHeader label="MEMBERS" className="d-none d-md-table-cell" />
              <CaseSearchColumHeader
                label="CLIENT"
                className="d-none d-xl-table-cell"
                sortable
                sortDirection={orderBy.Company}
                onClick={toggleSort('Company')}
              />
              <CaseSearchColumHeader
                label="OPENED ON"
                className="d-none d-sm-table-cell"
                sortable
                sortDirection={orderBy.CreatedUtc}
                onClick={toggleSort('CreatedUtc')}
              />
              <CaseSearchColumHeader
                label="STATUS"
                id="status-column-header"
                sortable
                sortDirection={orderBy.Status}
                onClick={toggleSort('Status')}
              />
              <CaseSearchColumHeader
                label="LAST UPDATED"
                className="d-none d-lg-table-cell"
                sortable
                sortDirection={orderBy.LastUpdatedUtc}
                onClick={toggleSort('LastUpdatedUtc')}
              />
            </tr>
          </thead>
          <tbody>
            {hasSearchResults &&
              cases.results.map(rowData => {
                return <CaseResultRow key={rowData.caseNumber} rowData={rowData} />
              })}
          </tbody>
        </table>
        {!hasSearchResults && (
          <div className="empty-table-message-container">
            <h6 className="mb-0">No cases have been assigned</h6>
          </div>
        )}
      </div>
      {hasSearchResults && (
        <div>
          <div className="text-right mt-3">
            <small>
              Showing {cases.results.length} of {cases.total} cases
            </small>
          </div>
          <div className="d-flex align-content-center">
            <Pagination>
              <Pagination.First onClick={setPage(1)} disabled={cases.page === 1} />
              <Pagination.Prev onClick={setPage(cases.page - 1)} disabled={cases.hasPrevious === false} />
              {pagingItems}
              <Pagination.Next onClick={setPage(cases.page + 1)} disabled={cases.hasNext === false} />
              <Pagination.Last onClick={setPage(cases.totalPages)} disabled={cases.page === cases.totalPages} />
            </Pagination>
          </div>
        </div>
      )}
    </div>
  )
}

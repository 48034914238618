import React from 'react'

import validation from '../../../validation'
import CheckBox from '../../controls/CheckBox'
import DatePicker from '../../controls/DatePicker'

export default {
  name: 'Work Status',
  group: 'Notes & Time',
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    status: validation.object({
      dateLastWorked: validation.date('Date Last Worked'),
      currentTreatment: validation.shortText('Current Treatment'),
      workStatus: validation.shortText('Work Status'),
      returnStatus: validation.shortText('Return to Work Status'),
    }),
  })
}

function Component({ createInputProps, getValue, ErrorMessage }) {
  return (
    <div>
      <h4 className="pb-3">Work Status</h4>
      <div className="form-row">
        <div className="form-group col-md-2">
          <label className="form-label" htmlFor={'statusDateLastWorked'}>
            Date Last Worked
          </label>
          <DatePicker id={'statusDateLastWorked'} {...createInputProps('status.dateLastWorked')} />
        </div>
        <ErrorMessage name={'status.dateLastWorked'} />
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={'statusCurrentTreatment'}>
            Current Treatment
          </label>
          <input
            type="text"
            className="form-control"
            id={'statusCurrentTreatment'}
            maxLength={validation.maxLengths.shortText}
            {...createInputProps('status.currentTreatment')}
          />
        </div>
        <ErrorMessage name={'status.currentTreatment'} />
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={'statusWorkStatus'}>
            Work Status
          </label>
          <input
            type="text"
            className="form-control"
            id={'statusWorkStatus'}
            maxLength={validation.maxLengths.shortText}
            {...createInputProps('status.workStatus')}
          />
        </div>
        <ErrorMessage name={'status.workStatus'} />
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="form-label" htmlFor={'statusReturnStatus'}>
            Return to Work Status
          </label>
          <input
            type="text"
            className="form-control"
            id={'statusReturnStatus'}
            maxLength={validation.maxLengths.shortText}
            {...createInputProps('status.returnStatus')}
          />
        </div>
        <ErrorMessage name={'status.returnStatus'} />
      </div>
      <div className="form-row">
        <div className="form-group no-top-margin">
          <CheckBox id={'statusLightDuty'} {...createInputProps('status.lightDuty')}>
            Light Duty Options
          </CheckBox>
        </div>
      </div>
    </div>
  )
}

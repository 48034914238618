import React from 'react'

import ActivityExpenseSelector from '../components/ActivityExpenseSelector'
import RemoveButton from '../components/controls/RemoveButton'

import DatePicker from './controls/DatePicker'

const Expense = ({
  namePrefix,
  model,
  setModelFunction,
  createInputProps,
  onChangeHandler,
  expenseIsMileage,
  ErrorMessage,
  handleRemove,
  allowEditing = true,
}) => {
  const handleChange = e => onChangeHandler(e, namePrefix)

  return (
    <div className="expense clearfix">
      <div className="form-row">
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2 d-md-none" htmlFor={`${namePrefix}.type`}>
              Date
            </label>
            <DatePicker
              readOnly={!allowEditing}
              className="form-control"
              {...createInputProps({ name: `${namePrefix}.date`, model, setModelFunction })}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2 d-md-none" htmlFor={`${namePrefix}.type`}>
              Type of Expense
            </label>
            <ActivityExpenseSelector
              allowEditing={allowEditing}
              className="form-control"
              {...createInputProps({
                name: `${namePrefix}.type`,
                model,
                setModelFunction,
                onChangeHandler: handleChange,
              })}
              type="expense"
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2 d-md-none" htmlFor={`${namePrefix}.amount`}>
              Amount
            </label>
            <div className="input-icon input-icon-left">
              <input
                readOnly={allowEditing ? null : true}
                className="form-control"
                {...createInputProps({
                  name: `${namePrefix}.amount`,
                  model,
                  setModelFunction,
                  onChangeHandler: handleChange,
                })}
                type="number"
                disabled={expenseIsMileage}
                onKeyDown={e => (e.key === 'e' || e.key === '-') && e.preventDefault()}
              />
              <i>$</i>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2 d-md-none" htmlFor={`${namePrefix}.distance`}>
              Distance
            </label>
            <div className="input-icon input-icon-right">
              <input
                readOnly={allowEditing ? null : true}
                className="form-control"
                {...createInputProps({
                  name: `${namePrefix}.distance`,
                  model,
                  setModelFunction,
                  onChangeHandler: handleChange,
                })}
                type="number"
                disabled={!expenseIsMileage}
                onKeyDown={e => (e.key === 'e' || e.key === '-') && e.preventDefault()}
              />
              <i>mi</i>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label mt-2 d-md-none" htmlFor={`${namePrefix}.description`}>
              Description
            </label>
            <div className="d-flex">
              <input
                readOnly={allowEditing ? null : true}
                className="form-control"
                {...createInputProps({
                  name: `${namePrefix}.description`,
                  model,
                  setModelFunction,
                  onChangeHandler: handleChange,
                })}
              />
              {allowEditing && (
                <RemoveButton
                  id={`${namePrefix}RemoveBtn`}
                  className="hide-sm-down"
                  onClick={handleRemove}
                ></RemoveButton>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <ErrorMessage name={`${namePrefix}.type`} model={model} />
        <ErrorMessage name={`${namePrefix}.amount`} model={model} />
        <ErrorMessage name={`${namePrefix}.distance`} model={model} />
        <ErrorMessage name={`${namePrefix}.description`} model={model} />
      </div>
      {allowEditing && (
        <RemoveButton id={`${namePrefix}.RemoveBtn-Large`} className="show-sm-down float-right" onClick={handleRemove}>
          Remove
        </RemoveButton>
      )}
    </div>
  )
}

export default Expense

import React, { useState } from 'react'
import { Button, FormGroup, FormLabel } from 'react-bootstrap'

import validations from '../../validation'
import StateSelector from '../StateSelector'

const schema = validations.object({
  name: validations.name().required(),
  addressLine1: validations.addressLine1().nullable(),
  addressCity: validations.addressCity().nullable(),
  addressState: validations.addressState().nullable(),
  addressZip: validations.addressZip(),
  phone: validations.phone(),
  fax: validations.fax(),
  email: validations.email(),
  notes: validations.note(),
})

export default ({ onSave, onSaveSuccess, onClose, oldModel }) => {
  const [model, setModel] = useState(oldModel || {})
  const [saveError, setSaveError] = useState()
  const [validState, setValidState] = useState(validations.validate(model, schema))
  const [touched, setTouched] = useState([])
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleOnChange = e => {
    const getTargetValue = () => {
      if (e.target.type === 'checkbox') {
        return e.target.checked
      } else if (e.target.type === 'file') {
        return e.target.files[0]
      }

      return e.target.value
    }

    const newModel = {
      ...model,
      [e.target.id]: getTargetValue(),
    }

    setModel(newModel)
    setValidState(validations.validate(newModel, schema))
  }

  const registerTouch = name => {
    if (touched.includes(name)) return

    setTouched(touched.concat([name]))
  }

  const handleOnBlur = e => {
    registerTouch(e.target.name)
  }

  const createInputProps = name => ({
    id: name,
    name,
    value: model[name] || '',
    onChange: handleOnChange,
    onBlur: handleOnBlur,
  })

  const handleSubmit = e => {
    e.preventDefault()

    setSaveError(null)
    setSubmitAttempted(true)
    if (!validState.success) return

    if (onSave) {
      setIsLoading(true)
      const isUpdate = !!oldModel && Object.keys(oldModel).length !== 0

      onSave(model, isUpdate)
        .then(result => {
          handleOnSaveSuccess(result)
        })
        .catch(err => {
          handleOnSaveFailure(err)
        })

      setIsLoading(false)
    }
  }

  const handleOnSaveSuccess = model => {
    if (onSaveSuccess) onSaveSuccess(model)
  }

  const handleOnSaveFailure = reason => {
    setSaveError(reason)
  }

  const ErrorMessage = ({ name }) => {
    if (!submitAttempted && !touched.includes(name)) return null
    if (!validState.errors) return null

    const msg = validState.errors[name]
    if (!msg) return null

    return <div className="text-danger">{msg}</div>
  }

  return (
    <form noValidate className="form-horizontal" onSubmit={handleSubmit}>
      {!!isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <h2>{!!oldModel && Object.keys(oldModel).length !== 0 ? 'Adjuster' : 'New Adjuster'}</h2>
      <div>
        <FormGroup>
          <FormLabel htmlFor="name">Company/Individual Name</FormLabel>
          <input
            type="text"
            className="form-control"
            maxLength={validations.maxLengths.name}
            {...createInputProps('name')}
          ></input>
          <ErrorMessage name="name" />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="addressLine1">Address</FormLabel>
          <input
            type="text"
            className="form-control"
            maxLength={validations.maxLengths.address.line1}
            {...createInputProps('addressLine1')}
          ></input>
          <ErrorMessage name="addressLine1" />
        </FormGroup>
        <div className="form-row">
          <div className="col-6">
            <FormGroup>
              <FormLabel htmlFor="addressCity">City</FormLabel>
              <input
                type="text"
                className="form-control"
                maxLength={validations.maxLengths.address.city}
                {...createInputProps('addressCity')}
              ></input>
            </FormGroup>
          </div>
          <div className="col-2">
            <FormGroup>
              <FormLabel htmlFor="addressState">State</FormLabel>
              <StateSelector {...createInputProps('addressState')} />
            </FormGroup>
          </div>
          <div className="col">
            <FormGroup>
              <FormLabel htmlFor="addressZip">Zip Code</FormLabel>
              <input type="text" className="form-control" {...createInputProps('addressZip')}></input>
            </FormGroup>
          </div>
        </div>
        <div>
          <ErrorMessage name="addressCity" />
          <ErrorMessage name="addressState" />
          <ErrorMessage name="addressZip" />
        </div>
        <div className="form-row">
          <div className="col">
            <FormGroup>
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <input type="tel" className="form-control" {...createInputProps('phone')}></input>
            </FormGroup>
          </div>
          <div className="col">
            <FormGroup>
              <FormLabel htmlFor="fax">Fax</FormLabel>
              <input type="tel" className="form-control" {...createInputProps('fax')}></input>
            </FormGroup>
          </div>
        </div>
        <div>
          <ErrorMessage name="phone" />
          <ErrorMessage name="fax" />
        </div>
        <FormGroup>
          <FormLabel htmlFor="email">Email</FormLabel>
          <input
            type="email"
            className="form-control"
            maxLength={validations.maxLengths.email}
            {...createInputProps('email')}
          ></input>
          <ErrorMessage name="email" />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor="notes">Notes</FormLabel>
          <textarea
            className="form-control"
            maxLength={validations.maxLengths.note}
            {...createInputProps('notes')}
            rows="6"
          ></textarea>
          <ErrorMessage name="notes" />
        </FormGroup>
      </div>
      {saveError && <div className="alert alert-danger">Adjuster failed to save.</div>}
      <div className="text-right">
        <Button variant="secondary" id="cancelBtn" className="mr-1" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" id="saveBtn" type="submit">
          Save
        </Button>
      </div>
    </form>
  )
}

import React, { useState } from 'react'

import logger from '../../services/logger'
import setFocusById from '../../services/setFocusById'
import DatePicker from '../controls/DatePicker'
import FilePicker from '../controls/FilePicker'
import { ErrorMessage, Field, FieldArray } from '../controls/FormikWrappers'

import FAIcon from './../FAIcon'

import 'react-datepicker/dist/react-datepicker.css'

const InjuryForm = ({ caseId, files, handleNewCaseFileAttached }) => {
  const [fileAttachError, setFileAttachError] = useState()

  return (
    <React.Fragment>
      <h4 className="form-heading mt-5">Injury Information</h4>
      <FieldArray
        name="injury.descriptions"
        render={({ push, remove, form }) => (
          <div>
            {form.values.injury.descriptions.map((description, index) => (
              <div key={index + 1} className="mt-1">
                {index === null || index > 0 ? null : (
                  <div className="form-row">
                    <label className="form-label mt-3 col-md-4" htmlFor="injuryDescription">
                      Description of Injury
                    </label>
                    <label className="form-label mt-3 col-md-2" htmlFor="descriptionProvidedBy">
                      Provided By
                    </label>
                  </div>
                )}
                <div className="form-row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <Field
                        type="text"
                        id={`injury.descriptions[${index}].description`}
                        name={`injury.descriptions[${index}].description`}
                        className="form-control"
                      />
                      <div style={{ display: 'inline' }}>
                        <ErrorMessage name={`injury.descriptions[${index}].description`} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group">
                      <Field
                        type="text"
                        id={`injuryDescriptions[${index}].provider`}
                        name={`injury.descriptions[${index}].provider`}
                        className="form-control"
                      />
                      <div style={{ display: 'inline' }}>
                        <ErrorMessage name={`injury.descriptions[${index}].provider`} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-.5">
                    <div className="form-group">
                      <button
                        type="button"
                        id={`${index}]RemoveBtn`}
                        className="btn btn-link"
                        onClick={() => remove(index)}
                      >
                        <FAIcon name="minus-circle" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="form-row col-md-.5">
              <div className="form-group">
                <button
                  type="button"
                  id="addButton"
                  className="btn btn-link add-field-button"
                  onClick={() => {
                    setFocusById(`injury.descriptions[${form.values.injury.descriptions.length}].description`)
                    push({ description: '', provider: '' })
                  }}
                >
                  <FAIcon name="plus-circle" /> ADD INJURY DESCRIPTION
                </button>
              </div>
            </div>
          </div>
        )}
      />
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="injuryNotes">
              Injury Notes
            </label>
            <Field
              type="text"
              name="injury.notes"
              className="form-control"
              id="injuryNotes"
              component="textarea"
              rows="6"
            />
            <div style={{ display: 'inline' }}>
              <ErrorMessage name={'injury.notes'} />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="caseNumber">
              Claimant
            </label>
            <Field
              type="text"
              name="caseNumber"
              value={caseId}
              id="caseNumber"
              className="form-control"
              readOnly
              disabled
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="injuryDate">
              Date of Injury
            </label>
            <Field name="injury.date">
              {({ field }) => <DatePicker id="injuryDate" dataFormat="YYYY-MM-DD" {...field} />}
            </Field>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-5">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="injuryFile">
              Records
            </label>
            <FieldArray
              name="injury.recordFiles"
              render={({ push, remove, form }) => {
                const recordFiles = form.values.injury.recordFiles
                const onFileAttach = file => {
                  setFileAttachError(null)
                  handleNewCaseFileAttached(file)
                  push(file.id)
                }

                return (
                  <>
                    {recordFiles.map((fileId, index) => {
                      const file = files.find(x => x.id === fileId)
                      return (
                        <div key={index} className="d-flex">
                          <div className="flex-grow-1 text-truncate">
                            {file.hasBeenSaved ? (
                              <a
                                href={`/api/cases/${caseId}/files/${fileId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                id={`file${fileId}`}
                                className="btn btn-link"
                              >
                                {file.name}
                              </a>
                            ) : (
                              <span className="btn btn-link text-primary text-decoration-none">{file.name}</span>
                            )}
                          </div>
                          <button
                            type="button"
                            id={`removeFile${fileId}Button`}
                            className="btn btn-link"
                            onClick={() => remove(index)}
                          >
                            <FAIcon name="minus-circle" />
                          </button>
                        </div>
                      )
                    })}
                    {recordFiles.length < 10 && ( // max of 10 files
                      <>
                        <FilePicker
                          onAttach={onFileAttach}
                          onError={err => {
                            logger.error(err)
                            setFileAttachError(err)
                          }}
                          id="injuryFile"
                        />
                        {fileAttachError && <div className="text-danger">{fileAttachError}</div>}
                      </>
                    )}
                  </>
                )
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InjuryForm

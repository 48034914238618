import React from 'react'
import DatePicker from 'react-datepicker'

import Moment from 'moment'

export default ({ name, id, value, onChange, timeFormat = 'h:mm aa', ...props }) => {
  const handleOnChange = value => {
    const valueToUse = (() => {
      if (!value) return null
      return value
    })()

    onChange({
      target: {
        type: 'date-picker',
        name,
        value: valueToUse,
      },
    })
  }

  const valueAsDate = value ? Moment(value).toDate() : null

  return (
    <div className="input-group date-picker">
      <DatePicker
        {...props}
        id={id}
        className="form-control"
        selected={valueAsDate}
        value={null}
        onChange={handleOnChange}
        placeholderText="12:00 PM"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat={timeFormat}
      />
      <div className="input-group-append">
        <span id="arrowIcon" className="fas fa-chevron-down input-group-text"></span>
      </div>
    </div>
  )
}

import React from 'react'

const housingTypes = [
  'Assisted Living',
  'Co-op',
  'Condominium',
  'In-law Suite',
  'Independent Living',
  'Mobile Home',
  'Multi-family Home',
  'Single-Family Home (with stairs)',
  'Nursing Home',
  'Ranch-Style Home',
  'Townhome',
]
housingTypes.sort().push('Other')

const HousingSelector = ({ value, onChange, ...rest }) => {
  return (
    <select value={value} onChange={onChange} className="form-control" {...rest}>
      <option value=""></option>
      {housingTypes.map((housingType, i) => (
        <option key={i} value={housingType}>
          {housingType}
        </option>
      ))}
    </select>
  )
}

export default HousingSelector

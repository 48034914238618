import sendRequest from './api/sendRequest'

const url = '/api/cases/search'
const method = 'post'

/**
 * @type { import('./types/caseSearch').default }
 */
function caseSearch(input, abortSignal = undefined) {
  return sendRequest({
    url,
    method,
    data: input,
    signal: abortSignal,
  })
}

export default caseSearch

import React, { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { Prompt } from 'react-router-dom'

import { groupBy, sortBy } from 'lodash'
import get from 'lodash/fp/get'
import { set } from 'object-path-immutable'

import AdjustersService from '../../../services/AdjustersService'
import AppointmentsService from '../../../services/AppointmentsService'
import CasesService from '../../../services/CasesService'
import ReferralSourcesService from '../../../services/ReferralSourcesService'
import TasksService from '../../../services/TasksService'
import validations from '../../../validation'
import LoadingSpinner from '../../LoadingSpinner'
import NewAdjusterModal from '../../modals/NewAdjusterModal'
import NewCompanyModal from '../../modals/NewCompanyModal'

import Sections from './Sections'

import './DetailsPage.scss'

const dirtyFormWarningMessage = 'You have unsaved changes, are you sure you want to leave?'

function validateModel(caseModel) {
  const result = {
    success: true,
  }

  Sections.forEach((section, index) => {
    const schema = section.validationSchema || validations.object({})
    const isValid = validations.validate(caseModel, schema)
    result[index] = isValid
    result.success = result.success && isValid.success
  })

  return result
}

function sortByName(array) {
  return sortBy(array, ['name'])
}
Sections.forEach((section, i) => {
  section.originalIndex = i
})

const DetailsPage = ({ caseNumber, showError, formRef, user }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [caseModel, setCaseModel] = useState()
  const [referralSources, setReferralSources] = useState([])
  const [adjusters, setAdjusters] = useState([])
  const [files, setFiles] = useState([])
  const [appointments, setAppointments] = useState([])
  const [tasks, setTasks] = useState([])
  const [namedState, setNamedState] = useState({})
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false)

  const [selectedSection, setSelectedSection] = useState(0)
  const [selectedAdjuster, setSelectedAdjuster] = useState({})
  const [selectedReferralSource, setSelectedReferralSource] = useState({})
  const [touched, setTouched] = useState({})
  const [modelIsDirty, setModelIsDirty] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [validStateBySection, setValidStateBySection] = useState()
  const [isSidebarToggled, setIsSidebarToggled] = useState(true)

  const [showNewReferralSourceModal, setShowNewReferralSourceModal] = useState(false)
  const [showNewAdjusterModal, setShowNewAdjusterModal] = useState(false)

  useEffect(() => {
    const casePromise = CasesService.get(caseNumber).then(caseModel => {
      setCaseModel(caseModel)
      setValidStateBySection(validateModel(caseModel))
      return caseModel
    })

    const filesPromise = CasesService.getFiles(caseNumber).then(setFiles)

    const appointmentsPromise = AppointmentsService.getCaseAppointments(caseNumber).then(setAppointments)

    const tasksPromise = TasksService.getAllForCase(caseNumber).then(setTasks)

    const referralSourcesPromise = ReferralSourcesService.getAll().then(setReferralSources)

    const adjustersPromise = AdjustersService.getAll().then(setAdjusters)

    Promise.all([
      casePromise,
      filesPromise,
      appointmentsPromise,
      tasksPromise,
      referralSourcesPromise,
      adjustersPromise,
    ])
      .then(() => {
        setIsLoading(false)
      })
      .catch(err => {
        showError(err)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseNumber])

  useEffect(() => {
    if (modelIsDirty) {
      // interestingly, browsers don't allow you the customize the text of an exiting
      // confirmation anymore. Just return true, and the browser will prompt the user
      // in the way it sees best.
      window.onbeforeunload = () => true

      // cleanup handlers
      return () => {
        window.onbeforeunload = null
      }
    }
  })

  const setModelValues = useCallback(values => {
    values.map(v => {
      const [name, value] = v
      const path = name.replace('[', '.').replace(']', '') // change array to a format object-path recognizes
      setCaseModel(current => {
        const newCaseModel = set(current, path, value)
        setValidStateBySection(validateModel(newCaseModel))
        setModelIsDirty(true)
        return newCaseModel
      })
      return null
    })
  }, [])

  if (isLoading) return <LoadingSpinner />

  const SectionComponent = Sections[selectedSection].Component
  const groupedSections = groupBy(Sections, 'group')

  const handleSubmit = () => {
    const updatedCaseModel = checkForAlternateContact()
    setSubmitAttempted(true)
    const validationResult = validateModel(updatedCaseModel)

    if (!validationResult.success) {
      setValidStateBySection(validationResult)
      return
    }

    handleCaseSave(updatedCaseModel, selectedSection)
  }

  const handleCaseSave = (updatedCaseModel, selectedSection) => {
    ;(() => {
      // Updates the case model to reflect latest additions entered by user.
      setCaseModel(updatedCaseModel)
      setIsLoading(true)

      return CasesService.update(updatedCaseModel)
    })()
      .onSuccess(savedCase => {
        showError(null)
        setModelIsDirty(false)

        // mark files as now saved
        setFiles(files.map(x => ({ ...x, hasBeenSaved: true })))

        if (savedCase) {
          setCaseModel(savedCase)
        }

        // display the case save success message
        setIsSuccessMessageVisible(true)
      })
      .onBadRequest(err => {
        showError(err)
      })
      .catch(() => {
        showError('An unexpected error occurred while trying to save the case')
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  const handleNewCaseFileAttached = file => {
    const newFiles = files.concat(file)
    setFiles(newFiles)
  }

  const setModelValue = (name, value) => {
    const path = name.replace('[', '.').replace(']', '') // change array to a format object-path recognizes
    const newCaseModel = set(caseModel, path, value)
    setCaseModel(newCaseModel)
    setValidStateBySection(validateModel(newCaseModel))
    setModelIsDirty(true)
  }

  const handleReferralSourceCreated = r => {
    const index = referralSources.findIndex(source => source.id === r.id)

    if (index !== null) {
      const newReferralSourcesArray = referralSources.slice()
      newReferralSourcesArray.splice(index, 1, r)

      setReferralSources(sortByName(newReferralSourcesArray))
    } else {
      setReferralSources(sortByName(referralSources.concat(r)))
    }

    setModelValue('referral.sourceId', r.id)
    setSelectedReferralSource({})
    setShowNewReferralSourceModal(false)
  }

  const handleAdjusterCreated = r => {
    const index = adjusters.findIndex(adjuster => adjuster.id === r.id)

    if (index !== null) {
      const newAdjustersArray = adjusters.slice()
      newAdjustersArray.splice(index, 1, r)

      setAdjusters(sortByName(newAdjustersArray))
    } else {
      setAdjusters(sortByName(adjusters.concat(r)))
    }

    setModelValue('referral.adjusterId', r.id)
    setSelectedAdjuster({})
    setShowNewAdjusterModal(false)
  }

  const getCaseModelValue = (name, defaultValue, model = caseModel) => {
    return get(name, model) || defaultValue
  }

  const handleBlur = name => {
    setTouched({
      ...touched,
      [name]: true,
    })
  }

  const createInputProps = name => ({
    id: name,
    name,
    value: getCaseModelValue(name, ''),
    onChange: e => {
      const value = (() => {
        switch (e.target.type) {
          case 'checkbox':
            return e.target.checked
          default:
            return e.target.value
        }
      })()
      setModelValue(name, value)
    },
    onBlur: e => handleBlur(name),
  })

  const removeItemFromArray = (name, index) => {
    const orig = getCaseModelValue(name, [])
    const newArray = [...orig.slice(0, index), ...orig.slice(index + 1)]
    setModelValue(name, newArray)
  }

  const addItemToArray = (name, item) => {
    const newArray = getCaseModelValue(name, []).concat(item)
    setModelValue(name, newArray)
    return newArray.length - 1
  }

  const ErrorMessageForSection =
    sectionIndex =>
    ({ name, className, ...props }) => {
      if (!touched[name] && !submitAttempted) return null

      const validState = validStateBySection[sectionIndex]
      if (!validStateBySection[sectionIndex].errors) return null

      const msg = validState.errors[name]
      if (!msg) return null

      return (
        <div className={`text-danger form-text ${className}`} {...props}>
          {msg}
        </div>
      )
    }

  const checkForAlternateContact = () => {
    if (getCaseModelValue('client.hasAlternateContact', false) === false) {
      const newCaseModel = {
        ...caseModel,
        client: {
          ...caseModel.client,
          alternateContact: {
            firstName: '',
            lastName: '',
            phone: '',
            fax: '',
            email: '',
          },
        },
      }
      return newCaseModel
    }
    return caseModel
  }

  const toggleSidebar = () => {
    setIsSidebarToggled(!isSidebarToggled)
  }

  const getStateByName = name => namedState[name]
  const setStateByName = (name, value) => {
    setNamedState({ ...namedState, [name]: value })
  }

  function sectionChange(sectionIndex) {
    setSelectedSection(sectionIndex)
    window.scrollTo(0, 0)
  }

  function showAdjusterEditScreen(adjusterId) {
    if (adjusters) {
      const adjuster = adjusters.find(adjuster => adjuster.id === adjusterId) || {}
      setSelectedAdjuster(adjuster)
      setShowNewAdjusterModal(true)
    }
  }

  function showReferralSourcerEditScreen(referralSourceId) {
    if (referralSources) {
      const source = referralSources.find(source => source.id === referralSourceId) || {}
      setSelectedReferralSource(source)
      setShowNewReferralSourceModal(true)
    }
  }

  return (
    <div className="case-details-component">
      <Prompt when={modelIsDirty} message={dirtyFormWarningMessage} />
      <div className={`fixed-left-container ${!isSidebarToggled ? 'fixed-left-container-closed' : ''}`}>
        <button className="close-button" onClick={toggleSidebar} type="button">
          <i className={`fas fa-lg ${isSidebarToggled ? 'fa-arrow-alt-circle-left' : 'fa-arrow-alt-circle-right'}`}></i>
        </button>
        <div className={`side-bar-container ${!isSidebarToggled ? 'side-bar-container-closed' : ''} bg-light`}>
          <div className="side-bar-background bg-light" />
          <ul className="nav flex-column nav-pills pt-5">
            {Object.keys(groupedSections).map((groupName, groupIndex) => {
              return (
                <div key={`groupName-${groupIndex}`}>
                  <p className="tab-header mb-0 mt-2">{groupName.toUpperCase()}</p>
                  {!!groupedSections &&
                    !!groupedSections[groupName] &&
                    groupedSections[groupName].map((section, sectionIndex) => {
                      return (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                        <li
                          key={section.originalIndex}
                          id={`section-${groupIndex}-${sectionIndex}`}
                          className={`nav-link ${
                            (!!validStateBySection && validStateBySection[section.originalIndex].success) ||
                            !submitAttempted
                              ? 'tab-valid'
                              : 'tab-error'
                          } ${section.originalIndex === selectedSection && 'active'}`}
                          onClick={() => sectionChange(section.originalIndex)}
                        >
                          {section.name}
                        </li>
                      )
                    })}
                </div>
              )
            })}
          </ul>
        </div>
      </div>
      <form ref={formRef} className="detail-page-form case-details-form" onSubmit={handleSubmit}>
        <div className="d-flex w-100">
          <div className={`w-100 p-4 section-container ${!isSidebarToggled ? 'section-container-closed' : ''}`}>
            <SectionComponent
              caseNumber={caseModel.caseNumber}
              caseModel={caseModel}
              caseFiles={files}
              handleNewCaseFileAttached={handleNewCaseFileAttached}
              createInputProps={createInputProps}
              getValue={getCaseModelValue}
              setModelValue={setModelValue}
              addItemToArray={addItemToArray}
              removeItemFromArray={removeItemFromArray}
              referralSources={referralSources}
              onAddNewReferralSource={() => setShowNewReferralSourceModal(true)}
              adjusters={adjusters}
              onAddNewAdjuster={() => setShowNewAdjusterModal(true)}
              onEditAdjuster={showAdjusterEditScreen}
              onEditReferralSource={showReferralSourcerEditScreen}
              tasks={tasks}
              ErrorMessage={ErrorMessageForSection(selectedSection)}
              validStateBySection={validStateBySection}
              namedState={namedState}
              modelIsDirty={modelIsDirty}
              getStateByName={getStateByName}
              setStateByName={setStateByName}
              appointments={appointments}
              user={user}
              setModelValues={setModelValues}
            />
          </div>
        </div>
      </form>
      <div className="content-background" />
      {showNewReferralSourceModal && (
        <NewCompanyModal
          oldModel={selectedReferralSource}
          onSaveSuccess={handleReferralSourceCreated}
          onClosed={() => {
            setSelectedReferralSource({})
            setShowNewReferralSourceModal(false)
          }}
        />
      )}
      {showNewAdjusterModal && (
        <NewAdjusterModal
          oldModel={selectedAdjuster}
          onSaveSuccess={handleAdjusterCreated}
          onClosed={() => {
            setSelectedAdjuster({})
            setShowNewAdjusterModal(false)
          }}
        />
      )}
      <div className="floating-alert-container">
        <Alert
          id="success-message"
          variant="success"
          className={`floating-alert ${isSuccessMessageVisible ? 'show-message' : ''}`}
        >
          <strong>Case save successful!</strong>
        </Alert>
      </div>
    </div>
  )
}

export default DetailsPage

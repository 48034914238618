import React, { useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { withRouter } from 'react-router-dom'

import UserService from '../services/UserService'
import { ADMIN_ROLE_NAME, ROLE_NAMES } from '../utils/Constants'

export default withRouter(({ history, currentUser }) => {
  const [allRoles, setAllRoles] = useState([])
  const [availableRoles, setAvailableRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setAllRoles(ROLE_NAMES)
  }, [setAllRoles])

  useEffect(() => {
    const roles = [...allRoles.filter(r => !selectedRoles.some(sr => sr.key === r.key))]
    setAvailableRoles(roles)
  }, [allRoles, selectedRoles, setAvailableRoles])

  const handleOnRoleSelected = e => {
    const value = e.target.value
    if (value) {
      const role = allRoles.find(r => r.key === value)
      setSelectedRoles([...selectedRoles, role])
    }
  }

  const handleRoleRemoval = roleId => {
    if (roleId) {
      setSelectedRoles([...selectedRoles.filter(sr => sr.key !== roleId)])
    }
  }

  const handleSubmit = async e => {
    const form = e.target
    e.preventDefault()
    e.stopPropagation()

    const formData = new FormData(form)
    const data = Object.fromEntries(formData)

    setIsLoading(true)

    try {
      await UserService.createUser({
        ...data,
        roles: selectedRoles.map(r => r.key),
      })
      history.push('/library/team-members')
    } catch (ex) {
      setError('Error creating user')
    }
  }

  if (!currentUser.roles.includes(ADMIN_ROLE_NAME)) {
    return <h4>You do not have permissions to access this page.</h4>
  }

  return (
    <div>
      {error && (
        <Alert variant="warning" className="my-4">
          {error}
        </Alert>
      )}
      <Form className="mt-4" onSubmit={handleSubmit}>
        <Form.Group controlId="firstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" required name="firstName" />
        </Form.Group>
        <Form.Group controlId="lastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="text" required name="lastName" />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" required name="email" />
        </Form.Group>
        <Form.Group controlId="roles">
          <Form.Label>Roles</Form.Label>
          <Form.Control as="select" onChange={handleOnRoleSelected}>
            <option></option>
            {availableRoles.map(ar => (
              <option key={ar.key} value={ar.key}>
                {ar.label}
              </option>
            ))}
          </Form.Control>
          <div className="h4 mx-n1 my-2">
            {selectedRoles.map(sr => (
              <Badge key={sr.key} variant="primary" className="mx-1">
                {sr.label}
                <Button type="button" size="sm" className="ml-1" onClick={() => handleRoleRemoval(sr.key)}>
                  ×
                </Button>
              </Badge>
            ))}
          </div>
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button type="submit" disabled={isLoading}>
            Add
          </Button>
        </div>
      </Form>
    </div>
  )
})

import React from 'react'
import { Form as ReactBootstrapForm } from 'react-bootstrap'

import { orderBy } from 'lodash'

import format from '../../../services/format'
import FAIcon from '../../FAIcon'

/**********************************************
 * SUPPORTING COMPONENTS FOR REPORT SECTIONS
 * These should probably be broken out into separate
 * files, and I started to but it took a while,
 * and I wasn't sure it was super valuable.
 **********************************************/

const hasItems = items => items && items.length > 0

const EmptyField = () => <>&mdash;</>
const EmptySectionText = ({ children }) => (
  <div className="text-muted">
    <i>{children}</i>
  </div>
)
const TextOrEmpty = ({ text }) => ((text ?? '') !== '' ? <>{text}</> : <EmptyField />)

const Form = {
  Row: ReactBootstrapForm.Row,
  Group: ReactBootstrapForm.Group,
  Label: ReactBootstrapForm.Label,
  Text: ({ children }) => (
    <div className="form-text">
      <TextOrEmpty text={children} />
    </div>
  ),
  Phone: ({ number }) => (
    <Form.Text>
      {number && (
        <>
          <FAIcon name="phone" /> {number}
        </>
      )}
    </Form.Text>
  ),
  Email: ({ email }) => (
    <Form.Text>
      {email && (
        <>
          <FAIcon name="mail" /> {email}
        </>
      )}
    </Form.Text>
  ),
}

const FormGroup = ({ label, text, textComponent, children }) => (
  <Form.Group>
    {children ?? (
      <>
        <Form.Label>{label}</Form.Label>
        {textComponent ?? <Form.Text>{text}</Form.Text>}
      </>
    )}
  </Form.Group>
)
const FormRowGroup = ({ cols, label, text, textComponent, children }) => (
  <Form.Group className={`col-md-${cols}`}>
    {children ?? (
      <>
        <Form.Label>{label}</Form.Label>
        {textComponent ?? <Form.Text>{text}</Form.Text>}
      </>
    )}
  </Form.Group>
)
const ReportSingleLineList = ({ items, columns }) => {
  const EmptyRow = () => (
    <Form.Row className="report-list-item">
      {columns.map((column, i) => (
        <Form.Group key={i} className={`col-md-${column.size}`}>
          <Form.Label className="show-sm-down">{column.title}</Form.Label>
          <div>
            <EmptyField />
          </div>
        </Form.Group>
      ))}
    </Form.Row>
  )

  return (
    <div className="single-line-list">
      <Form.Row className="single-line-list-header-row hide-sm-down">
        {columns.map((c, i) => (
          <Form.Group key={i} className={`col-md-${c.size}`}>
            <Form.Label>{c.title}</Form.Label>
          </Form.Group>
        ))}
      </Form.Row>
      {!items || items.length === 0 ? (
        <EmptyRow />
      ) : (
        items.map((item, i) => (
          <React.Fragment key={i}>
            <Form.Row className="report-list-item">
              {columns.map((c, i) => (
                <Form.Group key={i} className={`col-md-${c.size}`}>
                  <Form.Label className="show-sm-down">{c.title}</Form.Label>
                  {c.component ? <c.component item={item} /> : <Form.Text>{c.getText(item)}</Form.Text>}
                </Form.Group>
              ))}
            </Form.Row>
            {items.length - 1 !== i && <hr className="show-sm-down" />}
          </React.Fragment>
        ))
      )}
    </div>
  )
}
const ReportMultiLineList = ({ noItemsText, items, itemComponent }) => {
  if (!noItemsText) throw new Error('noItemsText is required.')

  if (!items || items.length === 0) return <EmptySectionText>{noItemsText}</EmptySectionText>

  return (
    <>
      {items.map((item, i) => (
        <div className="report-list-item" key={i}>
          {itemComponent(item)}
          {i !== items.length - 1 && <hr />}
        </div>
      ))}
    </>
  )
}
const Address = ({ address }) => {
  const populated = (() => {
    if (!address) return false
    if (!address.line1 && !address.city && !address.state && !address.zip) return false

    return true
  })()

  return (
    <>
      <div className="report-address">
        <FormGroup
          label="Address"
          text={
            !populated ? (
              <Form.Text />
            ) : (
              <>
                <div>{address.line1}</div>
                <div>
                  {address.city}, {address.state} {address.zip}
                </div>
              </>
            )
          }
        />
      </div>
    </>
  )
}
const PhoneAndFax = ({ phone, fax }) => {
  return (
    <>
      <FormGroup label="Phone" textComponent={<Form.Phone number={phone} />} />
      <FormGroup label="Fax" textComponent={<Form.Phone number={fax} />} />
    </>
  )
}

const ListOfFiles = ({ fileIds, caseNumber, caseFiles }) => {
  if (!fileIds || fileIds.length === 0) return <Form.Text />

  return (
    <>
      {fileIds.map(fileId => (
        <div key={fileId} className="case-file text-truncate">
          <Form.Text>
            <FAIcon name="paperclip" />{' '}
            <a
              className="case-file-name"
              target="_blank"
              rel="noopener noreferrer"
              href={`/api/cases/${caseNumber}/files/${fileId}`}
            >
              {caseFiles.find(x => x.id === fileId).name}
            </a>
          </Form.Text>
        </div>
      ))}
    </>
  )
}

const BooleanGroup = ({ label, value }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Text>{value ? 'Yes' : 'No'}</Form.Text>
    </Form.Group>
  )
}

const SubSectionHeader = ({ children }) => <header className="h4">{children}</header>

/**********************************************
 * REPORT SECTIONS
 **********************************************/

const InjurySection = {
  title: 'Injury',
  component: ({ caseNumber, caseReportModel, caseFiles }) => {
    return (
      <>
        <ReportSingleLineList
          items={caseReportModel.case.injury?.descriptions ?? []}
          columns={[
            {
              title: 'Description of Injury',
              size: 6,
              getText: item => item.description,
            },
            { title: 'Provided by', size: 6, getText: item => item.provider },
          ]}
        />

        <Form.Row>
          <Form.Group className="col-md-6">
            <Form.Label>Injury Notes</Form.Label>
            <Form.Text>{caseReportModel.case.injury?.notes}</Form.Text>
          </Form.Group>
          <Form.Group className="col-md-6">
            <Form.Label>Records</Form.Label>
            <ListOfFiles
              fileIds={caseReportModel.case.injury?.recordFiles ?? []}
              caseNumber={caseNumber}
              caseFiles={caseFiles}
            />
          </Form.Group>
        </Form.Row>
      </>
    )
  },
}

const DiagnosisSection = {
  title: 'Diagnosis',
  component: ({ caseNumber, caseReportModel, caseFiles }) => {
    const allDiagnosis = caseReportModel.case.diagnosis ?? []
    if (!hasItems(allDiagnosis)) return <EmptySectionText>No Diagnosis</EmptySectionText>

    return (
      <>
        <DiagnosisRowGroup
          name="Primary"
          category="Primary"
          allDiagnosis={allDiagnosis}
          caseNumber={caseNumber}
          caseFiles={caseFiles}
        />
        <DiagnosisRowGroup
          name="Secondary"
          category="Secondary"
          allDiagnosis={allDiagnosis}
          caseNumber={caseNumber}
          caseFiles={caseFiles}
        />
        <DiagnosisRowGroup
          name="Unrelated"
          category="Unrelated"
          allDiagnosis={allDiagnosis}
          caseNumber={caseNumber}
          caseFiles={caseFiles}
        />
      </>
    )
  },
}

function DiagnosisRowGroup({ name, category, allDiagnosis, caseNumber, caseFiles }) {
  return (
    <ReportSingleLineList
      items={allDiagnosis.filter(x => x.category === category)}
      columns={[
        { title: `${name} Diagnosis`, size: 4, getText: item => item.name },
        {
          title: `Physician`,
          size: 4,
          getText: item => item.physician ?? <EmptyField />,
        },
        {
          title: `Date`,
          size: 2,
          getText: item => format.date(item.date) ?? <EmptyField />,
        },
        {
          title: `Files`,
          size: 2,
          component: ({ item }) => (
            <ListOfFiles fileIds={item.files ?? []} caseNumber={caseNumber} caseFiles={caseFiles} />
          ),
        },
      ]}
    />
  )
}

const MedicationsAndAllergies = {
  title: 'Medications/Allergies',
  component: ({ caseNumber, caseReportModel, caseFiles }) => (
    <>
      <section className="mb-4">
        <SubSectionHeader>Medications</SubSectionHeader>
        <ReportMultiLineList
          noItemsText="No Medications"
          items={caseReportModel.case.medications}
          itemComponent={medication => (
            <>
              <FormGroup label="Medication Name" text={medication.name} />
              <FormGroup label="Dosage" text={medication.dosage} />
              <FormGroup label="Frequency" text={medication.frequency} />
              <FormGroup label="Reason" text={medication.reason} />
              <Form.Row>
                <FormRowGroup cols="3" label="Date Started" text={format.date(medication.dateStarted)} />
                <FormRowGroup cols="3" label="Date Discontinued" text={format.date(medication.dateDiscontinued)} />
              </Form.Row>
              <FormGroup label="Pharmacy" text={medication.pharmacy} />
              <BooleanGroup label="Related to Injury" value={medication.relatedToInjury} />
            </>
          )}
        />
      </section>
      <section>
        <SubSectionHeader>Allergies</SubSectionHeader>
        {!hasItems(caseReportModel.case.allergies) ? (
          <EmptySectionText>No Allergies</EmptySectionText>
        ) : (
          <ReportSingleLineList
            items={caseReportModel.case.allergies}
            columns={[
              { title: 'Allergy Name', size: 4, getText: item => item.name },
              { title: 'Reaction', size: 4, getText: item => item.reaction },
            ]}
          />
        )}
      </section>
    </>
  ),
}

const SurgeriesAndHospitalizations = {
  title: 'Surgeries/Hospitalizations',
  component: ({ caseNumber, caseReportModel, caseFiles }) => (
    <>
      <ReportMultiLineList
        noItemsText="No Surgeries/Hospitalizations"
        items={caseReportModel.case.surgeriesAndHospitalizations}
        itemComponent={item => (
          <>
            <FormGroup label="Procedure/Surgery" text={item.title} />
            <Form.Row>
              <FormRowGroup cols="3" label="Date Admitted" text={format.date(item.dateAdmitted)} />
              <FormRowGroup cols="3" label="Date of Surgery" text={format.date(item.dateOfSurgery)} />
              <FormRowGroup cols="3" label="Date Discharged" text={format.date(item.dateDischarged)} />
            </Form.Row>
            <FormGroup label="Physician/Specialist" text={item.provider} />
            <FormGroup label="Results" text={item.results} />
            <FormGroup label="Reason" text={item.reason} />
            <BooleanGroup label="Related to Injury" value={item.relatedToInjury} />
          </>
        )}
      />
    </>
  ),
}

const ReviewOfSystems = {
  title: 'Review of Systems',
  component: ({ caseNumber, caseReportModel, caseFiles }) => (
    <>
      <FormGroup label="General" text={caseReportModel.case.systems?.general} />
      <FormGroup label="Eyes" text={caseReportModel.case.systems?.eyes} />
      <FormGroup label="Skin" text={caseReportModel.case.systems?.skin} />
      <FormGroup label="Head" text={caseReportModel.case.systems?.head} />
      <FormGroup label="Ears" text={caseReportModel.case.systems?.ears} />
      <FormGroup label="Throat" text={caseReportModel.case.systems?.throat} />
    </>
  ),
}

const FunctionalStatus = {
  title: 'Functional Status',
  component: ({ caseNumber, caseReportModel, caseFiles }) => (
    <>
      <FormGroup label="Self Care" text={caseReportModel.case.functional?.selfcare} />
      <FormGroup label="Behavior" text={caseReportModel.case.functional?.behavior} />
      <FormGroup label="Mobility Status" text={caseReportModel.case.functional?.mobility} />
      <FormGroup label="Bowel and Bladder Management" text={caseReportModel.case.functional?.bbmanagement} />
      <FormGroup label="Nutrition Program" text={caseReportModel.case.functional?.nutrition} />
      <FormGroup label="Medication Program" text={caseReportModel.case.functional?.medication} />
      <FormGroup label="Cognition" text={caseReportModel.case.functional?.cognition} />
      <FormGroup label="Pain" text={caseReportModel.case.functional?.pain} />
      <FormGroup label="Household Management" text={caseReportModel.case.functional?.household} />
      <FormGroup label="Communication" text={caseReportModel.case.functional?.communication} />
    </>
  ),
}

const DiagnosticTests = {
  title: 'Diagnostic Tests',
  component: ({ caseNumber, caseReportModel, caseFiles }) => {
    if (!hasItems(caseReportModel.case.tests)) return <EmptySectionText>No Diagnostic Tests</EmptySectionText>

    return (
      <>
        <ReportSingleLineList
          items={caseReportModel.case.tests ?? []}
          columns={[
            { title: 'Type of Test', size: 4, getText: item => item.type },
            {
              title: 'Date of Test',
              size: 2,
              getText: item => format.date(item.date),
            },
          ]}
        />
      </>
    )
  },
}

const Equipment = {
  title: 'Equipment',
  component: ({ caseNumber, caseReportModel, caseFiles }) => (
    <>
      <ReportMultiLineList
        noItemsText="No Equipment"
        items={caseReportModel.case.equipment}
        itemComponent={item => (
          <>
            <FormGroup label="Item" text={item.item} />
            <Form.Row>
              <FormRowGroup cols="3" label="Date Received" text={format.date(item.dateReceived)} />
              <FormRowGroup cols="3" label="Date Repaired" text={format.date(item.dateRepaired)} />
              <FormRowGroup cols="3" label="Warranty Exp." text={format.date(item.dateWarranty)} />
            </Form.Row>
            <FormGroup label="Provider" text={item.provider} />
            <BooleanGroup label="Related to Injury" value={item.relatedToInjury} />
          </>
        )}
      />
    </>
  ),
}

const Transportation = {
  title: 'Transportation',
  component: ({ caseNumber, caseReportModel, caseFiles }) => (
    <>
      <ReportMultiLineList
        noItemsText="No Transportation"
        items={caseReportModel.case.transportation}
        itemComponent={item => (
          <>
            <Form.Row>
              <FormRowGroup cols="6" label="Type of Transportation" text={item.type} />
              <FormRowGroup cols="6" label="Vendor Name" text={item.vendor} />
            </Form.Row>
            {item.address && <Address address={item.address} />}
            <PhoneAndFax phone={item.phone} fax={item.fax} />
            <FormRowGroup label="Email" textComponent={<Form.Email email={item.email} />} />
          </>
        )}
      />
    </>
  ),
}

const HomeSetup = {
  title: 'Home Setup',
  component: ({ caseReportModel }) => (
    <>
      <ReportMultiLineList
        noItemsText="No Home Setup"
        items={caseReportModel.case.homeSetup}
        itemComponent={item => (
          <>
            <FormGroup label="Type of Housing" text={item.type} />
            <FormGroup label="Inaccessibilities" text={item.inaccessibilities} />
            <BooleanGroup label="Wheelchair Accessible" checked={item.wheelchairAccessible} />
          </>
        )}
      />
    </>
  ),
}

const TreatmentProviders = {
  title: 'Treatment Providers',
  component: ({ caseReportModel }) => (
    <>
      <ReportMultiLineList
        noItemsText="No Treatment Providers"
        items={caseReportModel.case.treatmentProviders}
        itemComponent={item => (
          <>
            <Form.Row>
              <FormRowGroup cols="6" label="Provider Name" text={item.name} />
              <FormRowGroup cols="6" label="Company Name" text={item.companyName} />
            </Form.Row>
            {item.address && <Address address={item.address} />}
            <PhoneAndFax phone={item.phone} fax={item.fax} />
            <FormGroup label="Email" textComponent={<Form.Email email={item.email} />} />
            <FormGroup label="Speciality" text={item.speciality} />
            <BooleanGroup label="Is Primary Care Physician" value={item.isPrimaryCarePhysician} />
          </>
        )}
      />
    </>
  ),
}

const EquipmentProviders = {
  title: 'Equipment Providers',
  component: ({ caseReportModel }) => (
    <>
      <ReportMultiLineList
        noItemsText="No Equipment Providers"
        items={caseReportModel.case.equipmentProviders}
        itemComponent={item => (
          <>
            <FormGroup label="Vendor Name" text={item.name} />
            {item.address && <Address address={item.address} />}
            <PhoneAndFax phone={item.phone} fax={item.fax} />
            <FormGroup label="Email" textComponent={<Form.Email email={item.email} />} />
          </>
        )}
      />
    </>
  ),
}

const Pharmacies = {
  title: 'Pharmacies',
  component: ({ caseReportModel }) => (
    <>
      <ReportMultiLineList
        noItemsText="No Pharmacies"
        items={caseReportModel.case.pharmacies}
        itemComponent={item => (
          <>
            <FormGroup label="Pharmacy Name" text={item.name} />
            {item.address && <Address address={item.address} />}
            <PhoneAndFax phone={item.phone} fax={item.fax} />
            <FormGroup label="Email" textComponent={<Form.Email email={item.email} />} />
          </>
        )}
      />
    </>
  ),
}

const WorkStatus = {
  title: 'Work Status',
  component: ({ caseReportModel }) => (
    <>
      <FormGroup label="Date Last Worked" text={format.date(caseReportModel.case.status?.dateLastWorked)} />
      <FormGroup label="Current Treatment" text={caseReportModel.case.status?.currentTreatment} />
      <FormGroup label="Work Status" text={caseReportModel.case.status?.workStatus} />
      <FormGroup label="Return to Work Status" text={caseReportModel.case.status?.returnStatus} />
      <BooleanGroup label="Light Duty Options" value={caseReportModel.case.status?.lightDuty} />
    </>
  ),
}

const Referral = {
  title: 'Referral',
  component: ({ caseReportModel, referralSource, adjuster }) => (
    <>
      <section>
        <SubSectionHeader>Referral</SubSectionHeader>
        <FormGroup label="Date of Referral" text={format.date(caseReportModel.case.referral?.date)} />
        <FormGroup label="Referral Source" text={referralSource?.name} />
        <PhoneAndFax phone={referralSource?.phone} fax={referralSource?.fax} />
        <FormGroup label="Email" textComponent={<Form.Email email={referralSource?.email} />} />
        <FormGroup label="Adjuster" text={adjuster?.name} />
        <PhoneAndFax phone={adjuster?.phone} fax={adjuster?.fax} />
        <FormGroup label="Email" textComponent={<Form.Email email={adjuster?.email} />} />
        <FormGroup label="Claim Number" text={caseReportModel.case.referral?.claimNumber} />
      </section>
      <section>
        <SubSectionHeader>Client</SubSectionHeader>
        <FormGroup
          label="Full Name"
          text={`${caseReportModel.case.client?.firstName} ${caseReportModel.case.client?.lastName}`}
        />
        <FormGroup label="Date of Birth" text={format.date(caseReportModel.case.client?.birthdate)} />
        <Address address={caseReportModel.case.client?.address} />
        <PhoneAndFax phone={caseReportModel.case.client?.phone} fax={caseReportModel.case.client?.fax} />
        <FormGroup label="Email" textComponent={<Form.Email email={caseReportModel.case.client?.email} />} />

        {caseReportModel.case.client?.hasAlternateContact && (
          <>
            <SubSectionHeader>Alternate Contact</SubSectionHeader>
            <FormGroup
              label="Full Name"
              text={`${caseReportModel.case.client?.alternateContact?.firstName} ${caseReportModel.case.client?.alternateContact?.lastName}`}
            />
            <PhoneAndFax
              phone={caseReportModel.case.client?.alternateContact?.phone}
              fax={caseReportModel.case.client?.alternateContact?.fax}
            />
            <FormGroup
              label="Email"
              textComponent={<Form.Email email={caseReportModel.case.client?.alternateContact?.email} />}
            />
          </>
        )}
      </section>
      <section>
        <SubSectionHeader>Employer</SubSectionHeader>
        <FormGroup label="Employer Name" text={`${caseReportModel.case.employer?.name}`} />
        <Address address={caseReportModel.case.employer?.address} />
        <FormGroup
          label="Contact Name"
          text={`${caseReportModel.case.employer?.contact?.firstName} ${caseReportModel.case.employer?.contact?.lastName}`}
        />
        <FormGroup label="Contact Title" text={`${caseReportModel.case.employer?.contact?.title}`} />
        <PhoneAndFax
          phone={caseReportModel.case.employer?.contact?.phone}
          fax={caseReportModel.case.employer?.contact?.fax}
        />
        <FormGroup label="Email" textComponent={<Form.Email email={caseReportModel.case.employer?.contact?.email} />} />
      </section>
      <section>
        <SubSectionHeader>Language(s)</SubSectionHeader>
        <FormGroup label="Primary" text={caseReportModel.case.client?.firstLanguage} />
        <FormGroup label="Secondary" text={caseReportModel.case.client?.secondLanguage} />
      </section>
    </>
  ),
}

const Attorney = {
  title: 'Attorney',
  component: ({ caseReportModel }) => (
    <>
      <section>
        <SubSectionHeader>Plaintiff</SubSectionHeader>
        <FormGroup label="Company Name" text={`${caseReportModel.case.plaintiff?.name}`} />
        <Address address={caseReportModel.case.plaintiff?.address} />
        <FormGroup
          label="Contact Name"
          text={`${caseReportModel.case.plaintiff?.contact?.firstName} ${caseReportModel.case.plaintiff?.contact?.lastName}`}
        />
        <PhoneAndFax
          phone={caseReportModel.case.plaintiff?.contact?.phone}
          fax={caseReportModel.case.plaintiff?.contact?.fax}
        />
        <FormGroup
          label="Email"
          textComponent={<Form.Email email={caseReportModel.case.plaintiff?.contact?.email} />}
        />
      </section>
      <section>
        <SubSectionHeader>Defendant</SubSectionHeader>
        <FormGroup label="Company Name" text={`${caseReportModel.case.defendant?.name}`} />
        <Address address={caseReportModel.case.defendant?.address} />
        <FormGroup
          label="Contact Name"
          text={`${caseReportModel.case.defendant?.contact?.firstName} ${caseReportModel.case.defendant?.contact?.lastName}`}
        />
        <PhoneAndFax
          phone={caseReportModel.case.defendant?.contact?.phone}
          fax={caseReportModel.case.defendant?.contact?.fax}
        />
        <FormGroup
          label="Email"
          textComponent={<Form.Email email={caseReportModel.case.defendant?.contact?.email} />}
        />
      </section>
    </>
  ),
}

const ActivityTable = ({ activities }) => {
  return (
    <ReportSingleLineList
      items={activities}
      columns={[
        { title: 'Activity Type', getText: x => x.type, size: 2 },
        { title: 'Time', getText: x => x.time, size: 2 },
        { title: 'Description', getText: x => x.description, size: 4 },
      ]}
    />
  )
}

const ExpenseTable = ({ expenses }) => {
  return (
    <ReportSingleLineList
      items={expenses}
      columns={[
        { title: 'Expense Type', getText: x => x.type, size: 2 },
        { title: 'Amount', getText: x => format.money(x.amount), size: 1 },
        {
          title: 'Distance',
          getText: x => x.distance && `${x.distance} Miles`,
          size: 1,
        },
        { title: 'Description', getText: x => x.description, size: 4 },
      ]}
    />
  )
}

const SOAPNotes = {
  title: 'SOAP Notes',
  component: ({ caseReportModel, caseNumber, caseFiles }) => {
    return (
      <ReportMultiLineList
        noItemsText="No SOAP Notes"
        items={orderBy(caseReportModel.appointments, x => x.startUtc)}
        itemComponent={item => (
          <>
            <FormGroup label="Appointment" text={item.title} />
            <FormGroup label="Description" text={item.description} />
            <FormGroup label="Provider" text={item.provider} />
            <FormGroup label="Time" text={format.dateTime(item.startUtc)} />
            <FormGroup label="Transportation Services Needed" text={item.transportationServicesNeeded ? 'Yes' : 'No'} />
            <FormGroup label="Transportation Service Name" text={item.transportationService} />
            <FormGroup label="Translation Services Needed" text={item.translationServicesNeeded ? 'Yes' : 'No'} />
            <Address label="Address" address={item.address} />
            <FormGroup label="Subjective Note" text={item.subjectiveNote} />
            <FormGroup label="Objective Note" text={item.objectiveNote} />
            <FormGroup label="Assessment Note" text={item.assessmentNote} />
            <FormGroup label="Plan Note" text={item.planNote} />
            <Form.Group>
              <Form.Label>Files</Form.Label>
              <ListOfFiles fileIds={item.files ?? []} caseNumber={caseNumber} caseFiles={caseFiles} />
            </Form.Group>

            <ActivityTable activities={item.activities} />

            <ExpenseTable expenses={item.expenses} />
          </>
        )}
      />
    )
  },
}

const RegularNotes = {
  title: 'Regular Notes',
  component: ({ caseReportModel, caseNumber, caseFiles }) => {
    return (
      <ReportMultiLineList
        noItemsText="No Regular Notes"
        items={orderBy(caseReportModel.tasks, x => x.completeDateTimeUtc)}
        itemComponent={item => (
          <>
            <FormGroup label="Title" text={item.title} />
            <FormGroup label="Created" text={format.dateTime(item.createdUtc)} />
            <FormGroup label="Completed" text={item.completeDateTimeUtc && format.dateTime(item.completeDateTimeUtc)} />
            <FormGroup label="Note" text={item.note} />

            <ActivityTable activities={item.activities} />

            <ExpenseTable expenses={item.expenses} />
          </>
        )}
      />
    )
  },
}

export default {
  'Case Details': [
    InjurySection,
    DiagnosisSection,
    MedicationsAndAllergies,
    SurgeriesAndHospitalizations,
    ReviewOfSystems,
    FunctionalStatus,
    DiagnosticTests,
    Equipment,
    Transportation,
    HomeSetup,
  ],

  Providers: [TreatmentProviders, EquipmentProviders, Pharmacies],

  Notes: [SOAPNotes, RegularNotes, WorkStatus],

  Client: [Referral, Attorney],
}

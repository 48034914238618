import React from 'react'

import setFocusById from '../../../services/setFocusById'
import validation from '../../../validation'
import AddButton from '../../controls/AddButton'
import CheckBox from '../../controls/CheckBox'
import DatePicker from '../../controls/DatePicker'
import RemoveButton from '../../controls/RemoveButton'

export default {
  name: 'Equipment',
  group: 'Case Details',
  validationSchema: getValidationSchema(),
  Component,
}

function getValidationSchema() {
  return validation.object({
    equipment: validation.arrayOf(
      validation.object({
        item: validation.name('Item').required(),
        dateReceived: validation.date('Date Received'),
        dateRepaired: validation.date('Date Repaired'),
        dateWarranty: validation.date('Date of Warranty Expiration'),
        provider: validation.shortText('Provider'),
        related: validation.shortText('Related to Injury'),
      }),
    ),
  })
}

function Component({ addItemToArray, removeItemFromArray, createInputProps, getValue, ErrorMessage }) {
  const equipment = getValue('equipment', [])

  return (
    <div>
      <h4 className="pb-3">Durable Medical Equipment</h4>
      {equipment.map((x, index) => {
        const namePrefix = `equipment[${index}]`
        return (
          <React.Fragment key={namePrefix}>
            <Equipment
              namePrefix={namePrefix}
              createInputProps={createInputProps}
              getValue={getValue}
              ErrorMessage={ErrorMessage}
              handleRemove={() => removeItemFromArray('equipment', index)}
            />
            <hr />
          </React.Fragment>
        )
      })}
      <AddButton
        id="addEquipmentButton"
        onClick={() => {
          setFocusById(`equipment[${equipment.length}].item`)
          addItemToArray('equipment', {})
        }}
      >
        Add Durable Medical Equipment
      </AddButton>
    </div>
  )
}

function Equipment({ namePrefix, createInputProps, ErrorMessage, handleRemove }) {
  return (
    <div className="clearfix">
      <div className="form-row">
        <div className="form-group col-md-9">
          <label className="form-label" htmlFor={`${namePrefix}.item`}>
            Item
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.name}
            {...createInputProps(`${namePrefix}.item`)}
          />
          <ErrorMessage name={`${namePrefix}.item`} />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-3">
          <label className="form-label" htmlFor={`${namePrefix}.dateReceived`}>
            Date Received
          </label>
          <DatePicker {...createInputProps(`${namePrefix}.dateReceived`)} />
        </div>
        <div className="form-group col-md-3">
          <label className="form-label" htmlFor={`${namePrefix}.dateRepaired`}>
            Date Repaired
          </label>
          <DatePicker {...createInputProps(`${namePrefix}.dateRepaired`)} />
        </div>
        <div className="form-group col-md-3">
          <label className="form-label" htmlFor={`${namePrefix}.dateWarranty`}>
            Date of Warranty Expiration
          </label>
          <DatePicker {...createInputProps(`${namePrefix}.dateWarranty`)} />
        </div>
        <ErrorMessage name={`${namePrefix}.dateReceived`} />
        <ErrorMessage name={`${namePrefix}.dateRepaired`} />
        <ErrorMessage name={`${namePrefix}.dateWarranty`} />
      </div>
      <div className="form-row">
        <div className="form-group col-md-9">
          <label className="form-label" htmlFor={`${namePrefix}.provider`}>
            Provider
          </label>
          <input
            type="text"
            className="form-control"
            maxLength={validation.maxLengths.shortText}
            {...createInputProps(`${namePrefix}.provider`)}
          />
        </div>
        <ErrorMessage name={`${namePrefix}.provider`} />
      </div>
      <div className="form-row">
        <div className="form-group align-center col-4 col-md-4">
          <CheckBox {...createInputProps(`${namePrefix}.relatedToInjury`)}>Related to Injury</CheckBox>
        </div>
        <div className="col-8 col-md-5">
          <RemoveButton id={`remove${namePrefix}Button`} className="float-right" onClick={() => handleRemove()}>
            Remove
          </RemoveButton>
        </div>
      </div>
    </div>
  )
}

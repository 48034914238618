import React from 'react'

import DatePicker from '../components/controls/DatePicker'
import RemoveButton from '../components/controls/RemoveButton'
import TimeEntry from '../components/controls/TimeEntry'

import ActivityExpenseSelector from './ActivityExpenseSelector'

const Activity = ({
  namePrefix,
  model,
  setModelFunction,
  createInputProps,
  ErrorMessage,
  handleRemove,
  allowEditing = true,
}) => {
  return (
    <div className="activity clearfix">
      <div className="form-row">
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2 d-md-none" htmlFor={`${namePrefix}.type`}>
              Date
            </label>
            <DatePicker
              readOnly={!allowEditing}
              className="form-control"
              {...createInputProps({ name: `${namePrefix}.date`, model, setModelFunction })}
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2 d-md-none" htmlFor={`${namePrefix}.type`}>
              Type of Activity
            </label>
            <ActivityExpenseSelector
              allowEditing={allowEditing}
              className="form-control"
              {...createInputProps({ name: `${namePrefix}.type`, model, setModelFunction })}
              type="activity"
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2 d-md-none" htmlFor={`${namePrefix}.time`}>
              Time
            </label>
            <TimeEntry
              allowEditing={allowEditing}
              {...createInputProps({ name: `${namePrefix}.time`, model, setModelFunction })}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2 d-md-none" htmlFor={`${namePrefix}.description`}>
              Description
            </label>
            <div className="d-flex">
              <input
                readOnly={allowEditing ? null : true}
                className="form-control"
                {...createInputProps({ name: `${namePrefix}.description`, model, setModelFunction })}
              />
              {allowEditing && (
                <RemoveButton
                  id={`${namePrefix}RemoveBtn`}
                  className="hide-sm-down"
                  onClick={handleRemove}
                ></RemoveButton>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <ErrorMessage name={`${namePrefix}.type`} model={model} />
        <ErrorMessage name={`${namePrefix}.time`} model={model} />
        <ErrorMessage name={`${namePrefix}.description`} model={model} />
      </div>
      {allowEditing && (
        <RemoveButton id={`${namePrefix}.RemoveBtn-Large`} className="show-sm-down float-right" onClick={handleRemove}>
          Remove
        </RemoveButton>
      )}
    </div>
  )
}

export default Activity

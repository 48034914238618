import React, { useEffect, useState } from 'react'

import AutoCompleteComponent, { getAddressDetails, getStreetAddress } from '../autocomplete/AutoComplete'
import { ErrorMessage, Field } from '../controls/FormikWrappers'
import StateSelector from '../StateSelector'

import 'react-datepicker/dist/react-datepicker.css'

const ReferralFormEmployerSection = props => {
  const { handleFieldChange, setFieldValue } = props
  const [address, setAddress] = useState(null)

  useEffect(() => {
    const streetAddress = getStreetAddress(address)
    if (!streetAddress) {
      return
    }

    setFieldValue('employer.address.line1', streetAddress)
    setFieldValue('employer.address.city', getAddressDetails(address, 'locality'))
    setFieldValue('employer.address.zip', getAddressDetails(address, 'postal_code'))
    setFieldValue('employer.address.state', getAddressDetails(address, 'administrative_area_level_1'))
  }, [address, setFieldValue])

  return (
    <React.Fragment>
      <h4 className="form-heading mt-5">Employer Information</h4>
      <p className="text-danger">All fields marked with * are required</p>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-3" htmlFor="employer.name">
              Employer Name*
            </label>
            <Field id="employer.name" className="form-control" type="text" name="employer.name" />
            <ErrorMessage name="employer.name" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.address.line1">
              Address
            </label>
            <AutoCompleteComponent address={setAddress} />
            <Field
              className="form-control"
              id="employer.address.line1"
              name="employer.address.line1"
              onChange={handleFieldChange('employer.address.line1')}
            />
            <ErrorMessage name="employer.address.line1" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.address.city">
              City
            </label>
            <Field
              className="form-control"
              id="employer.address.city"
              name="employer.address.city"
              onChange={handleFieldChange('employer.address.city')}
            />
            <ErrorMessage name="employer.address.city" />
          </div>
        </div>
        <div className="col-md-1">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.address.state">
              State
            </label>
            <Field id="employer.address.state" name="employer.address.state">
              {({ field }) => <StateSelector {...field} onChange={handleFieldChange('employer.address.state')} />}
            </Field>
            <ErrorMessage name="employer.address.state" />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.address.zip">
              Zip Code
            </label>
            <Field
              className="form-control"
              id="employer.address.zip"
              name="employer.address.zip"
              onChange={handleFieldChange('employer.address.zip')}
            />
            <ErrorMessage name="employer.address.zip" />
          </div>
        </div>
      </div>
      <div>
        <label className="form-label mt-3" htmlFor="employer.contact.firstName">
          Contact Name
        </label>
        <div className="form-row">
          <div className="col-md-3">
            <div className="form-group">
              <Field
                id="employer.contact.firstName"
                className="form-control"
                placeholder="First Name"
                name="employer.contact.firstName"
              />
              <ErrorMessage name="employer.contact.firstName" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <Field
                id="employer.contact.lastName"
                className="form-control"
                placeholder="Last Name"
                name="employer.contact.lastName"
              />
              <ErrorMessage name="employer.contact.lastName" />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.contact.title">
              Contact Title
            </label>
            <Field id="employer.contact.title" className="form-control" name="employer.contact.title" />
            <ErrorMessage name="employer.contact.title" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.contact.phone">
              Phone
            </label>
            <Field
              type="tel"
              id="employer.contact.phone"
              className="form-control"
              placeholder="(123) 456-7890"
              name="employer.contact.phone"
            />
            <ErrorMessage name="employer.contact.phone" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.contact.fax">
              Fax
            </label>
            <Field
              id="employer.contact.fax"
              className="form-control"
              type="tel"
              placeholder="(123) 456-7890"
              name="employer.contact.fax"
            />
            <ErrorMessage name="employer.contact.fax" />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label mt-2" htmlFor="employer.contact.email">
              Email
            </label>
            <Field
              id="employer.contact.email"
              className="form-control"
              type="email"
              placeholder="name@email.com"
              name="employer.contact.email"
            />
            <ErrorMessage name="employer.contact.email" />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReferralFormEmployerSection

import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'

import { Slide } from '@material-ui/core'
import Moment from 'moment'

import DatePicker from '../components/controls/DatePicker'
import CustomDropDown from '../components/CustomDropDown'
import { DownloadButton } from '../components/DownloadButton'
import FAIcon from '../components/FAIcon'
import LoadingSpinner from '../components/LoadingSpinner'
import NavigationBar from '../components/NavigationBar'
import createFormHelpers from '../FormHelpers'
import CasesService from '../services/CasesService'

export default withRouter(({ history }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [cases, setCases] = useState()
  const [filterStart, setFilterStart] = useState()
  const [filterEnd, setFilterEnd] = useState()
  const [filterCaseNumber, setFilterCaseNumber] = useState()

  useEffect(() => {
    async function fetch() {
      const cases = await CasesService.getAll(['caseNumber', 'client.firstName', 'client.lastName'])
      setCases(cases)

      setIsLoading(false)
    }

    fetch()
  }, [])

  if (isLoading) return <LoadingSpinner />

  const filterUrlParamsParts = []
  let isValid = true
  const { isValidTimeRange } = createFormHelpers({})
  const showTimeRangeError = filterStart && filterEnd && !isValidTimeRange(filterStart, filterEnd)
  isValid &= !showTimeRangeError

  if (filterCaseNumber) filterUrlParamsParts.push(`filterCaseNumber=${filterCaseNumber}`)
  if (filterStart) filterUrlParamsParts.push(`filterStart=${Moment(filterStart).format()}`)
  if (filterEnd) filterUrlParamsParts.push(`filterEnd=${Moment(filterEnd).endOf('day').format()}`)
  const filterUrlParams = filterUrlParamsParts.join('&')

  return (
    <>
      <Helmet bodyAttributes={{ style: 'background-color : #fff !important' }} />
      <Slide className="user-productivity-report-container" direction="up" in={true} mountOnEnter unmountOnExit>
        <div>
          <NavigationBar
            title={'ACTIVITIES AND EXPENSES'}
            leftBtnText={<FAIcon type="fa fa-lg close-icon" name={'times'} />}
            handleClickLeft={() => history.push('/library')}
          />

          <div className="container my-5">
            <div style={{ maxWidth: '40rem' }}>
              <h5 className="text-primary">Select the desired date range (optional):</h5>
              <div className="form-row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'start'}>
                      Start Date
                    </label>
                    <DatePicker
                      name="filterStart"
                      autoComplete="off"
                      value={filterStart}
                      onChange={e => setFilterStart(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label mt-2" htmlFor={'end'}>
                      End Date
                    </label>
                    <DatePicker
                      name="filterEnd"
                      autoComplete="off"
                      value={filterEnd}
                      onChange={e => setFilterEnd(e.target.value)}
                    />
                  </div>
                </div>
                {showTimeRangeError && <div className={'text-danger form-text mb-2'}>Invalid time range selected.</div>}
              </div>

              <h5 className="text-primary">Select a client (optional):</h5>
              <div className="form-row">
                <div className="col-md-6">
                  <CustomDropDown
                    items={cases.map(x => ({
                      id: x.caseNumber,
                      name: `${x.caseNumber} - ${x.client?.firstName} ${x.client?.lastName}`,
                    }))}
                    name="filterCaseNumber"
                    value={filterCaseNumber}
                    onChange={e => setFilterCaseNumber(e.target.value)}
                  />
                </div>
              </div>

              <DownloadButton
                className={'mt-4 btn-primary btn'}
                url={isValid ? `/api/productivity-report/download?${filterUrlParams}` : undefined}
              >
                Download Report
              </DownloadButton>
            </div>
          </div>
        </div>
      </Slide>
    </>
  )
})

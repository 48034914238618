import React from 'react'

const Component = ({ items, columns, noItemsText }) => {
  return (
    <>
      <div className="card border-light">
        <table className="table mb-0">
          <thead>
            <tr className="text-uppercase">
              {(columns ?? []).map((x, i) => {
                const sizeClass = x.size ? `col-${x.size}` : null
                return (
                  <th key={i} scope="col" className={`${sizeClass} ${x.className}`}>
                    {x.title}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {(items ?? []).map((item, i) => (
              <tr key={i}>
                {(columns ?? []).map((column, i) => (
                  <td key={i} className={column.className}>
                    {column.getText(item)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {(items ?? []).length === 0 && (
            <tfoot>
              <tr>
                <td colSpan="100%">
                  <div className="h6 text-center">{noItemsText ?? 'No Items'}</div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </>
  )
}

export default Component

import React from 'react'

import Moment from 'moment'

import 'react-big-calendar/lib/sass/styles.scss'
import './CalendarEventComponent.scss'

const CalendarEventComponent = selectedObject => {
  const isDayInPast = Moment.utc(selectedObject.event.end) < Moment.utc()

  const getFormattedTime = (startUtc, endUtc) => {
    if (!!startUtc && !!endUtc) {
      const startTime = Moment.utc(startUtc).local().format('h:mma')
      const endTime = Moment.utc(endUtc).local().format('h:mma')

      return `${startTime}-${endTime}`
    } else {
      return ''
    }
  }

  return (
    <div className="calendar-event-component-container">
      <span className={`dot mt-1 ${isDayInPast && 'grayed-img'}`}></span>
      <div className="event-content-container d-inline-block w-100">
        <p className={`event-title mb-0 ${isDayInPast && 'grayed-text'}`}>{selectedObject.event.title}</p>
        <p className={`event-subheading mb-0 ${isDayInPast && 'grayed-text'}`}>
          {getFormattedTime(selectedObject.event.start, selectedObject.event.end)}
        </p>
      </div>
    </div>
  )
}

export default CalendarEventComponent
